import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { breakpoints } from 'hook/useMedia';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 80vh;
  flex-direction: column;
  /* background: gold; */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 95%;
  margin-left: 1rem;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 2rem;
      width: 100%;
      margin-left: 0;
      justify-content: center;
      text-align: center;

      h2 {
        font-size: 16px;
      }
    `}
`;

export const WrapperForm = styled.div`
  display: flex;
  /* background: darkblue; */
  width: 100%;
  height: 100%;
  overflow: auto;

  @media only screen and ${breakpoints.xl} {
  }

  @media only screen and ${breakpoints.lg} {
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
      overflow: inherit;
    `}
`;

export const ContainerForm = styled.form`
  display: flex;
  /* background: aliceblue; */
  width: 60%;
  flex-direction: column;
  margin-left: 4rem;
  position: relative;
  max-width: 620px;

  @media only screen and ${breakpoints.xl} {
    width: 100%;
  }

  @media only screen and ${breakpoints.lg} {
    /* background: blue; */
    width: 100%;
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
      margin-left: 0.5rem;
    `}
`;

export const ImageProfile = styled.img`
  ${(props) =>
    props.mobile &&
    css`
      width: 40px;
      height: 40px;
    `}
`;

export const SelectStyled = styled(Select)({
  root: {
    width: '100%',
    height: '44px',
    borderRadius: '12px',
    '& .MuiOutlinedInput-root': {
      '& .MuiInputLabel-root': {
        background: '#fff',
        '.Mui-focused': {
          color: '#00887D',
        },
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const StyledTextField = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '100%',
      height: '44px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const StyledTextFieldMobile = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '400px',
      height: '44px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '@media only screen and (min-width: 1000px) and (max-width: 1150px)': {
        width: '500px',
      },
      '@media only screen and (min-width: 800px) and (max-width: 1149px)': {
        height: '45px',
        width: '400px',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const StyledTextFieldDouble = styled(TextField)({
  root: {
    width: '50%',
    '& .MuiOutlinedInput-root': {
      width: '100%',
      height: '44px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const StyledTextFieldDoubleMobile = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '190px',
      height: '44px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '@media only screen and (min-width: 1000px) and (max-width: 1150px)': {
        width: '500px',
      },
      '@media only screen and (min-width: 800px) and (max-width: 1149px)': {
        height: '45px',
        width: '400px',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const SwitchContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  /* background: green; */
  justify-content: space-between;
  width: 100%;

  ${(props) =>
    props.mobile &&
    css`
      width: 96%;
    `}
`;

export const ContainerDoubleTextField = styled.div`
  /* background: red; */
  display: flex;
  justify-content: space-between;
  width: 100%;

  gap: 10px;

  ${(props) =>
    props.mobile &&
    css`
      width: 70%;
    `}
`;

export const ContainerPessoaExposta = styled.div`
  display: flex;
  /* background: aliceblue; */
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const ContainerPessoaExpostaInputs = styled.div`
  display: flex;
  /* background: aliceblue; */
  width: 100%;
  flex-direction: column;
`;

export const TermosDeUsoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;

  @media only screen and ${breakpoints.xl} {
  }

  @media only screen and ${breakpoints.lg} {
    display: flex;
    width: 100%;
  }

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    `}
`;

export const TermosDeUsoText = styled.div`
  display: flex;
  word-break: break-word;
  width: 60%;
  cursor: pointer;

  @media only screen and ${breakpoints.xl} {
    width: 60%;
  }

  @media only screen and ${breakpoints.lg} {
    width: 60%;
    display: flex;
  }

  p {
    color: #828282;
    font-size: 13px;
  }

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      word-break: break-word;
      width: 90%;
      font-size: 12px;
      margin-right: 1rem;

      p {
        color: #828282;
        font-size: 12px;
      }
    `}
`;

export const ContainerDocumentos = styled.div`
  display: flex;
  width: 45%;
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 10px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 90%;
  justify-content: flex-end;
  width: 140%;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
      margin-top: 0%;
      justify-content: center;
    `}
`;

export const TootipAviso = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  margin-left: 20px;

  position: absolute;
  width: 198px;
  height: 272px;
  top: 310px;

  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
  border-radius: 27px;

  h3 {
    font-style: normal;
    font-weight: normal;
    line-height: 18px;

    /* Gray 2 */

    color: #4f4f4f;
  }

  p {
    color: #828282;
    font-size: 14px;
  }
`;

export const ImageAviso = styled.img`
  width: 30px;
  height: 30px;
`;

export const DatePickerWarning = styled.p`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #cc390a;
  width: 70%;
`;
