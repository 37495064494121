import { AiFillCloseCircle } from 'react-icons/ai';

import { useAuth } from 'context/Auth';

import { CloseContainer } from './styles';

export function ButtonClose() {
  const { signOut } = useAuth();
  return (
    <CloseContainer onClick={() => signOut()}>
      <AiFillCloseCircle size={32} color="#4F4F4F" />
    </CloseContainer>
  );
}
