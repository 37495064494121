import { api } from './api';

export async function deleteVinculado(numPedido, idNumSeq) {
  const response = await api.delete(
    `/vinculados/excluir/${numPedido}/${idNumSeq}`
  );
  return response.data;
}

export async function updateVinculado(payload) {
  const { data } = await api.put(`/vinculados/atualizar`, payload);
  return data;
}

export async function getVinculadosById(numPedido) {
  const { data } = await api.get(
    `/vinculados/getAllByNumIdPedido/${numPedido}`
  );
  return data;
}

export async function getVinculadoById(numPedido, idNumSeq) {
  const { data } = await api.get(
    `/vinculados/findById/${numPedido}/${idNumSeq}`
  );
  return data;
}

export async function postVinculado(payload) {
  const { data } = await api.post(`/vinculados/cadastrar`, payload);
  return data;
}

/* export const putGrauParentesco = async (id) => {
  const { data } = await api.put(`vinculados/salvarDominio/${id}`);
  return data;
};

export async function salvarDominio(id) {
  const { data } = await api.put(`/vinculados/salvarDominio/${id}`);
  return data;
} */
