function mascaraMoeda(valor) {
  const onlyDigits = valor
    .split('')
    .filter((s) => /\d/.test(s))
    .join('')
    .padStart(3, '0');
  const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
  valor = maskCurrency(digitsFloat);
  return valor;
}

export function mascaraMoedaGrid(valor) {
  if (!valor) return;
  const temVirgula = valor.toString().indexOf(',') > 0;
  const temPonto = valor.toString().indexOf('.') > 0;

  if (!temVirgula && !temPonto) {
    const onlyDigits = `${valor}.00`;
    valor = maskCurrency(onlyDigits);
    return valor;
  }
  if (temPonto && !temVirgula) {
    const casadecimal = valor.toString().split('.')[1];
    if (casadecimal.length == 1) {
      valor = `${valor.toString().split('.')[0]}.${casadecimal}0`;
    }
    const valorComVirgula = valor.toString().replace('.', ',');

    const onlyDigits = valorComVirgula
      .split('')
      .filter((s) => /\d/.test(s))
      .join('')
      .padStart(3, '0');

    const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
    valor = maskCurrency(digitsFloat);
    return valor;
  }
  const onlyDigits = valor
    .split('')
    .filter((s) => /\d/.test(s))
    .join('')
    .padStart(3, '0');

  const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
  valor = maskCurrency(digitsFloat);
  return valor;
}

function maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(valor);
}

function formatReal(valor) {
  if (!valor) return mascaraMoeda('0');

  return mascaraMoeda(`${valor}`);
}

export function removeRealFormat(valor_formatado) {
  const parsedReal = mascaraMoeda(`${valor_formatado}`)
    .replace('R$', '')
    .replace('.', '')
    .replace(',', '.');

  return parseFloat(parsedReal);
}

export default formatReal;
