export const sexos = [
  {
    key: 1,
    label: 'Masculino',
  },
  {
    key: 2,
    label: 'Feminino',
  },
];
