import { api } from './api';

/* export async function getSalario(numPedido, numIdPF) {
  const { data } = await api.get(`/users/getSalario/${numPedido}/${numIdPF}`);
  return data;
} */

export async function pathContribuicao(body) {
  const { data } = await api.put(`/users/atualizarcontribuicao`, body);
  return data;
}
