import TextField from '@mui/material/TextField';

import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 30em;

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      height: 30em;
    `}
`;

export const LogoImage = styled.img`
  margin-right: auto;
  margin-left: auto;
  max-width: 116px;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.enviado &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      width: 100%;
      align-items: flex-start;
      text-align: center;
      flex-direction: column;
      justify-content: center;
    `}

    ${(props) =>
    props.mobileEnviado &&
    css`
      display: flex;
      width: 100%;
      align-items: flex-start;
      text-align: center;
      flex-direction: column;
      justify-content: center;

      background: red;
    `}
`;

export const Title = styled.h3`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  margin-top: 20px;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;

      font-size: 24px;
      line-height: 28px;

      margin-top: 0px;
      margin-left: 0px;
    `}
`;

export const Descricao = styled.p`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;

      font-size: 18px;
      line-height: 21px;
      margin-left: 0px;
    `}
`;

export const TextFieldContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const StyledTextField = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '520px',
      height: '57px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const StyledTextFieldMobile = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '300px',
      height: '45px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const Footer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  margin-left: 15px;
  margin-right: 15px;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;
