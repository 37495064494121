import { breakpoints } from 'hook/useMedia';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      height: 100%;
      flex-direction: row;
      align-items: center;

      align-items: center;
      justify-content: center;
    `}
`;

export const Stepper = styled.div`
  margin-top: 20px;
  display: flex;
  background: white;
  width: 300px;
  height: 50px;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
  margin-left: 60px;

  @media only screen and ${breakpoints.xl} {
    margin-left: 80px;
  }

  @media only screen and ${breakpoints.lg} {
    margin-left: 100px;
  }

  ${(props) =>
    props.disabled &&
    css`
      background: transparent;
      color: white;
    `}

  ${(props) =>
    props.mobileDisabled &&
    css`
      background: transparent;
      color: #ffffff;
      opacity: 0.5;
    `}

  ${(props) =>
    props.mobile &&
    css`
      color: #fff;

      margin-top: 0px;
      display: flex;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      cursor: pointer;
      margin-left: 10px;
      background: transparent;
    `}
`;

export const StepperNumero = styled.div`
  margin-left: 15px;

  width: 32px;
  height: 32px;

  border-radius: 16px;

  border: 3px solid #00887d;
  box-sizing: border-box;

  ${(props) =>
    props.disabled &&
    css`
      border: 3px solid #00294b;
    `}

  ${(props) =>
    props.verificado &&
    css`
      border: 0px;
    `}

    ${(props) =>
    props.mobile &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0px;

      border: 3px solid #fff;
    `}
`;

export const StepperText = styled.div`
  margin-left: 10px;
  font-size: 18px;

  @media only screen and ${breakpoints.xl} {
    font-size: 18px;
  }

  @media only screen and ${breakpoints.lg} {
    font-size: 16px;
  }
`;
