import { Dialog } from '@mui/material';

import { useModalProspect } from 'context/ModalProspect';
import { useProspect } from 'context/Prospects';

import { Button } from '../Button';
import { Footer, ModalWrapper, TextContainer } from './styles';

export function ModalAvisoDeferir() {
  const { handleCloseModalAviso, showModalAviso } = useModalProspect();
  const { deferirUsuario } = useProspect();

  const prospect = JSON.parse(localStorage.getItem('@petros:prospect'));

  const apagarLocalStorage = () => {
    localStorage.removeItem('@petros:prospect');
  };

  return (
    <>
      {showModalAviso && (
        <Dialog
          onClose={() => {
            handleCloseModalAviso();
            apagarLocalStorage();
          }}
          aria-labelledby="simple-dialog-title"
          open={showModalAviso}
          scroll="body"
          fullWidth
        >
          <ModalWrapper>
            <h2> Tem certeza que deseja deferir o usuário?</h2>

            <TextContainer style={{ marginTop: '-3em' }}>
              <p>
                Essa ação não poderá ser desfeita, pois o usuário será
                transferido para a lista de usuários deferidos.
              </p>
            </TextContainer>

            <Footer>
              <Button
                outlinedVinculados
                onClick={() => {
                  handleCloseModalAviso();
                  apagarLocalStorage();
                }}
              >
                Cancelar
              </Button>

              <Button
                style={{ width: '159px' }}
                normal
                onClick={() => {
                  deferirUsuario(prospect);
                  apagarLocalStorage();
                  handleCloseModalAviso();
                }}
              >
                Deferir
              </Button>
            </Footer>
          </ModalWrapper>
        </Dialog>
      )}
    </>
  );
}

export function ModalAvisoIndeferir() {
  const { showModalAvisoIndeferir, handleCloseModalAvisoIndeferir } =
    useModalProspect();
  const { indeferirUsuario } = useProspect();

  const prospect = JSON.parse(localStorage.getItem('@petros:prospect'));

  const apagarLocalStorage = () => {
    localStorage.removeItem('@petros:prospect');
  };

  return (
    <>
      {showModalAvisoIndeferir && (
        <Dialog
          onClose={() => {
            handleCloseModalAvisoIndeferir();
            apagarLocalStorage();
          }}
          aria-labelledby="simple-dialog-title"
          open={showModalAvisoIndeferir}
          scroll="body"
          fullWidth
        >
          <ModalWrapper>
            <h2> Tem certeza que deseja indeferir o usuário?</h2>

            <TextContainer style={{ marginTop: '-3em' }}>
              <p>
                Essa ação não poderá ser desfeita, pois o usuário será
                transferido para a lista de usuários indeferidos.
              </p>
            </TextContainer>

            <Footer>
              <Button
                outlinedVinculados
                onClick={() => {
                  handleCloseModalAvisoIndeferir();
                  apagarLocalStorage();
                }}
              >
                Cancelar
              </Button>

              <Button
                style={{ width: '159px' }}
                normal
                onClick={() => {
                  indeferirUsuario(prospect);
                  apagarLocalStorage();
                  handleCloseModalAvisoIndeferir();
                }}
              >
                Indeferir
              </Button>
            </Footer>
          </ModalWrapper>
        </Dialog>
      )}
    </>
  );
}

export function ModalAvisoDeletar() {
  const { showModalDeletar, handleCloseModalDeletar } = useModalProspect();
  const { handleDelete } = useProspect();

  const prospect = JSON.parse(localStorage.getItem('@petros:prospect'));

  const apagarLocalStorage = () => {
    localStorage.removeItem('@petros:prospect');
  };

  return (
    <>
      {showModalDeletar && (
        <Dialog
          onClose={() => {
            handleCloseModalDeletar();
            apagarLocalStorage();
          }}
          aria-labelledby="simple-dialog-title"
          open={showModalDeletar}
          scroll="body"
          fullWidth
        >
          <ModalWrapper>
            <h2> Tem certeza que deseja deletar o usuário?</h2>

            <TextContainer style={{ marginTop: '-3em' }}>
              <p>
                Essa ação não poderá ser desfeita, pois o usuário será deletado
                permanentemente.
              </p>
            </TextContainer>

            <Footer>
              <Button
                outlinedVinculados
                onClick={() => {
                  handleCloseModalDeletar();
                  apagarLocalStorage();
                }}
              >
                Cancelar
              </Button>

              <Button
                style={{ width: '159px' }}
                normal
                onClick={() => {
                  handleDelete(prospect.numCpf);
                  apagarLocalStorage();
                  handleCloseModalDeletar();
                }}
              >
                Deletar
              </Button>
            </Footer>
          </ModalWrapper>
        </Dialog>
      )}
    </>
  );
}
