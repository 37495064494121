import TextField from '@mui/material/TextField';

import { breakpoints } from 'hook/useMedia';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 80vh;
  flex-direction: column;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const ContainerButtonTop = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and ${breakpoints.lg} {
    width: 55%;
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
      justify-content: space-around;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 95%;
  margin-top: 1rem;
  margin-left: 1rem;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 1rem;
      margin-left: 0rem;
      align-items: center;
      justify-content: center;
    `}
`;

export const ContainerMid = styled.div`
  display: flex;
  width: 80%;
  margin-top: 1rem;
  margin-left: 1rem;

  ${(props) =>
    props.grid &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 22px;
      list-style: none;
      overflow: scroll;
      width: 95%;

      ::-webkit-scrollbar {
        width: 6px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
      }

      @media only screen and ${breakpoints.xxl} {
        grid-template-columns: repeat(3, 1fr);
      }
    `}

  ${(props) =>
    props.mobile &&
    css`
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      list-style: none;
      margin-left: 1rem;
      margin-top: 1rem;
      gap: 22px;
      display: grid;
      width: 83%;
    `}
`;

export const Footer = styled.div`
  display: flex;
  width: 95%;
  margin-top: 1rem;
  margin-left: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 40vh;
`;

export const Image = styled.img`
  @media only screen and ${breakpoints.lg} {
    width: 50%;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  margin-left: -10px;
`;

export const ImageProfile = styled.img`
  ${(props) =>
    props.mobile &&
    css`
      width: 40px;
      height: 40px;
    `}
`;

export const ContainerVinculados = styled.div`
  display: flex;
  width: 341px;
  height: 302px;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  background: #fff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      width: 343px;
      height: 200px;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;

      background: #fff;
      box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    `}
`;

export const ContainerVinculadosMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div`
  display: flex;
  margin-top: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.mobile &&
    css`
      height: 100%;
      margin-left: 10px;
      flex-direction: column;
    `}
`;

export const StyledTextField = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '300px',
      height: '57px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const StyledTextFieldMobile = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '265px',
      height: '45px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const ContainerButtonFooter = styled.div`
  display: flex;
  margin-top: 1rem;

  width: 90%;
  justify-content: end;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
      justify-content: center;
    `}
`;

export const ContainerButtonActions = styled.div`
  width: 100%;
  display: flex;
  margin-top: 32px;
  justify-content: end;
  margin-right: 32px;

  @media only screen and ${breakpoints.xl} {
    margin-top: -42px;
    margin-right: 42px;
  }
`;
