/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect } from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';
import Switch from 'react-switch';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { FormHelperText, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import nacionalidade from 'data/nacionalidade.json';
import ptBR from 'date-fns/locale/pt-BR';
import { usePersonalInfoForm } from 'hook/usePersonalInfoForm';
import { useResponsive } from 'hook/useResponsive';
import { isCpf } from 'iscpf/dist/is-cpf';
import formataCpf from 'util/formatarCPF';
import formatarNome from 'util/formatarNome';
import formataTelefone from 'util/formatarTelefone';

import { Button } from 'components/Button';
import { ModalPPE } from 'components/ModalPPE';

import { useFormulario } from 'context/Formulario';
import { useInfoPessoais } from 'context/InfoPessoais';
import { useModal } from 'context/Modal';

import checkIcon from 'assets/images/check.png';
import checkMarkLock from 'assets/images/checkmark_lock.svg';
import docPreview from 'assets/images/doc.svg';

import { sexos } from './item';
import { FormularioMobile } from './mobile';
import {
  Container,
  ContainerButton,
  ContainerDocumentos,
  ContainerDoubleTextField,
  ContainerForm,
  ContainerPessoaExposta,
  ContainerPessoaExpostaInputs,
  FileItem,
  FileList,
  ImageAviso,
  ImageProfile,
  SelectStyled,
  StyledTextField,
  StyledTextFieldDouble,
  SwitchContainer,
  TermosDeUsoContainer,
  TermosDeUsoText,
  TitleContainer,
  TootipAviso,
  WrapperForm,
} from './styles';

export function Formulario() {
  const { files } = useInfoPessoais();
  const { mobileLayout } = useResponsive();

  const {
    formik,
    handleLoadFormulario,
    disabled_cpf,
    disabled_sexo,
    disabled_matricula,
    disabled_dtAdmissao,
    disabled_dtnasc,
    disabledAll,
    disabledForm,
    naoDesabilita,
    handleCep,
    estadoNacionalidadeLista,
    handleLoadCidadeLista,
    cidadeLista,
    loadingCep,
    loadingAvancar,
  } = usePersonalInfoForm();

  const { anchorEl, handlePopoverOpen, handlePopoverClose } = useFormulario();

  const { showModalPPE, setShowModalPPE, openModalPPE } = useModal();

  useEffect(() => {
    handleLoadFormulario();
  }, []);

  return (
    <>
      {!mobileLayout && (
        <Container>
          <TitleContainer>
            <h2>Confira seus dados</h2>
          </TitleContainer>
          <WrapperForm>
            <ContainerForm
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <StyledTextField
                onMouseEnter={(event) => {
                  handlePopoverOpen(event);
                }}
                onMouseLeave={handlePopoverClose}
                style={{ marginTop: '16px' }}
                id="outlined-basic"
                name="nome"
                label="Nome *"
                variant="outlined"
                type="nome"
                disabled
                value={formik.values.nome}
                onChange={(e) => {
                  formik.setFieldValue(
                    e.target.name,
                    formatarNome(e.target.value)
                  );
                }}
                error={Boolean(formik.errors.nome) && formik.touched.nome}
                helperText={Boolean(formik.touched.nome) && formik.errors.nome}
                placeholder={formik.errors.nome}
                inputProps={{
                  maxLength: 60,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.nome && formik.values.nome.length > 3 && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkMarkLock} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
              <ContainerDoubleTextField>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ptBR}
                >
                  <DesktopDatePicker
                    label="Data de nascimento *"
                    onMouseEnter={(event) => {
                      if (naoDesabilita && disabled_dtnasc) {
                        handlePopoverOpen(event);
                      }
                    }}
                    onMouseLeave={handlePopoverClose}
                    maxDate={new Date()}
                    disabled={
                      !naoDesabilita
                        ? false
                        : disabled_dtnasc || disabledAll || disabledForm
                    }
                    value={formik.values.dataNascimento}
                    onChange={(value) => {
                      formik.setFieldValue('dataNascimento', value);
                    }}
                    slotProps={{
                      textField: {
                        style: { marginTop: '16px' },
                        name: 'dataNascimento',
                        onBlur: formik.handleBlur,
                        error:
                          formik.touched.dataNascimento &&
                          Boolean(formik.errors.dataNascimento),
                        helperText:
                          Boolean(formik.touched.dataNascimento) &&
                          formik.errors.dataNascimento,
                      },
                    }}
                  />
                </LocalizationProvider>

                <StyledTextFieldDouble
                  onMouseEnter={(event) => {
                    if (naoDesabilita && disabled_sexo) {
                      handlePopoverOpen(event);
                    }
                  }}
                  onMouseLeave={handlePopoverClose}
                  style={{ marginTop: '16px' }}
                  id="outlined-select-currency"
                  select
                  component="div"
                  name="sexo"
                  label="Sexo *"
                  disabled={
                    !naoDesabilita
                      ? false
                      : disabled_sexo || disabledAll || disabledForm
                  }
                  value={formik.values.sexo}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.sexo) && formik.touched.sexo}
                  helperText={
                    Boolean(formik.touched.sexo) && formik.errors.sexo
                  }
                  placeholder={formik.errors.sexo}
                  InputProps={{
                    endAdornment: (
                      <>
                        {(disabled_sexo || disabledAll || disabledForm) && (
                          <InputAdornment
                            style={{
                              marginTop: '4px',
                              marginRight: '25px',
                            }}
                            position="end"
                          >
                            <ImageProfile src={checkMarkLock} />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                >
                  {sexos.map((option) => (
                    <MenuItem key={option.key} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextFieldDouble>
              </ContainerDoubleTextField>

              <StyledTextField
                onMouseEnter={(event) => {
                  if (disabled_cpf) {
                    handlePopoverOpen(event);
                  }
                }}
                onMouseLeave={handlePopoverClose}
                style={{ marginTop: '16px' }}
                id="outlined-basic"
                label="CPF *"
                name="cpf"
                variant="outlined"
                type="text"
                disabled={disabled_cpf || disabledAll || disabledForm}
                value={formataCpf(formik.values.cpf) || ''}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.cpf) && formik.touched.cpf}
                helperText={Boolean(formik.touched.cpf) && formik.errors.cpf}
                placeholder={formik.errors.cpf}
                inputProps={{
                  maxLength: 14,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {isCpf(formik.values.cpf) && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkMarkLock} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
              <StyledTextField
                style={{ marginTop: '16px' }}
                defaultValue=""
                id="telefone"
                name="celular"
                label="Celular *"
                variant="outlined"
                type="tel"
                disabled={disabledAll}
                value={formik.values.celular}
                onChange={(e) => {
                  formik.setFieldValue(
                    e.target.name,
                    formataTelefone(e.target.value)
                  );
                }}
                error={Boolean(formik.errors.celular) && formik.touched.celular}
                helperText={
                  Boolean(formik.touched.celular) && formik.errors.celular
                }
                placeholder={formik.errors.celular}
                inputProps={{
                  maxLength: 15,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.celular && !formik.errors.celular && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkIcon} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
              <StyledTextField
                onMouseEnter={(event) => {
                  handlePopoverOpen(event);
                }}
                onMouseLeave={handlePopoverClose}
                style={{ marginTop: '16px' }}
                id="email"
                name="email"
                label="E-mail pessoal *"
                variant="outlined"
                type="email"
                disabled
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email) && formik.touched.email}
                helperText={
                  Boolean(formik.touched.email) && formik.errors.email
                }
                placeholder={formik.errors.email}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.email && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkMarkLock} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
              <ContainerDoubleTextField>
                <StyledTextFieldDouble
                  onMouseEnter={(event) => {
                    if (naoDesabilita && disabled_matricula) {
                      handlePopoverOpen(event);
                    }
                  }}
                  onMouseLeave={handlePopoverClose}
                  style={{ marginTop: '16px' }}
                  id="telefone"
                  name="matricula"
                  label="Matrícula da patrocinadora *"
                  variant="outlined"
                  type="text"
                  value={formik.values.matricula}
                  onChange={(event) => {
                    const matricula = event.target.value.replace(/\D/g, '');
                    formik.setFieldValue('matricula', matricula);
                  }}
                  error={
                    Boolean(formik.errors.matricula) && formik.touched.matricula
                  }
                  helperText={
                    Boolean(formik.touched.matricula) && formik.errors.matricula
                  }
                  placeholder={formik.errors.matricula}
                  inputProps={{
                    maxLength: 9,
                  }}
                  disabled={
                    !naoDesabilita
                      ? false
                      : disabled_matricula || disabledAll || disabledForm
                  }
                  InputProps={{
                    endAdornment: (
                      <>
                        {(disabled_matricula ||
                          disabledAll ||
                          disabledForm) && (
                          <InputAdornment
                            style={{ marginTop: '5px' }}
                            position="end"
                          >
                            <ImageProfile src={checkMarkLock} />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
                <LocalizationProvider
                  adapterLocale={ptBR}
                  dateAdapter={AdapterDateFns}
                >
                  <DesktopDatePicker
                    onMouseEnter={(event) => {
                      if (naoDesabilita && disabled_dtAdmissao) {
                        handlePopoverOpen(event);
                      }
                    }}
                    onMouseLeave={handlePopoverClose}
                    maxDate={new Date()}
                    minDate={formik.values.dataNascimento}
                    label="Data de admissão *"
                    disabled={
                      !naoDesabilita
                        ? false
                        : disabled_dtAdmissao || disabledAll || disabledForm
                    }
                    value={formik.values.dataAdmissao}
                    onChange={(value) => {
                      formik.setFieldValue('dataAdmissao', value);
                    }}
                    slotProps={{
                      textField: {
                        style: { marginTop: '16px' },
                        name: 'dataAdmissao',
                        onBlur: formik.handleBlur,
                        error:
                          formik.touched.dataAdmissao &&
                          Boolean(formik.errors.dataAdmissao),
                        helperText:
                          formik.touched.dataAdmissao &&
                          formik.errors.dataAdmissao,
                      },
                    }}
                  />
                </LocalizationProvider>
              </ContainerDoubleTextField>

              <StyledTextField
                style={{ marginTop: '16px' }}
                id="cep"
                name="cep"
                label="CEP *"
                variant="outlined"
                type="text"
                maxLength="8"
                disabled={(!naoDesabilita ? false : disabledAll) || loadingCep}
                value={formik.values.cep}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const cep = event.target.value.replace(/\D/g, '');
                  formik.setFieldValue('cep', cep);
                  if (cep.length >= 8) {
                    handleCep(cep);
                  }
                }}
                error={Boolean(formik.errors.cep) && formik.touched.cep}
                helperText={formik.touched.cep && formik.errors.cep}
                placeholder={formik.errors.cep}
                inputProps={{ maxLength: 8 }}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.cep && !loadingCep && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkIcon} />
                        </InputAdornment>
                      )}
                      {loadingCep && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          sx={{ display: 'inline-flex' }}
                        />
                      )}
                    </>
                  ),
                }}
              />

              <StyledTextField
                style={{ marginTop: '16px' }}
                id="rua"
                name="rua"
                label="Logradouro *"
                variant="outlined"
                type="text"
                disabled={(!naoDesabilita ? false : disabledAll) || loadingCep}
                value={formik.values.rua}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.rua) && formik.touched.rua}
                helperText={Boolean(formik.touched.rua) && formik.errors.rua}
                placeholder={formik.errors.rua}
                inputProps={{
                  maxLength: 60,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.rua && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkIcon} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />

              <StyledTextField
                style={{ marginTop: '16px' }}
                id="bairro"
                name="bairro"
                label="Bairro *"
                variant="outlined"
                type="text"
                disabled={(!naoDesabilita ? false : disabledAll) || loadingCep}
                value={formik.values.bairro}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.bairro) && formik.touched.bairro}
                helperText={
                  Boolean(formik.touched.bairro) && formik.errors.bairro
                }
                placeholder={formik.errors.bairro}
                inputProps={{ maxLength: 30 }}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.bairro && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkIcon} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />

              <StyledTextField
                style={{ marginTop: '16px' }}
                id="cidade"
                name="cidade"
                label="Cidade *"
                variant="outlined"
                type="text"
                disabled={(!naoDesabilita ? false : disabledAll) || loadingCep}
                value={formik.values.cidade}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.cidade) && formik.touched.cidade}
                helperText={
                  Boolean(formik.touched.cidade) && formik.errors.cidade
                }
                placeholder={formik.errors.cidade}
                inputProps={{
                  maxLength: 40,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.cidade && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkIcon} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />

              <StyledTextField
                style={{ marginTop: '16px' }}
                id="estado"
                name="estado"
                label="Estado *"
                variant="outlined"
                type="text"
                disabled={(!naoDesabilita ? false : disabledAll) || loadingCep}
                value={formik.values.estado}
                onChange={(e) => {
                  formik.setFieldValue(
                    e.target.name,
                    e.target.value.replace(/[^A-Z]/gi, '').toUpperCase()
                  );
                }}
                error={Boolean(formik.errors.estado) && formik.touched.estado}
                helperText={
                  Boolean(formik.touched.estado) && formik.errors.estado
                }
                placeholder={formik.errors.estado}
                inputProps={{
                  maxLength: 2,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.estado && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkIcon} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />

              <StyledTextField
                style={{ marginTop: '16px' }}
                id="numero"
                name="numero"
                label="Numero *"
                variant="outlined"
                type="text"
                disabled={(!naoDesabilita ? false : disabledAll) || loadingCep}
                value={formik.values.numero}
                onChange={(event) => {
                  formik.setFieldValue(
                    'numero',
                    event.target.value.replace(/\D/g, '')
                  );
                }}
                inputProps={{
                  maxLength: 7,
                }}
                error={Boolean(formik.errors.numero) && formik.touched.numero}
                helperText={
                  Boolean(formik.touched.numero) && formik.errors.numero
                }
                placeholder={formik.errors.numero}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.numero && (
                        <InputAdornment
                          style={{ marginTop: '5px' }}
                          position="end"
                        >
                          <ImageProfile src={checkIcon} />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />

              <StyledTextField
                style={{ marginTop: '16px' }}
                id="complemento"
                name="complemento"
                label="Complemento"
                variant="outlined"
                type="text"
                disabled={(!naoDesabilita ? false : disabledAll) || loadingCep}
                value={formik.values.complemento}
                onChange={formik.handleChange}
                error={
                  Boolean(formik.errors.complemento) &&
                  formik.touched.complemento
                }
                helperText={
                  Boolean(formik.touched.complemento) &&
                  formik.errors.complemento
                }
                inputProps={{
                  maxLength: 30,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {formik.values.complemento &&
                        !formik.errors.complemento && (
                          <InputAdornment
                            style={{ marginTop: '5px' }}
                            position="end"
                          >
                            <ImageProfile src={checkIcon} />
                          </InputAdornment>
                        )}
                    </>
                  ),
                }}
              />

              <Box style={{ marginTop: '16px' }}>
                <FormControl
                  style={{ width: '100%' }}
                  error={
                    Boolean(formik.errors.nacionalidade) &&
                    formik.touched.nacionalidade
                  }
                >
                  <InputLabel
                    id="nacionalidade-id"
                    sx={{ background: '#fff', px: '4px', ml: '-4px' }}
                  >
                    Nacionalidade *
                  </InputLabel>
                  <SelectStyled
                    labelId="nacionalidade-id"
                    id="nacionalidade"
                    name="nacionalidade"
                    variant="outlined"
                    type="text"
                    disabled={!naoDesabilita ? false : disabledAll}
                    value={formik.values.nacionalidade}
                    onChange={(e) => {
                      if (e.target.value !== 'BRA') {
                        formik.setFieldValue('estadoNacionalidade', '');
                        formik.setFieldValue('naturalidade', '');
                        formik.setFieldValue('numIdLocalidade', '');
                      }
                      return formik.handleChange(e);
                    }}
                    sx={{ borderRadius: '12px' }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {formik.values.nacionalidade && (
                            <InputAdornment
                              style={{ marginTop: '5px' }}
                              position="end"
                            >
                              <ImageProfile src={checkIcon} />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  >
                    <MenuItem value="">--Selecione--</MenuItem>
                    {nacionalidade.map((data, i) => (
                      <MenuItem key={i} value={data.code}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </SelectStyled>
                  {Boolean(formik.errors.nacionalidade) &&
                    formik.touched.nacionalidade && (
                      <FormHelperText>
                        {formik.errors.nacionalidade}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>

              {formik.values.nacionalidade === 'BRA' && (
                <Box style={{ marginTop: '16px' }}>
                  <FormControl
                    style={{ width: '100%' }}
                    error={
                      Boolean(formik.errors.estadoNacionalidade) &&
                      formik.touched.estadoNacionalidade
                    }
                  >
                    <InputLabel
                      sx={{ background: '#fff', px: '4px', ml: '-4px' }}
                    >
                      Naturalidade Estado *
                    </InputLabel>
                    <SelectStyled
                      name="estadoNacionalidade"
                      disabled={!naoDesabilita ? false : disabledAll}
                      value={formik.values.estadoNacionalidade}
                      onChange={(e) => {
                        formik.setFieldValue('numIdLocalidade', '');
                        handleLoadCidadeLista(e.target.value);
                        return formik.handleChange(e);
                      }}
                      sx={{ borderRadius: '12px' }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {formik.values.estadoNacionalidade && (
                              <InputAdornment
                                style={{ marginTop: '5px' }}
                                position="end"
                              >
                                <ImageProfile src={checkIcon} />
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                    >
                      {estadoNacionalidadeLista.map((data, i) => (
                        <MenuItem key={i} value={data.sigla}>
                          {data.nome}
                        </MenuItem>
                      ))}
                    </SelectStyled>
                    {Boolean(formik.errors.estadoNacionalidade) &&
                      formik.touched.estadoNacionalidade && (
                        <FormHelperText>
                          {formik.errors.estadoNacionalidade}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Box>
              )}

              {formik.values.nacionalidade === 'BRA' && (
                <Box style={{ marginTop: '16px' }}>
                  <FormControl
                    style={{ width: '100%' }}
                    error={
                      Boolean(formik.errors.numIdLocalidade) &&
                      formik.touched.numIdLocalidade
                    }
                  >
                    <InputLabel id="e-naturalidade-id">
                      Naturalidade Município *
                    </InputLabel>
                    <SelectStyled
                      id="numIdLocalidade"
                      name="numIdLocalidade"
                      label="Naturalidade Município *"
                      variant="outlined"
                      type="text"
                      disabled={!naoDesabilita ? false : disabledAll}
                      value={formik.values.numIdLocalidade}
                      onChange={formik.handleChange}
                      sx={{ borderRadius: '12px' }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {formik.values.numIdLocalidade && (
                              <InputAdornment
                                style={{ marginTop: '5px' }}
                                position="end"
                              >
                                <ImageProfile src={checkIcon} />
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                    >
                      {cidadeLista.map((data, i) => (
                        <MenuItem key={i} value={data.numIdLocalidade}>
                          {data.nome}
                        </MenuItem>
                      ))}
                    </SelectStyled>
                    {Boolean(formik.errors.numIdLocalidade) &&
                      formik.touched.numIdLocalidade && (
                        <FormHelperText>
                          {formik.errors.numIdLocalidade}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Box>
              )}

              <SwitchContainer>
                <ContainerPessoaExposta>
                  Pessoa politicamente exposta
                  <Tooltip title="Saiba mais sobre PPE" placement="right">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <AiFillQuestionCircle
                        size={22}
                        color="#4F4F4F"
                        onClick={() => {
                          openModalPPE();
                        }}
                      />
                    </div>
                  </Tooltip>
                </ContainerPessoaExposta>
                <Switch
                  onChange={() =>
                    formik.setFieldValue(
                      'pessoaExposta',
                      !formik.values.pessoaExposta
                    )
                  }
                  checked={formik.values.pessoaExposta}
                  className="react-switch"
                  offColor="#CC390A"
                  onColor="#4CAF50"
                  width={77}
                  height={32}
                  disabled={disabledAll}
                  uncheckedIcon={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        fontSize: 15,
                        color: '#FFF',
                        paddingRight: 2,
                      }}
                    >
                      Não
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        fontSize: 15,
                        color: '#FFF',
                        paddingRight: 2,
                      }}
                    >
                      Sim
                    </div>
                  }
                />
              </SwitchContainer>

              {formik.values.pessoaExposta && (
                <ContainerPessoaExpostaInputs>
                  <StyledTextField
                    style={{ marginTop: '16px' }}
                    id="orgao-pep"
                    name="orgaoPEP"
                    label="Órgão PPE"
                    variant="outlined"
                    type="text"
                    value={formik.values.orgaoPEP}
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.orgaoPEP) && formik.touched.orgaoPEP
                    }
                    helperText={
                      formik.touched.orgaoPEP && formik.errors.orgaoPEP
                    }
                    placeholder={formik.errors.orgaoPEP}
                    disabled={disabledAll}
                    inputProps={{
                      maxLength: 120,
                    }}
                  />
                  <StyledTextField
                    style={{ marginTop: '16px' }}
                    id="cargo"
                    name="cargoPEP"
                    label="Cargo PPE"
                    variant="outlined"
                    type="text"
                    value={formik.values.cargoPEP}
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.cargoPEP) && formik.touched.cargoPEP
                    }
                    helperText={
                      formik.touched.cargoPEP && formik.errors.cargoPEP
                    }
                    placeholder={formik.errors.cargoPEP}
                    disabled={disabledAll}
                    inputProps={{
                      maxLength: 120,
                    }}
                  />
                  <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}
                  >
                    <DesktopDatePicker
                      maxDate={new Date()}
                      label="Data de nomeação"
                      value={formik.values.dataNomeacao}
                      onChange={(value) => {
                        formik.setFieldValue('dataNomeacao', value);
                      }}
                      disabled={disabledAll}
                      slotProps={{
                        textField: {
                          style: { marginTop: '16px' },
                          name: 'dataNomeacao',
                          onBlur: formik.handleBlur,
                          error:
                            formik.touched.dataNomeacao &&
                            Boolean(formik.errors.dataNomeacao),
                          helperText:
                            formik.touched.dataNomeacao &&
                            formik.errors.dataNomeacao,
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}
                  >
                    <DesktopDatePicker
                      maxDate={new Date()}
                      label="Data de exoneração"
                      inputFormat="dd/MM/yyyy"
                      minDate={formik.values.dataNomeacao}
                      value={formik.values.dataExoneracao}
                      onChange={(value) => {
                        formik.setFieldValue('dataExoneracao', value);
                      }}
                      disabled={disabledAll}
                      slotProps={{
                        textField: {
                          style: { marginTop: '16px' },
                          name: 'dataExoneracao',
                          onBlur: formik.handleBlur,
                          error:
                            formik.touched.dataExoneracao &&
                            Boolean(formik.errors.dataExoneracao),
                          helperText:
                            formik.touched.dataExoneracao &&
                            formik.errors.dataExoneracao,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </ContainerPessoaExpostaInputs>
              )}
              <TermosDeUsoContainer>
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  checked={disabledAll ? true : formik.values.termos}
                  onChange={() =>
                    formik.setFieldValue('termos', !formik.values.termos)
                  }
                  color="primary"
                  inputProps={{ 'aria-label': 'controlled' }}
                  disabled={disabledAll}
                />
                <TermosDeUsoText>
                  <p>
                    Declaro, para os devidos fins, que as informações prestadas
                    acima são verdadeiras e assumo inteira responsabilidade
                    pelas mesmas.
                  </p>
                </TermosDeUsoText>
              </TermosDeUsoContainer>
              <ContainerButton>
                <Button normal type="submit" disabled={loadingAvancar}>
                  Avançar
                </Button>
              </ContainerButton>
            </ContainerForm>

            <ContainerDocumentos>
              {files && files.length > 0 ? (
                <FileList>
                  {files.map((file, i) => (
                    <FileItem key={i}>
                      <img src={docPreview} alt={file.name} />
                      <p
                        style={{
                          display: 'flex',
                          width: '100%',
                          wordBreak: 'break-word',
                          marginRight: '1em',
                        }}
                      >
                        {file.name}
                      </p>
                    </FileItem>
                  ))}
                </FileList>
              ) : null}

              {anchorEl && (
                <TootipAviso>
                  <ImageAviso src={checkMarkLock} alt="checkmark" />
                  <h3>Atenção</h3>

                  <p>
                    Este dado não pode ser alterado por você. Entre em contato
                    com a Vibra para fazer a correção. Depois disso, prossiga
                    com sua inscrição.
                  </p>
                </TootipAviso>
              )}
            </ContainerDocumentos>
          </WrapperForm>

          <ModalPPE showModal={showModalPPE} setShowModal={setShowModalPPE} />
        </Container>
      )}

      {mobileLayout && <FormularioMobile />}
    </>
  );
}
