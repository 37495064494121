import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Grid, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import ptBR from 'date-fns/locale/pt-BR';
import { useResponsive } from 'hook/useResponsive';
import formataCpf from 'util/formatarCPF';
import formataTelefone from 'util/formatarTelefone';

import { Button } from 'components/Button';
import { ModalAvisoEmail } from 'components/ModalAvisoEmail';
import {
  ModalSenha,
  ModalSenhaEmail,
  ModalSenhaSucesso,
} from 'components/ModalSenha';
import { PasswordInput } from 'components/PasswordInput';

import { useLogin } from 'context/Login';
import { useModal } from 'context/Modal';
import { usePrimeiroAcesso } from 'context/PrimeiroAcesso';

import { LoginMobile } from './mobile';
import { ContainerTop, Footer, TermosDeUsoContainer, Wrapper } from './styles';

export function Login() {
  const { mobileLayout } = useResponsive();
  const { isPrimeiroAcesso, formik, trocarAcesso } = usePrimeiroAcesso();
  const { formik: formikLogin, loading } = useLogin();
  const {
    showModal,
    setShowModal,
    setShowModalSucesso,
    showModalSucesso,
    openModal,
    showModalEmail,
    setShowModalEmail,
    showModalAvisoEmail,
    setShowModalAvisoEmail,
  } = useModal();

  const handlePaste = (event) => {
    event.preventDefault();
    return false;
  };

  return (
    <>
      {!mobileLayout && (
        <Wrapper>
          <ContainerTop>
            <Button
              contained={isPrimeiroAcesso == 'login'}
              outlined={isPrimeiroAcesso != 'login'}
              onClick={() => {
                trocarAcesso('login');
              }}
            >
              Já sou cadastrado
            </Button>

            <Button
              contained={isPrimeiroAcesso == 'primeiroAcesso'}
              outlined={!isPrimeiroAcesso != 'primeiroAcesso'}
              onClick={() => {
                trocarAcesso('primeiroAcesso');
              }}
            >
              Primeiro acesso?
            </Button>
          </ContainerTop>

          {isPrimeiroAcesso == 'login' ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formikLogin.handleSubmit(e);
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label="E-mail"
                    variant="outlined"
                    type="email"
                    value={formikLogin.values.email}
                    onChange={formikLogin.handleChange}
                    onBlur={formikLogin.handleBlur}
                    inputProps={{
                      maxLength: 100,
                    }}
                    error={
                      Boolean(formikLogin.errors.email) &&
                      formikLogin.touched.email
                    }
                    helperText={
                      formikLogin.touched.email && formikLogin.errors.email
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    id="senha"
                    name="senha"
                    label="Senha"
                    error={formikLogin.errors.senha}
                    value={formikLogin.values.senha}
                    onChange={formikLogin.handleChange}
                    onBlur={formikLogin.handleBlur}
                  />
                </Grid>
              </Grid>
              <Footer
                style={isPrimeiroAcesso ? { height: '28%' } : { height: '15%' }}
              >
                <Button
                  none
                  onClick={() => {
                    openModal();
                  }}
                >
                  Esqueceu a senha?
                </Button>

                <Button normal type="submit" disabled={loading}>
                  Avançar
                </Button>
              </Footer>
            </form>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <TextField
                    id="nome"
                    name="nome"
                    label="Nome"
                    type="text"
                    variant="outlined"
                    value={formik.values.nome}
                    onChange={(e) => {
                      formik.setFieldValue(
                        e.target.name,
                        e.target.value
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                      );
                    }}
                    onBlur={formik.handleBlur}
                    inputProps={{
                      maxLength: 60,
                    }}
                    error={Boolean(formik.errors.nome) && formik.touched.nome}
                    helperText={formik.touched.nome && formik.errors.nome}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    id="cpf"
                    name="cpf"
                    label="CPF"
                    variant="outlined"
                    type="text"
                    value={formataCpf(formik.values.cpf)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputProps={{
                      maxLength: 14,
                    }}
                    error={Boolean(formik.errors.cpf) && formik.touched.cpf}
                    helperText={formik.touched.cpf && formik.errors.cpf}
                  />
                </Grid>
                <Grid item md={4}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ptBR}
                  >
                    <DesktopDatePicker
                      label="Data de nascimento"
                      value={formik.values.dataNascimento}
                      onChange={(value) => {
                        formik.setFieldValue('dataNascimento', value);
                      }}
                      slotProps={{
                        textField: {
                          name: 'dataNascimento',
                          onBlur: formik.handleBlur,
                          error:
                            formik.touched.dataNascimento &&
                            Boolean(formik.errors.dataNascimento),
                          helperText:
                            formik.touched.dataNascimento &&
                            formik.errors.dataNascimento,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={4}>
                  <TextField
                    id="celular"
                    name="celular"
                    label="Celular"
                    variant="outlined"
                    type="text"
                    value={formataTelefone(formik.values.celular)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputProps={{ maxLength: 15 }}
                    error={
                      Boolean(formik.errors.celular) && formik.touched.celular
                    }
                    helperText={formik.touched.celular && formik.errors.celular}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="email"
                    name="email"
                    label="E-mail pessoal"
                    variant="outlined"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputProps={{ maxLength: 100 }}
                    error={Boolean(formik.errors.email) && formik.touched.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    id="emailConfirmacao"
                    name="emailConfirmacao"
                    label="Confirmação de e-mail"
                    variant="outlined"
                    type="email"
                    value={formik.values.emailConfirmacao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputProps={{ maxLength: 100 }}
                    error={
                      Boolean(formik.errors.emailConfirmacao) &&
                      formik.touched.emailConfirmacao
                    }
                    helperText={
                      formik.touched.emailConfirmacao &&
                      formik.errors.emailConfirmacao
                    }
                    onPaste={handlePaste}
                  />
                </Grid>

                <Grid item md={12}>
                  <div style={{ display: 'flex', gap: 12 }}>
                    <PasswordInput
                      id="senha"
                      name="senha"
                      label="Senha"
                      error={formik.errors.senha}
                      value={formik.values.senha}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    <PasswordInput
                      id="senhaConfirmacao"
                      name="senhaConfirmacao"
                      label="Confirmação de Senha"
                      error={formik.errors.senhaConfirmacao}
                      value={formik.values.senhaConfirmacao}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onPaste={handlePaste}
                    />
                  </div>
                </Grid>
              </Grid>
              <TermosDeUsoContainer>
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  checked={formik.values.termos}
                  onChange={() =>
                    formik.setFieldValue('termos', !formik.values.termos)
                  }
                  color="primary"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <p>
                  Ao clicar em "Avançar" e continuar com o seu cadastro, você
                  autoriza a Petros a coletar seus dados pessoais de acordo com
                  a nossa{' '}
                  <a
                    href="https://dpo.privacytools.com.br/policy-view/oB3DljgrO/1/poli%CC%81tica-de-privacidade/pt_BR"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Política de Privacidade</strong>
                  </a>
                  , com o objetivo de comunicar informações sobre o processo de
                  adesão.
                </p>
              </TermosDeUsoContainer>
              <Footer>
                <Button normal type="submit">
                  Avançar
                </Button>
              </Footer>
            </form>
          )}
        </Wrapper>
      )}

      {mobileLayout && <LoginMobile />}

      <ModalSenha showModal={showModal} setShowModal={setShowModal} />
      <ModalSenhaSucesso
        showModal={showModalSucesso}
        setShowModal={setShowModalSucesso}
      />
      <ModalSenhaEmail
        showModal={showModalEmail}
        setShowModal={setShowModalEmail}
      />
      <ModalAvisoEmail
        showModal={showModalAvisoEmail}
        setShowModal={setShowModalAvisoEmail}
      />
    </>
  );
}
