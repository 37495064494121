/* eslint-disable no-self-compare */
import { useSteps } from 'context/Steps';

import CheckIcon from 'assets/images/check.svg';

import { steps } from './item';
import { Container, Stepper, StepperNumero, StepperText } from './styles';

export function Steps() {
  const { activeStep, selectStep, isCompletedSteps } = useSteps();

  return (
    <Container>
      {steps.map((step) => (
        <Stepper
          disabled={activeStep != step.index}
          key={step.index}
          onClick={() => {
            selectStep(step.index);
          }}
        >
          {isCompletedSteps(step.index) ? (
            <StepperNumero key={step.numero} verificado>
              <img src={CheckIcon} alt="check" />
            </StepperNumero>
          ) : (
            <StepperNumero>{step.numero}</StepperNumero>
          )}

          <StepperText>{step.text}</StepperText>
        </Stepper>
      ))}
    </Container>
  );
}

export function StepsMobile() {
  const { activeStep, selectStep, isCompletedSteps } = useSteps();

  return (
    <Container mobile>
      {steps.map((step) => (
        <Stepper
          mobile
          mobileDisabled={activeStep != step.index}
          key={step.index}
          onClick={() => {
            selectStep(step.index);
          }}
        >
          {isCompletedSteps(step.index) ? (
            <StepperNumero mobile key={step.numero} verificado>
              <img src={CheckIcon} alt="check" />
            </StepperNumero>
          ) : (
            <StepperNumero mobile>{step.numero}</StepperNumero>
          )}
        </Stepper>
      ))}
    </Container>
  );
}
