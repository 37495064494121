import styled from 'styled-components';

export const ListPreview = styled.div`
  overflow: auto;
  height: 150px;
  width: 100%;

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
`;

export const ListPreviewItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0;
`;

export const ListItem = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-around;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      background: #828282;
      border-radius: 4px;
      font-size: 15px;
      width: 30px;
      text-align: center;
      color: #c4c4c4;
      font-weight: bold;
    }
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
