import React, { createContext, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const ToastContext = createContext();

export function ToastProvider({ children }) {
  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      <div className="toast-wrapper">
        <Toaster
          position="top-right"
          reverseOrder={false}
          containerStyle={{
            top: 10,
          }}
          toastOptions={{
            // style: {
            //   minWidth: '10em',
            //   height: '2.5em',
            //   display: 'flex',
            //   fontSize: '1.2em',
            //   fontWeight: 'normal',
            //   background: '#FFb700',
            //   color: 'white',
            //   zIndex: 1,
            // },
            loading: {
              style: {
                minWidth: '10em',
                height: '2.5em',
                display: 'flex',
                lineHeight: '3px',
                background: '#f4f4f4',
                color: '#000',
                zIndex: 1,
              },
            },
            error: {
              style: {
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                background: '#fff',
                color: '#000',
              },
            },
            success: {
              style: {
                display: 'flex',
                justifyContent: 'center',
                background: '#fff',
                color: '#000',
              },
              duration: 2000,
            },
          }}
        />
      </div>
    </ToastContext.Provider>
  );
}

export function useToast() {
  const context = useContext(ToastContext);

  return context;
}
