// import { getTravarUser } from 'services/loginServices';

import { ConcluirInscricao } from 'pages/ConcluirInscricao';
import { Status } from 'pages/Status';

import { useAuth } from 'context/Auth';
import { useSteps } from 'context/Steps';

export function StatusEConfirmacao() {
  const { travaCadastro } = useAuth();
  const { page } = useSteps();

  return (
    <>
      {travaCadastro == 2 ? (
        <Status />
      ) : (
        <>{page == 'confirmacao' ? <ConcluirInscricao /> : <Status />}</>
      )}
    </>
  );
}
