import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { downloadZip } from 'client-zip';
import { useResponsive } from 'hook/useResponsive';
import moment from 'moment';
// import { getAccessToken } from 'services/sensediaServices';
import { gerarSenha } from 'services/statusServices';
import { getOneUser } from 'services/usuariosServices';

import { Button } from 'components/Button';

import { useToast } from 'context/Toast';

import regulamentoPetros from 'assets/files/1_Regulamento_Plano_FlexPrev_BR.pdf';
import estatutoPetros from 'assets/files/Estatuto_Petros.pdf';
import guiaFlexPrev from 'assets/files/guia_flexprev_adesao_13_12_link_COM.pdf';
import appStoreImg from 'assets/images/apple_store.svg';
import copyImg from 'assets/images/copy.svg';
import donwloadImg from 'assets/images/download.svg';
import playStoreImg from 'assets/images/play_store.svg';
import qrCode from 'assets/images/qrcode.svg';
import sendImg from 'assets/images/send.svg';

import { AprovadoMobile } from './mobile';
import {
  BaixeAppContainer,
  Container,
  ContainerAprovado,
  ContainerButton,
  ContainerInformacoes,
  ContainerLojasApp,
  Image,
  MatriculaContainer,
  NumeroMatricula,
  QrCodeContainer,
  SenhaPetrosContainer,
  TitleContainer,
  Wrapper,
} from './styles';

export function Aprovado(props) {
  const { toast } = useToast();
  const { mobileLayout } = useResponsive();

  const [copied, setCopied] = useState(false);
  const [matricula, setMatricula] = useState('');
  const [numeroPedido, setNumeroPedido] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');

  const verificaDados = async () => {
    const { numPedido, numIdPf, numMatriculaPetros } = JSON.parse(
      sessionStorage.getItem('@user:dados')
    )
      ? JSON.parse(sessionStorage.getItem('@user:dados'))
      : null;

    const user = await getOneUser(numPedido, numIdPf);
    const { dtNascimento, cpf, nome } = user;

    const nomeSplit = nome.split(' ');
    const primeiroNome = nomeSplit[0];

    setNome(primeiroNome);
    setNumeroPedido(numPedido);
    setDataNascimento(dtNascimento);
    setCpf(cpf);

    // const { numMatriculaPetros } = await getPedidos(numPedido);

    setMatricula(numMatriculaPetros);
  };

  useEffect(() => {
    verificaDados();
  }, []);

  const AppleStoreLink = () => {
    window.open(
      'https://apps.apple.com/br/app/petros-app/id1440476590',
      '_blank'
    );
  };

  const PlayStoreLink = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.petros&hl=pt_BR&gl=US',
      '_blank'
    );
  };

  const portalPetros = () => {
    window.open('https://www.petros.com.br/PortalPetros/', '_blank');
  };

  async function downloadArquivosZIP() {
    const guia = await fetch(guiaFlexPrev);
    const estatuto = await fetch(estatutoPetros);
    const regulamento = await fetch(regulamentoPetros);
    const certificado = await fetch(
      `${process.env.REACT_APP_URL_API}/pdf/986/certificadoadesao?codPatrocinadora=0004&matriculaPetros=${matricula}&numPedido=${numeroPedido}`,
      {
        headers: {
          client_id: process.env.REACT_APP_CLIENT_ID,
          access_token: localStorage.getItem('accessToken'),
          authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
          'user-authorization': `Bearer ${localStorage.getItem('userToken')}`,
        },
      }
    );
    const comprovante = await fetch(
      `${process.env.REACT_APP_URL_API}/pdf/986/${numeroPedido}/comprovante`,
      {
        headers: {
          client_id: process.env.REACT_APP_CLIENT_ID,
          access_token: localStorage.getItem('accessToken'),
          authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
          'user-authorization': `Bearer ${localStorage.getItem('userToken')}`,
        },
      }
    );

    const certificadoPdf = {
      name: 'certificado_adesao.pdf',
      input: certificado,
    };
    const comprovantePdf = {
      name: 'comprovante.pdf',
      input: comprovante,
    };

    const blob = await downloadZip([
      guia,
      estatuto,
      regulamento,
      certificadoPdf,
      comprovantePdf,
    ]).blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Boas_vindas_${nome}_${matricula}.zip`;
    link.click();
    link.remove();
  }

  const handleDonwload = async () => {
    try {
      toast.loading('Gerando arquivo...', { id: 'download' });
      await downloadArquivosZIP();
      toast.success('Arquivo gerado com sucesso!', { id: 'download' });
    } catch (error) {
      toast.error(error.message);
      toast.error('Erro ao baixar arquivos', { id: 'download' });
    }
  };

  const handleSendPassword = async () => {
    const dataNascimentoFormatada = moment(dataNascimento)
      .add(1, 'days')
      .format('DD/MM/YYYY');
    const cpfFormatado = cpf.replace(/\D/g, '');

    try {
      await gerarSenha(matricula, cpfFormatado, dataNascimentoFormatada);
      toast.success('Email enviado com sucesso!');
    } catch (error) {
      toast.error(error.response.data.message, {
        id: 'error-send-email',
      });
    }
  };

  return (
    <>
      {!mobileLayout && (
        <Container>
          <TitleContainer>
            <h2>Seu pedido de inscrição foi aprovado</h2>
          </TitleContainer>
          <Wrapper>
            <ContainerAprovado>
              <h3>Bem-vindo ao Flexprev!</h3>
              <p>
                Você agora é participante ativo do Flexprev. Acesse o kit de
                adesão para saber mais sobre o plano. No nosso portal
                (www.petros.com.br) , com sua matrícula Petros, disponível no
                quadro abaixo, você pode gerar uma senha de acesso à Área do
                Participante, onde você encontra informações relevantes sobre o
                plano e os registros de suas contribuições e da patrocinadora.
                Com a mesma senha, entre no nosso aplicativo.
              </p>

              <MatriculaContainer>
                <h3>Matricula Petros</h3>
                <CopyToClipboard
                  options={{ debug: props.debug, message: '' }}
                  text={matricula}
                  onCopy={() => setCopied(true)}
                >
                  <NumeroMatricula>
                    <h2>{matricula}</h2>
                    <Image src={copyImg} alt="Copiar" />
                  </NumeroMatricula>
                </CopyToClipboard>
                {copied ? <p>Texto copiado com sucesso!</p> : <></>}
              </MatriculaContainer>
              <SenhaPetrosContainer>
                <h3>Sua senha Petros</h3>

                <Button
                  senha
                  onClick={() => {
                    handleSendPassword();
                  }}
                  endIcon={<Image src={sendImg} alt="Enviar" />}
                >
                  Enviar senha por e-mail
                </Button>
              </SenhaPetrosContainer>
            </ContainerAprovado>
            <ContainerInformacoes>
              <ContainerButton>
                <Button
                  kit
                  onClick={() => handleDonwload()}
                  startIcon={<Image src={donwloadImg} alt="Download" />}
                >
                  Baixar kit de adesão
                </Button>
                {/* <a
                  href="/pi-flexprev/files/TERMO DE OPCAO PARA REGIME DE TRIBUTACAO - FLEXPREV.pdf"
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    kit
                    startIcon={<Image src={donwloadImg} alt="Download" />}
                  >
                    Formulário de Tributação
                  </Button>
                </a> */}
                {/* <Button
                    kit
                    startIcon={
                      <Image src={sendEmailImg} alt="Enviar por Email" />
                    }
                  >
                    Receber kit por e-mail
                  </Button> */}
              </ContainerButton>
              <BaixeAppContainer>
                <h2>Baixe o nosso app</h2>
                <ContainerLojasApp>
                  <Image
                    src={appStoreImg}
                    alt="Apple Store"
                    onClick={() => AppleStoreLink()}
                  />
                  <Image
                    src={playStoreImg}
                    alt="Play Store"
                    onClick={() => {
                      PlayStoreLink();
                    }}
                  />
                </ContainerLojasApp>
                <QrCodeContainer>
                  <img src={qrCode} alt="QrCode" />
                </QrCodeContainer>
              </BaixeAppContainer>

              <ContainerButton>
                <Button
                  normal
                  onClick={() => {
                    portalPetros();
                  }}
                >
                  Ir para o Portal Petros
                </Button>
              </ContainerButton>
            </ContainerInformacoes>
          </Wrapper>
        </Container>
      )}

      {mobileLayout && <AprovadoMobile />}
    </>
  );
}
