import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ReactSlider from 'react-slider';

import { TextField } from '@mui/material';

import { breakpoints } from 'hook/useMedia';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 80vh;
  flex-direction: column;
  justify-content: space-evenly;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
      justify-content: initial;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 95%;
  margin-left: 1rem;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 2rem;
      width: 100%;
      margin-left: 0;
      justify-content: center;
      text-align: center;

      h2 {
        font-size: 16px;
      }
    `}
`;

export const StyledTextField = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '300px',
      height: '57px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const ContainerMid = styled.div`
  display: flex;
  margin: 0px 15px;

  ${(props) =>
    props.mobile &&
    css`
      flex-direction: column;

      width: 100%;
    `}
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.mobile &&
    css`
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    `}
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-left: 35px;

  @media only screen and ${breakpoints.xl} {
    height: 20rem;
  }
`;

export const ContainerCircularProgressBar = styled.div`
  width: 150px;
  height: 150px;
  display: flex;

  ${(props) =>
    props.mobile &&
    css`
      width: 125px;
      height: 125px;
    `}
`;

export const ContainerCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 15px;
      flex-direction: row;
    `}
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  width: 159px;
  height: 74px;
  margin: 10px 0px;

  background: #fff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export const CardMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  height: 74px;
`;

export const PorcentagemText = styled.div`
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: #4f4f4f;
`;

export const DinheiroText = styled.div`
  color: #346a0c;
  line-height: 14px;
`;

export const DescricaoText = styled.div`
  font-size: 11px;
  line-height: 10px;
  text-align: center;
  color: #828282;
`;

export const ImageProfile = styled.img``;

export const ContainerContribuicao = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
`;

export const ContribuicaoBasica = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-right: 32px;

  h2 {
    font-size: 19px;
    line-height: 18px;
    text-align: center;
    color: #828282;
    font-weight: normal;
    text-align: initial;
  }

  p {
    width: 50%;
    color: #828282;
    font-size: 12px;
    height: 9rem;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

export const ContribuicaoBasicaTitle = styled.h2`
  font-size: 19px;
  line-height: 18px;
  text-align: center;
  color: #828282;
  font-weight: bold;
  text-align: initial;
`;

export const ContribuicaoVoluntaria = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  h2 {
    font-size: 19px;
    line-height: 18px;
    text-align: center;
    color: #828282;
    font-weight: normal;
    text-align: initial;
  }

  p {
    width: 50%;
    color: #828282;
    font-size: 12px;
    height: 5rem;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

export const ContribuicaoParticipante = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /* h2 {
    font-size: 19px;
    line-height: 18px;
    text-align: center;
    color: #828282;
    font-weight: normal;
    text-align: initial;
  } */

  p {
    width: 50%;
    color: #828282;
    font-size: 12px;
    height: 9rem;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  @media only screen and ${breakpoints.xl} {
    width: 50%;
  }

  @media only screen and ${breakpoints.lg} {
    width: 40%;
  }

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 0rem;

      p {
        width: 100%;
        color: #828282;
        font-size: 12px;
        height: 0rem;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    `}
`;

export const StyledCircular = styled(CircularProgressbarWithChildren)`
  .CircularProgressbar-path {
    stroke: #04cfbf;
  }

  .CircularProgressbar-text {
    fill: #4f4f4f;
  }

  .CircularProgressbar-trail {
    stroke: #f2f2f2;
  }
`;

export const CircularProgressbarText = styled.div`
  font-size: 12px;
  margin-top: 60px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #4f4f4f;
`;

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
`;

export const StyledThumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  line-height: 25px;
  width: 62px;
  text-align: center;

  background:
    linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #00294b, #00887d) border-box;
  border-radius: 50em;
  border: 5px solid transparent;

  color: #000;
  border-radius: 50%;
  cursor: grab;
`;

export const ContainerThumb = styled.div`
  height: 70px;
  width: 100%;
  overflow: hidden;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  float: left;
  display: inline-flex;
`;

export const ContainerSlider = styled.div`
  display: flex;
  margin-top: -25px;
`;

export const StyledThumbDisabled = styled.div`
  display: flex;
  background: #ffffff;
  border: 3px solid #f3f3f3;
  box-shadow: 0px 16px 40px rgba(255, 255, 255, 0.2);
  z-index: 1;
  width: 60px;
  height: 60px;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-top: 82px;
  cursor: default;

  /* @media only screen and ${breakpoints.xl} {
    margin-left: 56.5%;
  }

  @media only screen and ${breakpoints.lg} {
    margin-left: 52.5%;
  } */
`;

export const StyledThumbDisabledText = styled.div`
  color: #bdbdbd;
  font-size: 17px;
  line-height: 18px;
`;

export const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 2 ? '#F2F2F2' : props.index === 1 ? '#00294B' : '#00887D'};
  border-radius: 28px;

  ${(props) =>
    props.voluntaria &&
    css`
      background: ${(props) =>
        props.index === 2
          ? '#F2F2F2'
          : props.index === 1
          ? '#F2F2F2'
          : '#00294B'};
    `}
`;

export const Footer = styled.div`
  display: flex;
  width: 95%;
  height: 70px;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
      flex-direction: column;
    `}
`;

export const FooterText = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  ${(props) =>
    props.secondary &&
    css`
      margin-top: 0rem;
    `}

  p {
    width: 65%;
    font-size: 13px;
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
      flex-direction: column;

      p {
        width: 90%;
        font-size: 13px;
      }
    `}
`;
