import { breakpoints } from 'hook/useMedia';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 80vh;
  flex-direction: column;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 95%;
  margin-left: 1rem;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 2rem;
      width: 100%;
      margin-left: 0;
      justify-content: center;

      h2 {
        font-size: 16px;
      }
    `}
`;

export const CardContainer = styled.div`
  display: flex;
  width: 75%;
  height: 55%;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-top: 5%;

  align-items: center;
  justify-content: center;

  @media only screen and ${breakpoints.xl} {
    height: 60%;
    margin-top: 3%;
  }

  @media only screen and ${breakpoints.lg} {
    height: 65%;
    margin-top: 0%;
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
`;

export const CardItem = styled.div`
  display: flex;
  width: 100%;
  margin: 16px;
`;

export const Image = styled.img`
  width: 50px;
  height: 50px;
`;

export const ContainerInformacoes = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  h3 {
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    color: #4f4f4f;
  }
`;

export const ContainerInformacoesText = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color: #4f4f4f;

  max-height: 5ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  p {
    text-transform: capitalize !important;
    margin: 0;
  }
`;

export const ContainerInformacoesRegimeText = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color: #4f4f4f;
`;

export const EditarButton = styled.b`
  cursor: pointer;
  color: #1351b4;
`;

export const ContainerDeclaracao = styled.div`
  display: flex;
  margin-top: 2em;
  width: 75%;
  align-items: center;

  @media only screen and ${breakpoints.xl} {
    margin-top: 0.5em;
  }

  @media only screen and ${breakpoints.lg} {
    margin-top: 0.5em;
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const ContainerButton = styled.div`
  display: flex;
  margin-top: 16px;

  @media only screen and ${breakpoints.xl} {
    margin-top: 0.5em;
  }

  @media only screen and ${breakpoints.lg} {
    margin-top: 0.5em;
  }
`;
