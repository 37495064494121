import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useModal } from 'context/Modal';

export default function ModalAguardar() {
  const { showModalAguardar, closeModalAguardar, modalAguardarProgress } =
    useModal();

  return (
    <Dialog
      open={showModalAguardar}
      onClose={closeModalAguardar}
      maxWidth="xs"
      sx={{ textAlign: 'center' }}
    >
      <HourglassBottomIcon
        color="primary"
        sx={{ margin: '0 auto', fontSize: '8rem', padding: '16px 0' }}
      />
      <DialogTitle>Processando</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A solicitação está sendo processada, a página será atualizada
          automaticamente em:
        </DialogContentText>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
            marginTop: '20px',
          }}
        >
          <CircularProgress
            variant="determinate"
            value={100 - modalAguardarProgress}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h5" component="div">
              {modalAguardarProgress}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
