import TextField from '@mui/material/TextField';

import styled from 'styled-components';

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  justify-content: flex-end;
`;

export const ContainerTitleSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }
`;

export const Search = styled(TextField)`
  margin-top: -55px;
  background-color: #f5f5f5;
  height: 40px;
  border-radius: 3px;

  .MuiInputLabel-outlined {
    margin-top: -6px;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input:first-child {
    margin-top: -6px;
  }

  .MuiOutlinedInput-root {
    height: 40px;
  }
`;
