import styled, { css } from 'styled-components';

import loginBackground from 'assets/images/background.png';

export const Background = styled.div`
  display: flex;
  background-image: url(${loginBackground});
  background-size: cover;
  justify-content: space-between;
  background-repeat: no-repeat;
  height: 100% !important;
  min-height: 100vh;

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      background: linear-gradient(180deg, #00887d 0%, #00294b 100%);
      background-size: cover;
      justify-content: space-between;
      background-repeat: no-repeat;
      height: 100vh !important;
      flex-direction: column;
    `}
`;

export const ContainerLeft = styled.div`
  display: flex;
  width: 20%;
  flex-direction: column;
  text-align: center;

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      width: 100%;
      flex-direction: column;
      text-align: center;
    `}
`;

export const LogoImage = styled.img`
  margin-top: 5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 260px;

  ${(props) =>
    props.rodape &&
    css`
      margin-top: 0;
      margin-bottom: 1rem;
    `}

  ${(props) =>
    props.mobile &&
    css`
      max-width: 220px;
      margin-top: 4.5rem;
      margin-bottom: 0rem;
    `}
`;

export const ContainerRight = styled.div`
  background-color: white;
  border-radius: 2em;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  flex: 0 0 auto;
  width: 80%;

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 2em;
      border-top-right-radius: 1.25rem;
      border-top-left-radius: 1.25rem;
      border-bottom-right-radius: 0rem;
      border-bottom-left-radius: 0rem;
      width: 100%;
      height: 75vh;
    `}
`;
