import { createContext, useContext, useState } from 'react';

import { useSteps } from './Steps';

const FormularioContext = createContext();

export function FormularioProvider({ children }) {
  const { handleNext } = useSteps();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeVinculadosPage = () => {
    handleNext();
  };

  return (
    <FormularioContext.Provider
      value={{
        handleChangeVinculadosPage,
        anchorEl,
        handlePopoverOpen,
        handlePopoverClose,
      }}
    >
      {children}
    </FormularioContext.Provider>
  );
}

export function useFormulario() {
  const context = useContext(FormularioContext);
  if (!context) {
    throw new Error('useFormulario must be used within a FormularioProvider');
  }
  return context;
}
