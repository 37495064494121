/* eslint-disable no-restricted-syntax */
import { useState, createContext, useContext } from 'react';

import { useVinculadoFormulario } from 'hook/useVinculadoFormulario';
import { isCpf } from 'iscpf';
import moment from 'moment';
import {
  sendFile,
  // atualizarFileDependente,
} from 'services/files';
import {
  buscarCadastroParaResetarSenha,
  // getTravarUser,
} from 'services/loginServices';
import {
  deleteVinculado,
  getVinculadoById,
  getVinculadosById,
  postVinculado,
  updateVinculado,
} from 'services/vinculadoServices';
// import formataCpf from 'util/formatarCPF';
import formatarNome from 'util/formatarNome';
import isValidDate from 'util/isValidDate';
import { verificaSeTemSobreNome } from 'util/verificaSeTemSobreNome';

import { useAuth } from './Auth';
import { useInfoPessoais } from './InfoPessoais';
import { useToast } from './Toast';

const ModalContext = createContext();

export function ModalProvider({ children }) {
  const { toast } = useToast();
  const {
    nome,
    setNome,
    idNumSeq,
    setIdNumSeq,
    dataNascimento,
    setDataNascimento,
    idade,
    setIdade,
    grauParentesco,
    setGrauParentesco,
    cpf,
    setCpf,
    sexo,
    setSexo,
    invalidez,
    setInvalidez,
    dataInvalidez,
    setDataInvalidez,
    errorNome,
    setErrorNome,
    errorDataNascimento,
    setErrorDataNascimento,
    errorGrauParentesco,
    setErrorGrauParentesco,
    errorCpf,
    setErrorCpf,
    errorSexo,
    setErrorSexo,
    errorInvalidez,
    setErrorInvalidez,
    errorDataInvalidez,
    setErrorDataInvalidez,
    errorPorcentagemVinculado,
    setErrorPorcentagemVinculado,
  } = useVinculadoFormulario();

  const { travaCadastro, numPedido } = useAuth();

  const {
    setFilesVinculado,
    handleLoadFilesVinculados,
    filesVinculado,
    toBase64,
  } = useInfoPessoais();

  // Modal Senha
  const [showModal, setShowModal] = useState(false);
  // Modal Sucesso
  const [showModalSucesso, setShowModalSucesso] = useState(false);
  // Modal Email
  const [showModalEmail, setShowModalEmail] = useState(false);
  // Modal Redefinir Senha
  const [showModalRedefinirSenha, setShowModalRedefinirSenha] = useState(false);
  // Modal Documentos
  const [showModalDocumentos, setShowModalDocumentos] = useState(false);
  // Modal Vinculados
  const [showModalVinculado, setShowModalVinculado] = useState(false);
  // Modal Informa��es Documentos
  const [showModalInfoDocumento, setShowModalInfoDocumento] = useState(false);
  // Modal Contribui��o
  const [showModalContribuicao, setShowModalContribuicao] = useState(false);
  // Modal PPE
  const [showModalPPE, setShowModalPPE] = useState(false);
  // Modal Aviso Email
  const [showModalAvisoEmail, setShowModalAvisoEmail] = useState(false);
  // Modal Aviso Final
  const [showModalAvisoFinal, setShowModalAvisoFinal] = useState(false);
  // Modal Confirmacao Tributacao
  const [showModalConfirmacaoTributacao, setShowModalConfirmacaoTributacao] =
    useState(false);
  // Modal Aguardar
  const [showModalAguardar, setShowModalAguardar] = useState(false);
  const [modalAguardarProgress, setModalAguardarProgress] = useState(60);

  // Declaração Filho
  const [checkedDeclaracaoFilho, setCheckedDeclaracaoFilho] = useState(false);
  const [consentimentoMenor, setConsentimentoMenor] = useState('');

  const [openEtapaAvisoSobrinho, setOpenEtapaAvisoSobrinho] = useState(false);
  const [openEtapaInvalidezSobrinho, setOpenEtapaInvalidezSobrinho] =
    useState(false);
  const [openEtapaDocumentos, setOpenEtapaDocumentos] = useState(false);

  const [openProximaEtapaDireto, setOpenProximaEtapaDireto] = useState(false);

  const [pessoasVinculadas, setPessoasVinculadas] = useState([]);

  const [editando, setEditando] = useState(false);
  const [carregandoVinculado, setCarregandoVinculado] = useState(false);

  const [pessoas, setPessoas] = useState([]);
  const [todosVinculados, setTodosVinculados] = useState([]);
  const [excluido, setExcluido] = useState(false);

  const [temGrauParentesco, setTemGrauParentesco] = useState(false);
  const [temDataNascimento, setTemDataNascimento] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');

  // const [idFile, setIdFile] = useState(null);

  let erros = false;

  const { dtNascimento } = JSON.parse(sessionStorage.getItem('@user:dados'))
    ? JSON.parse(sessionStorage.getItem('@user:dados'))
    : {};

  const dataNascimentoProspect = moment(dtNascimento);

  handleLoadFilesVinculados;

  const handleChangeConsentimento = (event) => {
    setConsentimentoMenor(event.target.value);
  };

  const apagaDados = () => {
    setNome('');
    // BUG_FIX: datas de invalidez e nascimento iniciadas com a data de hoje
    // Author: Lemuel
    setDataNascimento(null);
    setIdade('');

    setGrauParentesco('');
    setCpf('');
    setSexo('');
    setInvalidez(false);
    // BUG_FIX: datas de invalidez e nascimento iniciadas com a data de hoje
    // Author: Lemuel
    setDataInvalidez(null);
    setOpenProximaEtapaDireto(false);
    setOpenEtapaAvisoSobrinho(false);
    setOpenEtapaInvalidezSobrinho(false);
    setCheckedDeclaracaoFilho(false);
  };

  const apagarErros = () => {
    setErrorNome(false);
    setErrorDataNascimento(false);
    setErrorGrauParentesco(false);
    setErrorCpf(false);
    setErrorSexo(false);
    setErrorInvalidez(false);
    setErrorDataInvalidez(false);
    setErrorPorcentagemVinculado(false);
  };

  const verificaErros = () => {
    // BUG_FIX: retornar erro se usu�rio n�o inserir um sobre-nome
    // Author: Lemuel
    if (nome === '' || !verificaSeTemSobreNome(nome)) {
      setErrorNome(true);
      erros = true;
    } else {
      setErrorNome(false);
    }
    if (
      !dataNascimento ||
      dataNascimento > new Date() ||
      dataNascimento === ''
    ) {
      setErrorDataNascimento(true);
      erros = true;
    } else {
      setErrorDataNascimento(false);
    }

    if (
      dataNascimentoProspect.isSameOrAfter(dataNascimento) &&
      grauParentesco === '02'
    ) {
      toast.error(
        'Filho(a) n�o pode ter a data de nascimento anterior ou igual a sua',
        {
          id: 'nascimento',
        }
      );
      erros = true;
    } else if (
      dataNascimentoProspect.isSameOrBefore(dataNascimento) &&
      grauParentesco === '03'
    ) {
      toast.error(
        'Filiação não pode ter a data de nascimento posterior ou igual a sua',
        {
          id: 'nascimento',
        }
      );
      erros = true;
    } else if (
      idade < 16 &&
      (grauParentesco == '06' || grauParentesco == '07')
    ) {
      toast.error(
        'Companheiro(a) ou ex-companheiro(a) n�o pode ser menor que 16 anos',
        {
          id: 'nascimento',
        }
      );
      erros = true;
    } else if (
      idade >= 18 &&
      (grauParentesco == '18' || grauParentesco == '16')
    ) {
      toast.error(
        'Tutelado ou Menor sob guarda n�o podem ser maior de idade.',
        {
          id: 'nascimento',
        }
      );
      erros = true;
    } else if (
      dataNascimentoProspect.isSameOrBefore(dataNascimento) &&
      grauParentesco === '13'
    ) {
      toast.error(
        'Avô(ó) / Bisavô(ó) não pode ter data de nascimento posterior ou igual a sua',
        { id: 'nascimento' }
      );
      erros = true;
    } else if (
      dataNascimentoProspect.isSameOrAfter(dataNascimento) &&
      grauParentesco === '12'
    ) {
      toast.error(
        'Neto(a) / Bisneto(a) não pode ter data de nascimento anterior ou igual a sua',
        {
          id: 'nascimento',
        }
      );
      erros = true;
    } else if (grauParentesco === '') {
      setErrorGrauParentesco(true);
      erros = true;
    } else {
      setErrorGrauParentesco(false);
    }

    if (idade >= 8 || cpf) {
      if (!cpf || cpf === '') {
        setErrorCpf('Campo obrigat�rio');
        erros = true;
      } else if (!isCpf(cpf)) {
        toast.error('Por favor, insira um CPF v�lido', { id: 'cpf-invalido' });
        setErrorCpf('CPF inv�lido');
        erros = true;
      } /* else if (todosVinculados && todosVinculados.length > 0) {
        todosVinculados.every((vinculado) => {
          if (formataCpf(vinculado.cpf) === formataCpf(cpf)) {
            setErrorCpf('CPF n�o pode ser igual a de outro vinculado');
            erros = true;
            return false;
          }
          return true;
        });
      } */ else {
        setErrorCpf(false);
      }
    }
    if (sexo === '') {
      setErrorSexo(true);
      erros = true;
    } else {
      setErrorSexo(false);
    }
    if (invalidez === true) {
      if (
        dataInvalidez === '' ||
        dataInvalidez === null ||
        dataInvalidez < dataNascimento
      ) {
        toast.error(
          'Data de Invalidez n�o pode ser menor do que a data nascimento. ',
          { id: 'datainavalidez-invalido' }
        );
        setErrorDataInvalidez(true);
        erros = true;
      } else if (dataInvalidez > new Date()) {
        toast.error(
          'Data de Invalidez n�o pode ser maior do que a data  de hoje. ',
          { id: 'datainavalidez-invalido' }
        );
        setErrorDataInvalidez(true);
        erros = true;
      } else {
        setErrorDataInvalidez(false);
      }
    }
    if (
      idade < 16 &&
      (grauParentesco === '01' ||
        grauParentesco === '06' ||
        grauParentesco === '07')
    ) {
      setErrorDataNascimento(true);
      toast.error(
        '(Ex-)C�njuge/(Ex-)Companheiro(a) n�o pode ser menor de 16 anos',
        { id: 'nascimento' }
      );
      erros = true;
    }
    return erros;
  };

  const apagaDadosDocumento = () => {
    setFilesVinculado([]);
  };

  // ++BUG_FIX: verificar se o usu�rio pai consentiu o filho menor de idade
  // Author: Lemuel
  const verificaCheckedFilho = () => {
    if (consentimentoMenor === '') {
      return toast.error('Por favor, escolha uma op��o', {
        id: 'declara��o-filho',
      });
    }
    if (consentimentoMenor === 'naoConsentir' || consentimentoMenor === '') {
      return toast.error(
        'O n�o consentimento implica na n�o inclus�o do menor.',
        { id: 'declara��o-filho' }
      );
    }

    verificaErros();
    proximaEtapaInvalidezSobrinho();
  };

  const verificaDataInvalidez = () => {
    if (invalidez === true) {
      if (
        dataInvalidez === '' ||
        dataInvalidez === null ||
        dataInvalidez === 'Invalid date'
      ) {
        toast.error('Data de Invalidez n�o pode ser vazia', {
          id: 'datainavalidez-invalido',
        });
      }
    }
  };

  const enviarVinculado = async () => {
    const { numPedido } = JSON.parse(sessionStorage.getItem('@user:dados'));

    verificaErros();

    verificaDataInvalidez();

    if (erros) {
      toast.error('Por favor, preencha todos os campos corretamente', {
        id: 'erro-vinculado',
      });
      return;
    }

    if (!editando) {
      const body = {
        numPedido,
        nome,
        dtNascimento: moment(dataNascimento).format('YYYY-MM-DD'),
        sexo,
        codGrauParentesco: grauParentesco,
        cpf: idade < 8 && !cpf ? '000.000.000-00' : cpf,
        fgInvalido: invalidez ? 'S' : 'N',
      };

      if (invalidez) {
        body.dtInvalidez = moment(dataInvalidez).format('YYYY-MM-DD');
      }

      await postVinculado(body);

      if (pessoasVinculadas) {
        setIdNumSeq(pessoasVinculadas.length + 1);
      } else {
        setIdNumSeq(1);
      }

      // await atualizarFileDependente(id_depend, idFile); //Atualizar arquivlos

      setPessoasVinculadas([...pessoasVinculadas, body]);
    } else {
      const body = {
        numPedido,
        idNumSeq,
        nome,
        dtNascimento: moment(dataNascimento).format('YYYY-MM-DD'),
        sexo,
        codGrauParentesco: grauParentesco,
        cpf: idade < 8 && !cpf ? '000.000.000-00' : cpf,
        fgInvalido: invalidez ? 'S' : 'N',
      };

      if (invalidez) {
        body.dtInvalidez = moment(dataInvalidez).format('YYYY-MM-DD');
      }

      await updateVinculado(body);

      setShowModalVinculado(false);
      handleCloseModalVinculado();
    }
  };

  const handleLoadVinculado = async (numPedido, idNumSeq) => {
    setIsLoading(true);
    setEditando(true);
    setCarregandoVinculado(true);

    const {
      nome,
      dtNascimento,
      codGrauParentesco,
      cpf,
      sexo,
      fgInvalido,
      dtInvalidez,
    } = await getVinculadoById(numPedido, idNumSeq);

    setNome(nome);
    setDataNascimento(new Date(dtNascimento).addDays(1));
    setTemDataNascimento(!!dtNascimento);
    setGrauParentesco(codGrauParentesco);
    setTemGrauParentesco(!!codGrauParentesco);
    setIdNumSeq(idNumSeq);
    setCpf(cpf === '000.000.000-00' ? '' : cpf);
    setSexo(sexo);
    if (fgInvalido === 'S') {
      setInvalidez(true);
    } else {
      setInvalidez(false);
    }

    if (dtInvalidez == null) {
      setDataInvalidez('');
    } else {
      setDataInvalidez(new Date(dtInvalidez).addDays(1));
    }
    // setIdDependente(getDados[0].id_dependente);

    setIdade(moment().diff(dtNascimento, 'years'));
    proximaEtapaPassaDireto();
    setShowModalVinculado(true);

    setCarregandoVinculado(false);
    setIsLoading(false);
  };

  const handleExcluirVinculado = async (numPedido, idNumSeq) => {
    setExcluido(true);
    await deleteVinculado(numPedido, idNumSeq);
    setPessoasVinculadas(
      pessoasVinculadas.filter((pessoa) => pessoa.idNumSeq !== idNumSeq)
    );
    setExcluido(false);
  };

  const editarVinculado = (pessoa) => {
    setNome(pessoa.nome);
    setDataNascimento(pessoa.dataNascimento);
    setIdade(pessoa.idade);
    setGrauParentesco(pessoa.codGrauParentesco);
    setCpf(pessoa.cpf === '000.000.000-00' ? '' : pessoa.cpf);
    setSexo(pessoa.sexo);
    setInvalidez(pessoa.invalidez);
    setDataInvalidez(pessoa.dataInvalidez);
    setShowModalVinculado(true);
  };

  const handleChangeNome = (e) => {
    setErrorNome(false);
    const nome = formatarNome(e.target.value);

    setNome(nome);
  };

  const handleChangeCpf = (e) => {
    setErrorCpf(false);
    setCpf(e.target.value);
  };

  const handleChangeInvalidez = (nextChecked) => {
    setInvalidez(nextChecked);
  };

  const handleChangeDataNascimento = (newValue) => {
    setErrorDataNascimento(false);
    setDataNascimento(newValue);
    setIdade(moment().diff(newValue, 'years'));
  };

  const handleChangeDataInvalidez = (date) => {
    const newValue = isValidDate(date) ? date : '';
    setDataInvalidez(newValue);
  };

  const handleChangeParentesco = (event) => {
    setErrorGrauParentesco(false);
    setGrauParentesco(event.target.value);
  };

  const proximaEtapaAvisoSobrinho = () => {
    verificaErros();
    setOpenEtapaDocumentos(true);

    // if (erros) {
    //   return;
    // }

    // if (idade < 12 && grauParentesco) {
    //   setOpenEtapaAvisoSobrinho(true);
    // }
  };

  const verificaSobrinho = () => {
    verificaErros();

    if (!erros) {
      proximaEtapaAvisoSobrinho();
    }
  };

  const verificaGrauParentesco = async () => {
    if (verificaErros()) {
      return;
    }

    await enviarVinculado();

    setEditando(true);

    if (
      (!erros && grauParentesco === '15' && idade < 12) ||
      (!erros && grauParentesco === '10' && idade < 12) ||
      (!erros && grauParentesco === '04' && idade < 12)
    ) {
      // Sobrinho
      proximaEtapaAvisoSobrinho();
    } else if (!erros && grauParentesco === '02' && idade < 12) {
      // Filho
      // setOpenEtapaAvisoSobrinho(true);
    } else {
      // Outros parentescos
      openModalDocumentos();
    }
  };

  const handleLoadVinculados = async () => {
    const getDados = await getVinculadosById(numPedido);

    // const { travarCadastro } = await getTravarUser(numPedido, numIdPf);

    // setTravaCadastro(travarCadastro);

    setPessoas(getDados);
    setTodosVinculados(getDados);
    setPessoasVinculadas(getDados);
  };

  const proximaEtapaInvalidezSobrinho = () => {
    setShowModalDocumentos(true);
    setOpenEtapaInvalidezSobrinho(true);
    setOpenEtapaAvisoSobrinho(false);
  };

  const proximaEtapaDocumentos = () => {
    setOpenEtapaDocumentos(true);
  };

  const handleChangeSexo = (event) => {
    setErrorSexo(false);
    setSexo(event.target.value);
  };

  const proximaEtapaPassaDireto = () => {
    handleCloseModalDocumentos();
    setOpenProximaEtapaDireto(true);
  };

  const pularEtapaDocumentos = () => {
    handleCloseModalDocumentos();
    proximaEtapaPassaDireto();
  };

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const openModalSucesso = () => {
    setShowModalSucesso((prev) => !prev);
  };

  const openModalEmail = () => {
    handleCloseModalSucesso();
    setShowModalEmail((prev) => !prev);
  };

  const openModalRedefinirSenha = () => {
    setShowModalRedefinirSenha(true);
  };

  const handleCloseModalRedefinirSenha = () => {
    setShowModalRedefinirSenha(false);
  };

  const openModalPPE = () => {
    setShowModalPPE((prev) => !prev);
  };

  const openModalAvisoEmail = () => {
    setShowModalAvisoEmail(true);
  };

  const handleCloseModalAvisoEmail = () => {
    setShowModalAvisoEmail(false);
  };

  const handleCloseModalSucesso = () => {
    setShowModalSucesso(false);
  };

  const handleCloseModalEmail = () => {
    setShowModalEmail(false);
    setEmail('');
  };

  const openModalVinculado = () => {
    setEditando(false);
    setShowModalVinculado((prev) => !prev);
  };

  const openModalDocumentos = () => {
    verificaErros();

    if (erros) {
      return;
    }

    setShowModalDocumentos((prev) => !prev);
  };

  const openModalInfoDocumento = () => {
    setShowModalInfoDocumento((prev) => !prev);
  };

  const openModalContribuicao = () => {
    setShowModalContribuicao((prev) => !prev);
  };

  const handleCloseModalContribuicao = () => {
    setShowModalContribuicao(false);
  };

  const handleCloseModalPPE = () => {
    setShowModalPPE(false);
  };

  const handleCloseModalAvisoFinal = () => {
    setShowModalAvisoFinal(false);
  };

  const openModalAvisoFinal = () => {
    setShowModalAvisoFinal((prev) => !prev);
  };

  const enviarRecuperacaoSenha = async (values) =>
    buscarCadastroParaResetarSenha({
      cpf: values.cpf,
      dataNascimento: values.dataNascimento.toISOString().split('T')[0],
    });

  const openModalConfirmacaoTributacao = () => {
    setShowModalConfirmacaoTributacao(true);
  };

  const closeModalConfirmacaoTributacao = () => {
    setShowModalConfirmacaoTributacao(false);
  };

  const openModalAguardar = () => {
    setShowModalAguardar(true);
  };

  const closeModalAguardar = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setShowModalAguardar(false);
  };

  const handleCloseModalVinculado = () => {
    setShowModalVinculado(false);
    apagaDados();
    apagarErros();
    setTemGrauParentesco(false);
    setTemDataNascimento(false);
  };

  const handleCloseModalDocumentos = () => {
    setShowModalDocumentos(false);
    apagaDadosDocumento();
  };

  const handleCloseModalInfoDocumento = () => {
    setShowModalInfoDocumento(false);
  };

  const handleCheckedDeclaracaoFilho = () => {
    setCheckedDeclaracaoFilho(!checkedDeclaracaoFilho);
  };

  const handleCloseModalDocumentosExcluir = () => {
    setShowModalDocumentos(false);
    setShowModalVinculado(false);
    apagaDados();
    apagaDadosDocumento();
  };

  const handleChangeEmail = (email) => {
    setEmail(email);
  };

  const isDisabledAll = () => {
    if (travaCadastro == 2) {
      return true;
    }
    return false;
  };

  const isDisabledGrauParentesco = () => {
    if (temGrauParentesco || isLoading) {
      return true;
    }
    return false;
  };

  const isDisabledDataNascimento = () => {
    if (temDataNascimento || isLoading) {
      return true;
    }
    return false;
  };

  const submitFilesVinculados = async (callback) => {
    let aux = filesVinculado;

    try {
      // await deleteFileFromIdDependente(0, idNumSeq);
      let index = 0;
      for await (const file of filesVinculado) {
        toast.loading('Enviando...', {
          id: 'upload',
        });

        await sendFile({
          file: await toBase64(file),
          codPessoa: idNumSeq,
          numPedido,
          tag: 52,
          numSeq: ++index,
          tipoPessoa: 'VINCULADO',
          // eslint-disable-next-line no-loop-func
          onError: () => {
            toast.error(`Erro ao enviar arquivo: ${file.name}`, {
              id: file.name,
            });
            aux = aux.filter((f) => f.name !== file.name);
          },
          onSuccess: () => {
            toast.success(`Arquivo enviado: ${file.name}`, { id: file.name });
          },
        });
      }
      callback();
    } catch (error) {
      console.log(error);
      toast.error('Ops! Erro ao enviar as imagens!', { id: 'files-upload' });
    } finally {
      setFilesVinculado(aux);
      toast.dismiss('upload');
    }
  };

  return (
    <ModalContext.Provider
      value={{
        showModal,
        setShowModal,
        openModal,
        handleCloseModal,
        showModalSucesso,
        setShowModalSucesso,
        openModalSucesso,
        handleCloseModalSucesso,
        enviarRecuperacaoSenha,
        showModalVinculado,
        setShowModalVinculado,
        openModalVinculado,
        handleCloseModalVinculado,
        dataNascimento,
        dataInvalidez,
        openProximaEtapaDireto,
        sexo,
        grauParentesco,
        invalidez,
        handleChangeInvalidez,
        handleChangeDataNascimento,
        handleChangeDataInvalidez,
        handleChangeParentesco,
        handleChangeSexo,
        proximaEtapaPassaDireto,
        handleChangeNome,
        nome,
        handleChangeCpf,
        cpf,
        handleExcluirVinculado,
        idade,
        proximaEtapaAvisoSobrinho,
        openEtapaAvisoSobrinho,
        proximaEtapaInvalidezSobrinho,
        openEtapaInvalidezSobrinho,
        proximaEtapaDocumentos,
        openEtapaDocumentos,
        openModalDocumentos,
        handleCloseModalDocumentos,
        showModalDocumentos,
        setShowModalDocumentos,
        pularEtapaDocumentos,
        handleCloseModalDocumentosExcluir,
        openModalInfoDocumento,
        showModalInfoDocumento,
        setShowModalInfoDocumento,
        handleCloseModalInfoDocumento,
        errorNome,
        errorDataNascimento,
        errorGrauParentesco,
        errorCpf,
        errorSexo,
        errorInvalidez,
        setErrorInvalidez,
        errorDataInvalidez,
        errorPorcentagemVinculado,
        verificaErros,
        openModalContribuicao,
        showModalContribuicao,
        setShowModalContribuicao,
        handleCloseModalContribuicao,
        verificaSobrinho,
        pessoasVinculadas,
        setPessoasVinculadas,
        dataNascimentoPai: dataNascimentoProspect,
        checkedDeclaracaoFilho,
        handleCheckedDeclaracaoFilho,
        verificaCheckedFilho,
        enviarVinculado,
        handleLoadVinculado,
        consentimentoMenor,
        handleChangeConsentimento,
        editarVinculado,
        handleCloseModalPPE,
        showModalPPE,
        setShowModalPPE,
        openModalPPE,
        verificaGrauParentesco,
        handleLoadVinculados,
        excluido,
        pessoas,
        todosVinculados,
        openModalEmail,
        handleCloseModalEmail,
        showModalEmail,
        setShowModalEmail,
        handleChangeEmail,
        email,
        disabledAll: isDisabledAll(),
        carregandoVinculado,
        submitFilesVinculados,
        disabledGrauParentesco: isDisabledGrauParentesco(),
        disabledDataNascimento: isDisabledDataNascimento(),
        openModalRedefinirSenha,
        handleCloseModalRedefinirSenha,
        setShowModalRedefinirSenha,
        showModalRedefinirSenha,
        openModalAvisoEmail,
        handleCloseModalAvisoEmail,
        setShowModalAvisoEmail,
        showModalAvisoEmail,
        showModalAvisoFinal,
        setShowModalAvisoFinal,
        handleCloseModalAvisoFinal,
        openModalAvisoFinal,
        showModalConfirmacaoTributacao,
        openModalConfirmacaoTributacao,
        closeModalConfirmacaoTributacao,
        showModalAguardar,
        openModalAguardar,
        closeModalAguardar,
        modalAguardarProgress,
        setModalAguardarProgress,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}
