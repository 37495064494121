import { apiReset } from './api';
import { getAccessToken, getBearerToken, getGrantCode } from './gtwfedServices';

apiReset.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (
      (error.response?.status === 404 &&
        error.response?.data.result === 'Invalid Token') ||
      (error.response?.status === 401 &&
        error.response?.headers['content-type'] === 'text/plain')
    ) {
      try {
        const grantCodeResponse = await getGrantCode();
        const accessTokenResponse = await getAccessToken(grantCodeResponse);
        const bearerTokenResponse = await getBearerToken();
        apiReset.defaults.headers.access_token =
          accessTokenResponse.data.access_token;
        apiReset.defaults.headers.Authorization = `Bearer ${bearerTokenResponse.data.access_token}`;
        return apiReset(error.config);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
