import { MdOutlineCancel, MdCheckCircle } from 'react-icons/md';

import IconButton from '@mui/material/IconButton';

import { useInfoPessoais } from 'context/InfoPessoais';

import docPreview from 'assets/images/doc.svg';

import { ListPreview, ListPreviewItem, ListItem } from './styles';

export function FilesPreview() {
  const { files, removeFile, handleTag } = useInfoPessoais();
  return (
    <>
      {files && files.length > 0 && (
        <ListPreview>
          {files.map(
            (file, i) =>
              file && (
                <ListPreviewItem key={i}>
                  <ListItem>
                    <img src={docPreview} alt={file.name} />
                    <div>
                      <p>{handleTag(file.tag)}</p>
                    </div>
                    <p>{file.name}</p>
                  </ListItem>

                  <div>
                    <IconButton disabled>
                      <MdCheckCircle size={20} color="#4CAF50" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        removeFile(file.numSeq, file.tag);
                      }}
                    >
                      <MdOutlineCancel size={20} color="#C4C4C4" />
                    </IconButton>
                  </div>
                </ListPreviewItem>
              )
          )}
        </ListPreview>
      )}
    </>
  );
}

export function FilesPreviewVinculado() {
  const { filesVinculado, removeFileVinculado } = useInfoPessoais();
  return (
    <>
      {filesVinculado && filesVinculado.length > 0 && (
        <ListPreview>
          {filesVinculado.map((file, i) => (
            <ListPreviewItem key={i}>
              <ListItem>
                <img src={docPreview} alt={file.name} />
                <p>{file.name}</p>
              </ListItem>

              <div>
                <IconButton disabled>
                  <MdCheckCircle size={20} color="#4CAF50" />
                </IconButton>
                <IconButton
                  onClick={() => removeFileVinculado(file.numSeq, file.tag)}
                >
                  <MdOutlineCancel size={20} color="#C4C4C4" />
                </IconButton>
              </div>
            </ListPreviewItem>
          ))}
        </ListPreview>
      )}
    </>
  );
}
