/* eslint-disable react/jsx-no-duplicate-props */

import { AiOutlineForm } from 'react-icons/ai';
import Switch from 'react-switch';

import { Dialog } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import ptBR from 'date-fns/locale/pt-BR';
import { useResponsive } from 'hook/useResponsive';
import { isCpf } from 'iscpf';
import formataCpf from 'util/formatarCPF';
import formatarNome from 'util/formatarNome';
import { verificaSeTemSobreNome } from 'util/verificaSeTemSobreNome';

import { Button } from 'components/Button';
import { ModalDocumentos } from 'components/ModalDocumentos';

import { useModal } from 'context/Modal';

import checkIcon from 'assets/images/check.png';
import personImage from 'assets/images/person.png';
import warningImage from 'assets/images/warning.png';

import { parentesco, sexos } from './item';
import {
  ContainerAvisoMenor,
  ContainerWarning,
  Footer,
  Image,
  ImageProfile,
  LogoImage,
  ModalWrapper,
  StyledLink,
  StyledTextFieldMobile,
  SwitchContainer,
  TextContainer,
  TextFieldContainer,
  TitleAvisoMenor,
} from './styles';

export function ModalVinculadoMobile() {
  const { mobileLayout } = useResponsive();

  const {
    showModalVinculado,
    handleCloseModalVinculado,
    dataNascimento,
    handleChangeDataNascimento,
    grauParentesco,
    handleChangeParentesco,
    sexo,
    handleChangeSexo,
    handleChangeInvalidez,
    invalidez,
    dataInvalidez,
    handleChangeDataInvalidez,
    handleChangeNome,
    nome,
    handleChangeCpf,
    cpf,
    openEtapaAvisoSobrinho,
    proximaEtapaInvalidezSobrinho,
    openEtapaInvalidezSobrinho,
    openProximaEtapaDireto,
    showModalDocumentos,
    setShowModalDocumentos,
    errorNome,
    errorDataNascimento,
    errorGrauParentesco,
    errorCpf,
    errorSexo,
    verificaErros,
    dataNascimentoPai,
    consentimentoMenor,
    handleChangeConsentimento,
    verificaCheckedFilho,
    verificaGrauParentesco,
    errorInvalidez,
    enviarVinculado,
    disabledGrauParentesco,
    idade,
  } = useModal();

  return (
    <>
      {showModalVinculado && (
        <Dialog
          onClose={handleCloseModalVinculado}
          aria-labelledby="simple-dialog-title"
          open={showModalVinculado}
          scroll="body"
          fullWidth
        >
          {mobileLayout && (
            <ModalWrapper
              mobile
              style={
                openEtapaAvisoSobrinho == true
                  ? { height: '50em' }
                  : { height: '45em' }
              }
            >
              <LogoImage src={personImage} alt="Perfil" />
              <TextContainer>
                <TextFieldContainer mobile>
                  <StyledTextFieldMobile
                    id="outlined-basic"
                    label="Nome do vinculado"
                    variant="outlined"
                    type="nome"
                    onChange={handleChangeNome}
                    value={formatarNome(nome) || ''}
                    required
                    error={errorNome}
                    helperText={errorNome ? 'Campo obrigatório' : ''}
                    InputProps={{
                      endAdornment: (
                        <>
                          {nome && verificaSeTemSobreNome(nome) && (
                            <InputAdornment
                              style={{ marginTop: '5px' }}
                              position="end"
                            >
                              <ImageProfile src={checkIcon} />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  />
                  <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}
                  >
                    <DesktopDatePicker
                      maxDate={new Date()}
                      minDate={
                        grauParentesco == '02' ? dataNascimentoPai : null
                      }
                      label="Data de nascimento"
                      value={dataNascimento}
                      onChange={handleChangeDataNascimento}
                      sx={{ marginTop: '32px' }}
                      slotProps={{
                        textField: {
                          error: { errorDataNascimento },
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <StyledTextFieldMobile
                    style={{ marginTop: '32px' }}
                    id="outlined-select-currency"
                    select
                    label="Sexo"
                    value={sexo}
                    onChange={handleChangeSexo}
                    error={errorSexo}
                    helperText={errorSexo ? 'Campo obrigatório' : ''}
                    InputProps={{
                      endAdornment: (
                        <>
                          {sexo && (
                            <InputAdornment
                              style={{
                                marginTop: '5px',
                                marginRight: '25px',
                              }}
                              position="end"
                            >
                              <ImageProfile src={checkIcon} />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  >
                    {sexos.map((option) => (
                      <MenuItem key={option.key} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </StyledTextFieldMobile>
                  <StyledTextFieldMobile
                    style={{ marginTop: '32px' }}
                    id="outlined-select-currency"
                    select
                    disabled={disabledGrauParentesco || openProximaEtapaDireto}
                    label="Grau de parentesco"
                    value={grauParentesco}
                    onChange={handleChangeParentesco}
                    error={errorGrauParentesco}
                    helperText={errorGrauParentesco ? 'Campo obrigatório' : ''}
                  >
                    {parentesco.map((option) => (
                      <MenuItem key={option.key} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </StyledTextFieldMobile>
                  <StyledTextFieldMobile
                    style={{ marginTop: '32px' }}
                    id="outlined-basic"
                    label="CPF"
                    variant="outlined"
                    type="nome"
                    required
                    onChange={handleChangeCpf}
                    value={formataCpf(cpf)}
                    inputProps={{
                      maxLength: 14,
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {isCpf(cpf) && (
                            <InputAdornment
                              style={{ marginTop: '5px' }}
                              position="end"
                            >
                              <ImageProfile src={checkIcon} />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                    error={errorCpf}
                    helperText={errorCpf ? 'Campo obrigatório' : ''}
                  />
                </TextFieldContainer>
              </TextContainer>

              {openEtapaAvisoSobrinho == true && (
                <>
                  {grauParentesco === 'Sobrinho(a)' ||
                  grauParentesco === 'Outros / Sem parentesco' ||
                  grauParentesco === 'Irmão / Irmã' ? (
                    <>
                      <ContainerWarning>
                        <Image src={warningImage} alt="Atenção" />
                        <h4>Atenção</h4>
                        <span>
                          A inclusão do <b>menor de 12 anos</b> deve ter a
                          anuência por um dos seus pais ou responsáveis legais.
                          Pedimos, por gentileza, que os mesmos preencham o
                          formulário de cadastro clicando no botão abaixo.
                        </span>
                        <StyledLink
                          to="/files/TERMO_DE_CONSENTIMENTO_PARA_DADOS_DE_CRIANÇAS.pdf"
                          target="_blank"
                          download
                        >
                          <Button
                            style={{ marginTop: '10px' }}
                            iconeOutlined
                            endIcon={
                              <AiOutlineForm
                                style={{ fontSize: '24px', color: '#00887D' }}
                              />
                            }
                          >
                            Baixar formulário
                          </Button>
                        </StyledLink>
                      </ContainerWarning>

                      <Footer>
                        <Button
                          outlinedVinculados
                          onClick={() => {
                            handleCloseModalVinculado();
                          }}
                        >
                          Excluir
                        </Button>
                        <Button
                          normalVinculados
                          onClick={() => {
                            verificaErros();
                            proximaEtapaInvalidezSobrinho(); // Abre etapa com aviso menor de idade
                          }}
                        >
                          Prosseguir
                        </Button>
                      </Footer>
                    </>
                  ) : (
                    <>
                      <ContainerAvisoMenor>
                        <FormControl component="fieldset">
                          <TitleAvisoMenor>
                            O(a) Participante, na qualidade de
                            filiação/representante legal do(s) Vinculado(s)/
                            Beneficiário(s), declara:
                          </TitleAvisoMenor>
                          <RadioGroup
                            aria-label="gender"
                            name="controlled-radio-buttons-group"
                            value={consentimentoMenor}
                            onChange={handleChangeConsentimento}
                          >
                            <FormControlLabel
                              value="consentir"
                              control={<Radio />}
                              label="CONSENTIR que a PETROS realize operações de tratamento dos dados pessoais abaixo relativos a crianças.
                                "
                            />
                            <FormControlLabel
                              value="naoConsentir"
                              control={<Radio />}
                              label="NÃO CONSENTIR que a PETROS realize operações de tratamento dos dados pessoais abaixo relativos a crianças, optando por não incluí-las como Vinculado(s)/Beneficiário(s).
                                "
                            />
                          </RadioGroup>
                        </FormControl>
                      </ContainerAvisoMenor>

                      <Footer>
                        <Button
                          outlinedVinculados
                          onClick={() => {
                            handleCloseModalVinculado();
                          }}
                        >
                          Excluir
                        </Button>
                        <Button
                          normalVinculados
                          onClick={() => {
                            verificaCheckedFilho();
                          }}
                        >
                          Prosseguir
                        </Button>
                      </Footer>
                    </>
                  )}
                </>
              )}

              {openProximaEtapaDireto == true && (
                <>
                  <SwitchContainer>
                    Vinculado tem invalidez?
                    <Switch
                      onChange={handleChangeInvalidez}
                      checked={invalidez}
                      className="react-switch"
                      offColor="#CC390A"
                      onColor="#4CAF50"
                      width={77}
                      height={32}
                      uncheckedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 15,
                            color: '#FFF',
                            paddingRight: 2,
                          }}
                        >
                          Não
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 15,
                            color: '#FFF',
                            paddingRight: 2,
                          }}
                        >
                          Sim
                        </div>
                      }
                    />
                  </SwitchContainer>

                  {invalidez == true && (
                    <TextContainer>
                      <TextFieldContainer mobile>
                        <LocalizationProvider
                          adapterLocale={ptBR}
                          dateAdapter={AdapterDateFns}
                        >
                          <DesktopDatePicker
                            maxDate={new Date()}
                            minDate={dataNascimento}
                            label="Data de invalidez"
                            value={dataInvalidez}
                            onChange={handleChangeDataInvalidez}
                            sx={{ marginBottom: '32px' }}
                            slotProps={{
                              textField: {
                                error: errorInvalidez,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </TextFieldContainer>
                    </TextContainer>
                  )}

                  <Footer>
                    <Button
                      outlinedVinculados
                      onClick={() => {
                        handleCloseModalVinculado();
                      }}
                    >
                      Excluir
                    </Button>
                    <Button
                      normalVinculados
                      onClick={() => {
                        enviarVinculado();
                      }}
                    >
                      Adicionar
                    </Button>
                  </Footer>
                </>
              )}

              {openEtapaAvisoSobrinho == true ||
              openEtapaInvalidezSobrinho == true ||
              openProximaEtapaDireto == true ? null : (
                <Footer>
                  <Button
                    outlinedVinculados
                    onClick={() => {
                      handleCloseModalVinculado();
                    }}
                  >
                    Excluir
                  </Button>
                  <Button
                    normalVinculados
                    onClick={() => {
                      verificaGrauParentesco();
                    }}
                  >
                    Prosseguir
                  </Button>
                </Footer>
              )}
            </ModalWrapper>
          )}
        </Dialog>
      )}

      <ModalDocumentos
        verificarDocumentoConsentimento={
          (grauParentesco == 'Sobrinho(a)' && idade < 12) ||
          (grauParentesco === 'Outros / Sem parentesco' && idade < 12) ||
          (grauParentesco === 'Irmão / Irmã' && idade < 12)
        }
        showModal={showModalDocumentos}
        setShowModal={setShowModalDocumentos}
      />
    </>
  );
}
