import Files from 'react-files';
import { MdAddCircle } from 'react-icons/md';

import { Button } from 'components/Button';

import { useInfoPessoais } from 'context/InfoPessoais';

import uploadIcon from 'assets/images/cloud-upload.svg';

import {
  Container,
  StyledFiles,
  Text,
  Image,
  Description,
  ContainerButtonDocs,
} from './styles';

export function Dropzone() {
  const {
    onFilesChange,
    onFilesError,
    handleClickRg,
    handleClickCpf,
    handleClickCertidaoNascimento,
  } = useInfoPessoais();

  return (
    <>
      <Container>
        <StyledFiles
          onChange={onFilesChange}
          onError={onFilesError}
          accepts={['.jpg', '.pdf', '.tif']}
          multiple={false}
          maxFiles={10}
          maxFileSize={1000000000000000}
          minFileSize={0}
          clickable
        >
          <Image src={uploadIcon} alt="Upload" />
          <Text>Arraste seus arquivos até aqui</Text>
        </StyledFiles>
      </Container>

      <Description>ou</Description>

      <div>
        <Files
          onChange={onFilesChange}
          onError={onFilesError}
          accepts={['.jpg', '.pdf', '.tif']}
          multiple={false}
          maxFiles={10}
          maxFileSize={1000000000000000}
          minFileSize={0}
          clickable
        >
          <ContainerButtonDocs>
            <Button
              iconeDropzone
              onClick={() => {
                handleClickRg();
              }}
              endIcon={<MdAddCircle size={24} color="#00887D" />}
            >
              Selecione o RG/CNH ou RNE
            </Button>

            <Button
              iconeDropzone
              onClick={() => {
                handleClickCpf();
              }}
              endIcon={<MdAddCircle size={24} color="#00887D" />}
            >
              Selecione o CPF
            </Button>

            <Button
              iconeDropzone
              onClick={() => {
                handleClickCertidaoNascimento();
              }}
              endIcon={<MdAddCircle size={24} color="#00887D" />}
            >
              Selecione a Certidão de Nascimento
            </Button>
          </ContainerButtonDocs>
        </Files>
      </div>
    </>
  );
}

export function DropzoneVinculado() {
  const { onFileChangeVinculado, onFilesErrorVinculado } = useInfoPessoais();

  return (
    <>
      <Container>
        <StyledFiles
          onChange={onFileChangeVinculado}
          onError={onFilesErrorVinculado}
          accepts={['image/*', '.pdf']}
          maxFiles={10}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
          multiple={false}
        >
          <Image src={uploadIcon} alt="Upload" />
          <Text>Arraste seus arquivos até aqui</Text>
        </StyledFiles>
      </Container>

      <Description>ou</Description>

      <div>
        <Files
          onChange={onFileChangeVinculado}
          onError={onFilesErrorVinculado}
          accepts={['image/*', '.pdf']}
          multiple={false}
          maxFiles={10}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          <Button
            iconeOutlinedGreen
            endIcon={<MdAddCircle size={24} color="#00887D" />}
          >
            Selecione o arquivo
          </Button>
        </Files>
      </div>
    </>
  );
}
