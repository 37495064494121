import { useEffect } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputAdornment from '@mui/material/InputAdornment';

import { useResponsive } from 'hook/useResponsive';

import { Button } from 'components/Button';
import { ModalVinculado } from 'components/ModalVinculado';

import { useModal } from 'context/Modal';
import { useSteps } from 'context/Steps';

import checkIcon from 'assets/images/check.png';
import personImage from 'assets/images/person2.png';

import {
  Container,
  TitleContainer,
  ContainerMid,
  ContainerVinculados,
  ContainerButton,
  TextContainer,
  StyledTextFieldMobile,
  ImageProfile,
  ContainerButtonTop,
  ContainerVinculadosMobile,
  ContainerImage,
  ContainerButtonFooter,
} from './styles';

export function VinculadosMobile() {
  const { mobileLayout } = useResponsive();

  const { handleNext } = useSteps();

  const {
    showModalVinculado,
    setShowModalVinculado,
    openModalVinculado,
    handleExcluirVinculado,
    handleLoadVinculado,
    todosVinculados,
    handleLoadVinculados,
    excluido,
    disabledAll,
    carregandoVinculado,
  } = useModal();

  useEffect(() => {
    handleLoadVinculados();
  }, [showModalVinculado, excluido, carregandoVinculado]);

  return (
    <>
      {mobileLayout && (
        <Container mobile>
          <TitleContainer mobile>
            <h2>
              {todosVinculados && todosVinculados.length > 0
                ? 'Vinculados'
                : 'Adicione seus vinculado(s)'}
            </h2>
          </TitleContainer>

          {todosVinculados && todosVinculados.length > 0 ? (
            <ContainerButtonTop mobile>
              <Button
                icone
                disabled={disabledAll}
                style={{ width: '180px', height: '40px' }}
                startIcon={<AddCircleIcon style={{ fontSize: '24px' }} />}
                onClick={() => {
                  openModalVinculado();
                }}
              >
                Adicionar vinculado
              </Button>
            </ContainerButtonTop>
          ) : (
            <Button
              icone
              disabled={disabledAll}
              startIcon={<AddCircleIcon style={{ fontSize: '24px' }} />}
              onClick={() => {
                openModalVinculado();
              }}
            >
              Adicionar vinculado
            </Button>
          )}

          {todosVinculados && todosVinculados.length > 0 ? (
            <ContainerMid mobile>
              {todosVinculados.map((pessoa) => (
                <ContainerVinculados key={pessoa.id} mobile>
                  <ContainerVinculadosMobile>
                    <ContainerImage>
                      <ImageProfile mobile src={personImage} alt="Pessoa" />
                    </ContainerImage>
                    <TextContainer mobile>
                      <StyledTextFieldMobile
                        id="outlined-basic"
                        label="Nome do vinculado"
                        variant="outlined"
                        type="nome"
                        disabled
                        value={pessoa.nome}
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              style={{ marginTop: '5px' }}
                              position="end"
                            >
                              <ImageProfile src={checkIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </TextContainer>
                  </ContainerVinculadosMobile>
                  <ContainerButton>
                    <Button
                      outlinedVinculados
                      onClick={() => {
                        handleLoadVinculado(pessoa.numPedido, pessoa.idNumSeq);
                      }}
                    >
                      Editar
                    </Button>
                    <Button
                      outlinedVinculados
                      onClick={() => {
                        handleExcluirVinculado(
                          pessoa.numPedido,
                          pessoa.idNumSeq
                        );
                      }}
                    >
                      Excluir
                    </Button>
                  </ContainerButton>
                </ContainerVinculados>
              ))}
            </ContainerMid>
          ) : (
            <ContainerMid>
              O FlexPrev conta com os benefícios de abono ou pensão por morte.
              Assim, após a morte do participante, seja na condição de ativo ou
              de assistido, o saldo de conta remanescente é convertido em pensão
              por morte para as pessoas que forem vinculadas ao plano por você.
              Esses vinculados, que serão seus beneficiários, também têm a opção
              de receber todo o saldo remanescente de uma só vez, o chamado
              abono por morte, em substituição ao recebimento de renda mensal de
              pensão por morte. Os beneficiários podem ser de qualquer natureza,
              não havendo necessidade de vínculo sanguíneo ou familiar. Caso o
              participante não registre beneficiários no seu cadastro no plano,
              o saldo de conta remanescente será pago aos herdeiros, conforme
              definido em inventário.
            </ContainerMid>
          )}
          {todosVinculados && todosVinculados.length == 0 ? (
            <ContainerButtonFooter mobile>
              <Button outlinedVinculados>Não adicionar</Button>
            </ContainerButtonFooter>
          ) : (
            <ContainerButtonFooter mobile>
              <Button
                normal
                onClick={() => {
                  handleNext();
                }}
              >
                Avançar
              </Button>
            </ContainerButtonFooter>
          )}
        </Container>
      )}
      <ModalVinculado
        showModal={showModalVinculado}
        setShowModal={setShowModalVinculado}
      />
    </>
  );
}
