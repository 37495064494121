import { useEffect } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputAdornment from '@mui/material/InputAdornment';

import { useResponsive } from 'hook/useResponsive';

import { Button } from 'components/Button';
import { ModalVinculado } from 'components/ModalVinculado';

import { useModal } from 'context/Modal';
import { useSteps } from 'context/Steps';

import checkIcon from 'assets/images/check.png';
import personImage from 'assets/images/person2.png';
import DrawImage from 'assets/images/undraw.png';

import { VinculadosMobile } from './mobile';
import {
  Container,
  TitleContainer,
  ContainerMid,
  Footer,
  Image,
  ContainerVinculados,
  ContainerButton,
  TextContainer,
  StyledTextField,
  ImageProfile,
  ContainerButtonTop,
  ContainerButtonFooter,
} from './styles';

export function Vinculados() {
  const { mobileLayout } = useResponsive();
  const { handleNext } = useSteps();

  const {
    showModalVinculado,
    setShowModalVinculado,
    openModalVinculado,
    handleExcluirVinculado,
    handleLoadVinculado,
    todosVinculados,
    handleLoadVinculados,
    excluido,
    disabledAll,
    carregandoVinculado,
  } = useModal();

  useEffect(() => {
    handleLoadVinculados();
  }, [showModalVinculado, excluido, carregandoVinculado]);

  return (
    <>
      {!mobileLayout && (
        <Container>
          <TitleContainer>
            <h2>
              {todosVinculados && todosVinculados.length > 0
                ? 'Vinculados'
                : 'Adicione seus vinculado(s)'}
            </h2>
            {todosVinculados && todosVinculados.length > 0 ? (
              <ContainerButtonTop>
                <Button
                  icone
                  disabled={disabledAll}
                  startIcon={<AddCircleIcon style={{ fontSize: '24px' }} />}
                  onClick={() => {
                    openModalVinculado();
                  }}
                >
                  Adicionar vinculado
                </Button>
              </ContainerButtonTop>
            ) : (
              <Button
                icone
                disabled={disabledAll}
                startIcon={<AddCircleIcon style={{ fontSize: '24px' }} />}
                onClick={() => {
                  openModalVinculado();
                }}
              >
                Adicionar vinculado
              </Button>
            )}
          </TitleContainer>

          {todosVinculados && todosVinculados.length > 0 ? (
            <ContainerMid grid>
              {todosVinculados.map((pessoa) => (
                <ContainerVinculados key={pessoa.id}>
                  <ImageProfile src={personImage} alt="Pessoa" />
                  <TextContainer>
                    <StyledTextField
                      id="outlined-basic"
                      label="Nome do vinculado"
                      variant="outlined"
                      type="nome"
                      disabled
                      value={pessoa.nome}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            style={{ marginTop: '5px' }}
                            position="end"
                          >
                            <ImageProfile src={checkIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TextContainer>

                  <ContainerButton>
                    <Button
                      outlinedVinculados
                      disabled={disabledAll}
                      onClick={() => {
                        handleLoadVinculado(pessoa.numPedido, pessoa.idNumSeq);
                        // editarVinculado(pessoa);
                      }}
                    >
                      Editar
                    </Button>
                    <Button
                      outlinedVinculados
                      disabled={disabledAll}
                      onClick={() => {
                        handleExcluirVinculado(
                          pessoa.numPedido,
                          pessoa.idNumSeq
                        );
                      }}
                    >
                      Excluir
                    </Button>
                  </ContainerButton>
                </ContainerVinculados>
              ))}
            </ContainerMid>
          ) : (
            <>
              <ContainerMid>
                O FlexPrev conta com os benefícios de abono ou pensão por morte.
                Assim, após a morte do participante, seja na condição de ativo
                ou de assistido, o saldo de conta remanescente é convertido em
                pensão por morte para as pessoas que forem vinculadas ao plano
                por você. Esses vinculados, que serão seus beneficiários, também
                têm a opção de receber todo o saldo remanescente de uma só vez,
                o chamado abono por morte, em substituição ao recebimento de
                renda mensal de pensão por morte. Os beneficiários podem ser de
                qualquer natureza, não havendo necessidade de vínculo sanguíneo
                ou familiar. Caso o participante não registre beneficiários no
                seu cadastro no plano, o saldo de conta remanescente será pago
                aos herdeiros, conforme definido em inventário.
              </ContainerMid>

              <Footer>
                <h2>Até o momento você não possui vinculados</h2>
                <Image src={DrawImage} alt="Árvores" />
              </Footer>
            </>
          )}

          {todosVinculados && todosVinculados.length == 0 ? (
            <ContainerButtonFooter style={{ marginTop: '-2.25rem' }}>
              <Button
                onClick={() => {
                  handleNext();
                }}
                outlinedVinculados
              >
                Não adicionar
              </Button>
            </ContainerButtonFooter>
          ) : (
            <ContainerButtonFooter>
              <Button
                normal
                onClick={() => {
                  handleNext();
                }}
              >
                Avançar
              </Button>
            </ContainerButtonFooter>
          )}
        </Container>
      )}

      {mobileLayout && <VinculadosMobile />}
      <ModalVinculado
        showModal={showModalVinculado}
        setShowModal={setShowModalVinculado}
      />
    </>
  );
}
