import { useState, createContext, useContext } from 'react';

// import { getTravarUser } from 'services/loginServices';
import { getTributacao, updateTributacao } from 'services/tributacaoServices';

import { useAuth } from './Auth';
import { useSteps } from './Steps';

const Tributacao = createContext();

export function TributacaoProvider({ children }) {
  const [regime, setRegime] = useState('');
  const { travaCadastro } = useAuth();
  const { setPage, handleNext } = useSteps();

  const handleChangeRegime = (event) => {
    setRegime(event.target.value);
  };

  const handleClickRegime = (tipoRegime) => {
    setRegime(tipoRegime);
  };

  const verificarRegime = () => {
    if (regime === 'NO') {
      return 'Regime progressivo';
    }
    if (regime === 'RE') {
      return 'Regime regressivo';
    }
    if (regime === 'futuro') {
      return 'Desejo optar depois';
    }
    return 'Nenhum';
  };

  const handleLoadTributacao = async () => {
    const dados = sessionStorage.getItem('@user:dados');

    if (!dados) return;

    const { numPedido, numIdPf } = JSON.parse(dados);

    if (!numPedido && !numIdPf) return;

    const { tipoTributo } = await getTributacao(numPedido, numIdPf);

    // const { travarCadastro } = await getTravarUser(numPedido, numIdPf);

    // setTravaCadastro(travarCadastro);

    if (!tipoTributo) return;

    setRegime(tipoTributo);
  };

  const enviarTributacao = async () => {
    const dados = sessionStorage.getItem('@user:dados');

    if (!dados) return;

    const { numPedido, numIdPf } = JSON.parse(dados);

    if (!numPedido && !numIdPf) return;

    const body = {
      tipoTributo: regime,
    };

    await updateTributacao(numPedido, numIdPf, body);
  };

  const handleConfirmacaoSteps = () => {
    setPage('confirmacao');
    enviarTributacao();
    handleNext();
  };

  const isDisabledAll = () => {
    if (travaCadastro == 2) {
      return true;
    }
    return false;
  };

  return (
    <Tributacao.Provider
      value={{
        regime,
        handleChangeRegime,
        handleClickRegime,
        verificarRegime,
        enviarTributacao,
        handleLoadTributacao,
        disabledAll: isDisabledAll(),
        handleConfirmacaoSteps,
      }}
    >
      {children}
    </Tributacao.Provider>
  );
}

export function useTributacao() {
  const context = useContext(Tributacao);
  if (!context) {
    throw new Error('useTributacao must be used within a TributacaoProvider');
  }
  return context;
}
