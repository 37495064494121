import { useEffect, useState } from 'react';

import { Box, Tab, Tabs, Typography } from '@mui/material';

// import { getAccessToken } from 'services/sensediaServices';

import { Prospect } from '../Prospect';
import { Usuarios } from '../Usuarios';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanels() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // getAccessToken();
  }, []);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          marginTop: '1rem',
          marginLeft: '2rem',
        }}
      >
        <Tabs
          TabIndicatorProps={{ style: { background: '#00887d' } }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Prospect" />
          <Tab label="Ciência do Patrocinador" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Prospect />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Usuarios />
      </TabPanel>
    </>
  );
}

export default TabPanels;
