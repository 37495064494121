import { useEffect } from 'react';

import { Dialog, MenuItem } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import { isCpf } from 'iscpf';
import { saveProspect, updateProspect } from 'services/prospectServices';
import formatarCPF from 'util/formatarCPF';
import formatReal, { removeRealFormat } from 'util/formatReal';
import * as yup from 'yup';

import { useModalProspect } from 'context/ModalProspect';
import { useProspect } from 'context/Prospects';
import { useToast } from 'context/Toast';

import { Button } from '../Button';
import {
  Container,
  ContainerFormDouble,
  Footer,
  ModalWrapper,
  StyledTextField,
} from './styles';

export function ModalCadastro(props) {
  const { userData, editMode } = props;
  const { toast } = useToast();
  const { getListaProspects } = useProspect();
  const { handleCloseModalCadastro, showModalCadastro } = useModalProspect();

  const validationSchema = yup.object({
    nomeCompleto: yup
      .string('')
      .required('Campo obrigatório')
      .min(10, 'Insira o nome completo'),
    cpf: yup
      .string('')
      .required('Campo obrigatório')
      .test('is-cpf', 'CPF inválido', (valor) => isCpf(valor)),
    dtNascimento: yup
      .date()
      .nullable()
      .required('Campo obrigatório')
      .typeError('Data inválida'),
    dtAdmissao: yup
      .date()
      .nullable()
      .min(yup.ref('dtNascimento'), 'Data de admissão menor que nascimento')
      .max(new Date(), 'Data de adimissão não pode ser maior que data atual.')
      .typeError('Data inválida'),
    matricula: yup.string('').required('Campo obrigatório'),
  });

  const trataNome = (nome) =>
    String(nome)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toString()
      .toUpperCase();

  const formik = useFormik({
    initialValues: {
      nomeCompleto: userData?.nomeCompleto || '',
      cpf: userData?.numCpf || '',
      salario: userData?.salario || '',
      dtNascimento: userData?.dtNasc || null,
      dtAdmissao: userData?.dtAdmissao || null,
      matricula: userData?.numMatricula || '',
      sexo: userData?.codSexo || '1',
    },
    validationSchema,
    onSubmit: async ({
      nomeCompleto,
      cpf,
      salario,
      dtNascimento,
      sexo,
      dtAdmissao,
      matricula,
    }) => {
      if (!cpf || !nomeCompleto || !matricula) {
        toast.error('Preencha os campos obrigatórios');
        return;
      }

      if (dtNascimento.toISOString() === dtAdmissao.toISOString()) {
        toast.error('Data de admissão não pode ser igual a data de nascimento');
        return;
      }

      try {
        const body = {
          cpf,
          nome: trataNome(nomeCompleto),
          dtNascimento: new Date(dtNascimento).toISOString().split('T')[0],
          sexo,
          numMatricPatroc: matricula,
          dtAdmissao: new Date(dtAdmissao).toISOString().split('T')[0],
          salario: removeRealFormat(salario),
          numIdEmpresa: 479,
        };

        if (!editMode) {
          try {
            toast.loading('Salvando dados', { id: 'salvando' });
            await saveProspect(body);
            toast.success('Dados salvos com sucesso', { id: 'salvando' });
            await getListaProspects();
          } catch (error) {
            toast.error('Erro ao salvar dados', { id: 'salvando' });
          }
        } else {
          try {
            toast.loading('Salvando dados', { id: 'salvando' });
            await updateProspect(body);
            toast.success('Dados salvos com sucesso', { id: 'salvando' });
            await getListaProspects();
          } catch (error) {
            toast.error('Erro ao salvar dados', { id: 'salvando' });
          }
        }

        handleCloseModalCadastro();
        apagarDados();
      } catch (err) {
        toast.error(err);
      }
    },
  });

  const SEXO_CONST = [
    {
      label: 'Masculino',
      value: 'M',
    },
    {
      label: 'Feminino',
      value: 'F',
    },
  ];

  useEffect(() => {
    if (userData && editMode) {
      formik.setValues(
        {
          cpf: userData.numCpf,
          nomeCompleto: userData.nomeCompleto,
          dtNascimento: new Date(userData.dtNasc),
          sexo: userData.codSexo,
          matricula: userData.numMatricula,
          dtAdmissao: new Date(userData.dtAdmissao),
          salario: userData.valSalario,
        },
        false
      );
    }
  }, [userData, editMode]);

  const apagarDados = () => {
    formik.setValues(
      {
        cpf: '',
        nomeCompleto: '',
        dtNascimento: null,
        sexo: '1',
        matricula: '',
        dtAdmissao: null,
        salario: '',
      },
      false
    );
  };

  return (
    <>
      {showModalCadastro && (
        <Dialog
          onClose={() => {
            handleCloseModalCadastro();
            apagarDados();
          }}
          aria-labelledby="simple-dialog-title"
          open={showModalCadastro}
          scroll="body"
          fullWidth
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <ModalWrapper>
              <Container>
                <ContainerFormDouble>
                  <StyledTextField
                    size="medium"
                    variant="outlined"
                    label="CPF"
                    id="cpf"
                    value={formatarCPF(formik.values.cpf) || ''}
                    placeholder="Digite o CPF"
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 14 }}
                    error={Boolean(formik.errors.cpf) && formik.touched.cpf}
                    helperText={formik.errors.cpf}
                  />

                  <StyledTextField
                    size="medium"
                    variant="outlined"
                    label="Matrícula"
                    id="matricula"
                    value={formik.values.matricula || ''}
                    placeholder="Digite a matrícula"
                    onChange={formik.handleChange}
                  />
                </ContainerFormDouble>

                <ContainerFormDouble>
                  <StyledTextField
                    size="medium"
                    variant="outlined"
                    label="Nome Completo"
                    id="nomeCompleto"
                    value={formik.values.nomeCompleto || ''}
                    placeholder="Digite seu Nome Completo"
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.nomeCompleto) &&
                      formik.touched.nomeCompleto
                    }
                    helperText={formik.errors.nomeCompleto}
                  />

                  <StyledTextField
                    size="medium"
                    variant="outlined"
                    label="Salário"
                    id="salario"
                    value={formatReal(formik.values.salario) || ''}
                    placeholder="Digite seu salário"
                    onChange={formik.handleChange}
                  />
                </ContainerFormDouble>

                <ContainerFormDouble>
                  <LocalizationProvider
                    adapterlocale={ptBR}
                    dateAdapter={AdapterDateFns}
                  >
                    <DesktopDatePicker
                      maxDate={new Date()}
                      label="Data de Nascimento"
                      value={formik.values.dtNascimento}
                      onChange={(value) => {
                        formik.setFieldValue('dtNascimento', value);
                      }}
                      slotProps={{
                        textField: {
                          name: 'dtNascimento',
                          onBlur: formik.handleBlur,
                          error:
                            formik.touched.dtNascimento &&
                            Boolean(formik.errors.dtNascimento),
                          helperText:
                            formik.touched.dtNascimento &&
                            formik.errors.dtNascimento,
                        },
                      }}
                    />
                  </LocalizationProvider>

                  <StyledTextField
                    className="field-class"
                    select
                    value={formik.values.sexo || '1'}
                    label="Sexo"
                    variant="outlined"
                    id="sexo"
                    onChange={(e) => {
                      formik.setFieldValue('sexo', e.target.value);
                    }}
                  >
                    <MenuItem key="0" value="1">
                      Selecione
                    </MenuItem>
                    {SEXO_CONST.map((x, i) => (
                      <MenuItem key={i} value={x.value}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </ContainerFormDouble>

                <ContainerFormDouble
                  style={{
                    justifyContent: 'flex-start',
                    marginLeft: '17px',
                    width: '97%',
                  }}
                >
                  <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}
                  >
                    <DesktopDatePicker
                      maxDate={new Date()}
                      label="Data de admissão"
                      value={formik.values.dtAdmissao}
                      onChange={(value) => {
                        formik.setFieldValue('dtAdmissao', value);
                      }}
                      slotProps={{
                        textField: {
                          name: 'dtAdmissao',
                          onBlur: formik.handleBlur,
                          error:
                            formik.touched.dtAdmissao &&
                            Boolean(formik.errors.dtAdmissao),
                          helperText:
                            formik.touched.dtAdmissao &&
                            formik.errors.dtAdmissao,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </ContainerFormDouble>

                <Footer>
                  <Button
                    outlinedVinculados
                    onClick={() => {
                      handleCloseModalCadastro();
                      apagarDados();
                    }}
                  >
                    Cancelar
                  </Button>

                  <Button style={{ width: '159px' }} normal type="submit">
                    Salvar
                  </Button>
                </Footer>
              </Container>
            </ModalWrapper>
          </form>
        </Dialog>
      )}
    </>
  );
}
