import { api } from './api';
import {
  getAccessToken,
  getBearerToken,
  getGrantCode,
} from './sensediaServices';

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    const bearerToken = localStorage.getItem('bearerToken');
    const userToken = localStorage.getItem('userToken');
    if (accessToken && bearerToken) {
      config.headers.access_token = accessToken;
      config.headers.Authorization = `Bearer ${bearerToken}`;
      config.headers['x-token-petros'] = `Bearer ${bearerToken}`;
    }
    if (userToken) {
      config.headers['User-Authorization'] = `Bearer ${userToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Verifica autorização do Sensedia
    if (
      (error.response?.status === 404 &&
        error.response?.data.result === 'Invalid Token') ||
      (error.response?.status === 401 &&
        error.response?.headers['content-type'] === 'text/plain')
    ) {
      try {
        const grantCodeResponse = await getGrantCode();
        const accessTokenResponse = await getAccessToken(grantCodeResponse);
        const bearerTokenResponse = await getBearerToken();
        localStorage.setItem(
          'accessToken',
          accessTokenResponse.data.access_token
        );
        localStorage.setItem(
          'bearerToken',
          bearerTokenResponse.data.access_token
        );
        return api(error.config);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    // Token expirado ou usuário não autorizado
    if (error.response?.status === 403) {
      if (localStorage.getItem('userToken')) {
        localStorage.removeItem('userToken'); // Limpar o token armazenado
        window.location = '/'; // Redirecionar para a página de login
      }
    }
    return Promise.reject(error);
  }
);
