import { useEffect, useState } from 'react';

const useKeyLock = (key) => {
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.getModifierState) {
        setToggled(event.getModifierState(key));
      }
    };

    const onKeyUp = (event) => {
      if (event.getModifierState) {
        setToggled(event.getModifierState(key));
      }
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [key]);

  return { toggled };
};

export default useKeyLock;
