import { Dialog } from '@mui/material';

import { Button } from 'components/Button';

import { useModal } from 'context/Modal';

import logoImg from 'assets/images/logo2.png';

import { Footer, LogoImage, ModalWrapper, TextContainer } from './styles';

export function ModalAvisoEmail() {
  const { handleCloseModalAvisoEmail, showModalAvisoEmail } = useModal();

  return (
    <Dialog
      onClose={handleCloseModalAvisoEmail}
      aria-labelledby="simple-dialog-title"
      open={showModalAvisoEmail}
      scroll="body"
      fullWidth
    >
      <ModalWrapper>
        <LogoImage src={logoImg} alt="Logo Petros" />
        <h2> Verifique seu e-mail</h2>
        <TextContainer>
          <p>
            <b>
              Nós enviamos um e-mail para a sua segurança. Por favor,
              verifique-o para continuar com o seu cadastro.
            </b>
          </p>
        </TextContainer>
        <TextContainer style={{ marginTop: '-3em' }}>
          <p>
            Caso não tenha recebido e não esteja na caixa de spam, favor entrar
            em contato com a Central de Relacionamento Petros (0800 025 35 45),
            para ligações de celular: 21 3529-5550 .
          </p>
        </TextContainer>

        <Footer>
          <Button
            style={{ width: '159px !important' }}
            normal
            onClick={() => {
              handleCloseModalAvisoEmail();
            }}
          >
            Continuar
          </Button>
        </Footer>
      </ModalWrapper>
    </Dialog>
  );
}
