import { useEffect, useState } from 'react';

import { useResponsive } from 'hook/useResponsive';
import { getCienciaPatrocinadora, getPedidos } from 'services/statusServices';

import { useSteps } from 'context/Steps';
import { useToast } from 'context/Toast';

import ArrowIcon from 'assets/images/arrow.svg';
import CheckedDisabledIcon from 'assets/images/check_disabled.svg';
import CheckedIcon from 'assets/images/check_green.svg';
import ApplyIconDisabled from 'assets/images/noun_apply_disabled.svg';
import ApplyIcon from 'assets/images/noun_apply.svg';
import AuthorizeIconDisabled from 'assets/images/noun_authorize_disabled.svg';
import StampIconDisabled from 'assets/images/noun_Stamp_disabled.svg';
import StampIcon from 'assets/images/noun_Stamp.svg';
import WarningIcon from 'assets/images/warning_icon.svg';

import {
  Container,
  TitleContainer,
  StatusBarWrapper,
  BorderLinearProgress,
  StatusWrapper,
  StatusContainer,
  StatusItem,
  StatusListWrapper,
  List,
  ListItem,
  Icon,
  ProtocoloContainer,
  NumeroProtocolo,
  AtencaoContainer,
} from './styles';

export function StatusMobile() {
  const { toast } = useToast();
  const { mobileLayout } = useResponsive();
  const { handleAprovado } = useSteps();
  const [numeroProtocolo, setNumeroProtocolo] = useState();
  const [statusCode, setStatusCode] = useState();

  useEffect(() => {
    async function recebeProtocolo() {
      const dados = sessionStorage.getItem('@user:dados');

      if (!dados) {
        toast.error('Dados não encontrados');
        return;
      }

      const { numPedido } = JSON.parse(dados);

      if (!numPedido) {
        toast.error('Número do pedido não encontrado');
        return;
      }

      const { numSeqStpsolass } = await getCienciaPatrocinadora(numPedido);

      const { numIdSolServico } = await getPedidos(numPedido);

      setStatusCode(numSeqStpsolass);
      setNumeroProtocolo(numIdSolServico);
    }

    recebeProtocolo();
  }, []);

  const verificaStatusCode = () => {
    if (statusCode === 879 || statusCode === 880 || statusCode === 882) {
      return 30;
    }
    if (statusCode === 881) {
      return 50;
    }
    if (statusCode === 884) {
      handleAprovado();
      return 100;
    }
    if (statusCode === 885) {
      return 100;
    }

    return 0;
  };

  return (
    <>
      {mobileLayout && (
        <Container mobile>
          <TitleContainer mobile>
            <h2>Status do seu pedido de inscrição</h2>
            {numeroProtocolo && (
              <ProtocoloContainer>
                <h3>Protocolo</h3>
                <NumeroProtocolo>
                  <h2>{numeroProtocolo}</h2>
                </NumeroProtocolo>
              </ProtocoloContainer>
            )}
          </TitleContainer>
          <p>O prazo para deferimento do pedido será de 30 dias</p>
          <StatusBarWrapper mobile>
            <StatusWrapper>
              <div style={{ width: '95%' }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={verificaStatusCode()}
                  style={{
                    height: '20px',
                    borderRadius: '10px',
                    background: '#C4C4C4',
                  }}
                  color="primary"
                />
              </div>

              {statusCode === 879 ||
                (statusCode === 880 && (
                  <>
                    <StatusContainer mobile>
                      <StatusItem mobile>
                        <img
                          src={ApplyIcon}
                          alt="Autorização"
                          style={{ width: '32px' }}
                        />
                        <p>Pedido de inscrição</p>
                      </StatusItem>
                      <StatusItem mobile>
                        <img
                          src={StampIconDisabled}
                          alt="Autorização"
                          style={{ width: '24px' }}
                        />
                        <p>Aprovação da patrocinadora</p>
                      </StatusItem>
                      <StatusItem mobile>
                        <img
                          src={AuthorizeIconDisabled}
                          alt="Autorização"
                          style={{ width: '26px' }}
                        />
                        <p>Aprovação da Petros</p>
                      </StatusItem>
                    </StatusContainer>

                    <StatusWrapper style={{ marginTop: '32px' }}>
                      <StatusListWrapper>
                        <List>
                          <ListItem mobile>
                            <Icon src={CheckedIcon} alt="Aprovado" />
                            <p>
                              Seu pedido de inscrição foi enviado com sucesso
                            </p>
                          </ListItem>
                        </List>
                      </StatusListWrapper>
                    </StatusWrapper>
                  </>
                ))}

              {statusCode == 881 && (
                <>
                  <StatusContainer mobile>
                    <StatusItem mobile>
                      <img
                        src={ApplyIcon}
                        alt="Autorização"
                        style={{ width: '32px' }}
                      />
                      <p>Pedido de inscrição</p>
                    </StatusItem>
                    <StatusItem mobile>
                      <img
                        src={StampIcon}
                        alt="Autorização"
                        style={{ width: '24px' }}
                      />
                      <p>Aprovação da patrocinadora</p>
                    </StatusItem>
                    <StatusItem mobile>
                      <img
                        src={AuthorizeIconDisabled}
                        alt="Autorização"
                        style={{ width: '26px' }}
                      />
                      <p>Aprovação da Petros</p>
                    </StatusItem>
                  </StatusContainer>

                  <StatusWrapper style={{ marginTop: '32px' }}>
                    <StatusListWrapper>
                      <List>
                        <ListItem mobile>
                          <Icon src={CheckedIcon} alt="Aprovado" />
                          <p>Seu pedido de inscrição foi enviado com sucesso</p>
                        </ListItem>
                        <ListItem mobile>
                          <Icon src={CheckedIcon} alt="Aprovado" />
                          <p>
                            Seu pedido de inscrição foi avaliado pela
                            patrocinadora
                          </p>
                        </ListItem>
                      </List>
                    </StatusListWrapper>
                  </StatusWrapper>
                </>
              )}

              {statusCode == 884 && (
                <>
                  <StatusContainer mobile>
                    <StatusItem mobile>
                      <img
                        src={ApplyIcon}
                        alt="Autorização"
                        style={{ width: '32px' }}
                      />
                      <p>Pedido de inscrição</p>
                    </StatusItem>
                    <StatusItem mobile>
                      <img
                        src={StampIcon}
                        alt="Autorização"
                        style={{ width: '24px' }}
                      />
                      <p>Aprovação da patrocinadora</p>
                    </StatusItem>
                    <StatusItem mobile>
                      <img
                        src={AuthorizeIconDisabled}
                        alt="Autorização"
                        style={{ width: '26px' }}
                      />
                      <p>Aprovação da Petros</p>
                    </StatusItem>
                  </StatusContainer>

                  <StatusWrapper style={{ marginTop: '32px' }}>
                    <StatusListWrapper>
                      <List>
                        <ListItem mobile>
                          <Icon src={CheckedIcon} alt="Aprovado" />
                          <p>Seu pedido de inscrição foi enviado com sucesso</p>
                        </ListItem>
                        <ListItem mobile>
                          <Icon src={CheckedIcon} alt="Aprovado" />
                          <p>
                            Seu pedido de inscrição foi avaliado pela
                            patrocinadora
                          </p>
                        </ListItem>
                        <ListItem mobile>
                          <Icon src={ArrowIcon} alt="Aprovado" />
                          <p>
                            Seu pedido de inscrição foi aprovado pela Petros.
                            Seja bem-vindo ao Flexprev!
                          </p>
                        </ListItem>
                      </List>
                    </StatusListWrapper>
                  </StatusWrapper>
                </>
              )}

              {statusCode == 885 && (
                <>
                  <StatusContainer mobile>
                    <StatusItem mobile>
                      <img
                        src={ApplyIconDisabled}
                        alt="Autorização"
                        style={{ width: '32px' }}
                      />
                      <p>Pedido de inscrição</p>
                    </StatusItem>
                    <StatusItem mobile>
                      <img
                        src={StampIconDisabled}
                        alt="Autorização"
                        style={{ width: '24px' }}
                      />
                      <p>Aprovação da patrocinadora</p>
                    </StatusItem>
                    <StatusItem mobile>
                      <img
                        src={AuthorizeIconDisabled}
                        alt="Autorização"
                        style={{ width: '26px' }}
                      />
                      <p>Aprovação da Petros</p>
                    </StatusItem>
                  </StatusContainer>

                  <AtencaoContainer>
                    <Icon
                      src={WarningIcon}
                      alt="Aviso"
                      style={{ width: '32px', height: '32px' }}
                    />
                    <p>Atenção!</p>
                  </AtencaoContainer>
                  <StatusWrapper>
                    <StatusListWrapper>
                      <List>
                        <ListItem mobile>
                          <Icon src={CheckedDisabledIcon} alt="Aprovado" />
                          <p>Seu pedido de inscrição foi enviado com sucesso</p>
                        </ListItem>
                        <ListItem mobile>
                          <Icon src={CheckedDisabledIcon} alt="Aprovado" />
                          <p>
                            Seu pedido de inscrição foi avaliado pela
                            patrocinadora
                          </p>
                        </ListItem>
                        <ListItem mobile>
                          <Icon src={CheckedDisabledIcon} alt="Aprovado" />
                          <p>
                            Seu pedido de inscrição foi <b>indeferido</b>.
                          </p>
                        </ListItem>
                      </List>
                    </StatusListWrapper>
                  </StatusWrapper>
                </>
              )}
            </StatusWrapper>
          </StatusBarWrapper>
        </Container>
      )}
    </>
  );
}
