import { Dialog } from '@mui/material';

import { useModal } from 'context/Modal';

import { ModalWrapper, TextContainer } from './styles';

export function ModalPPE() {
  const { handleCloseModalPPE, showModalPPE } = useModal();

  return (
    <>
      {showModalPPE && (
        <Dialog
          onClose={handleCloseModalPPE}
          aria-labelledby="simple-dialog-title"
          open={showModalPPE}
          scroll="body"
          fullWidth
        >
          <ModalWrapper>
            <TextContainer>
              <h2>Pessoa Politicamente Exposta</h2>
              <p>
                Brasileiros que atuam ou atuaram como agentes públicos que
                desempenham ou desempenharam, nos últimos cinco anos, cargos,
                empregos ou funções públicas relevantes no Brasil ou em países,
                territórios e dependências estrangeiras são considerados pessoas
                expostas politicamente. Por isso, são reguladas pelo Conselho de
                Controle de Atividades Financeiras (Coaf). São considerados
                cargos, empregos ou funções públicas relevantes:
              </p>
              <ul>
                <li>
                  Detentores de mandatos eletivos dos poderes Executivo e
                  Legislativo da União;
                </li>
                <li>
                  Ocupantes de cargo, no Poder Executivo da União, de:
                  <br />
                  a) Ministro de Estado ou equiparado;
                  <br />
                  b) Natureza Especial ou equivalente;
                  <br />
                  c) presidente, vice-presidente e diretor, ou equivalentes, de
                  entidades da administração pública indireta; e
                  <br />
                  d) Grupo Direção e Assessoramento Superior (DAS), nível 6, ou
                  equivalente;
                </li>
                <li>
                  Membros do Supremo Tribunal Federal, dos Tribunais Superiores
                  e dos Tribunais Regionais Federais, do Trabalho e Eleitorais;
                </li>
                <li>
                  O Procurador-Geral da República, o Procurador-Geral do
                  Trabalho, o Procurador-Geral da Justiça Militar e os
                  Procuradores-Gerais de Justiça dos Estados e do Distrito
                  Federal;
                </li>
                <li>
                  Os membros do Tribunal de Contas da União e o Procurador-Geral
                  do Ministério Público junto ao Tribunal de Contas da União;
                </li>
                <li>
                  Os presidentes e tesoureiros nacionais, ou equivalentes, de
                  partidos políticos;
                </li>
                <li>
                  Os governadores e secretários de Estado e do Distrito Federal,
                  os Deputados Estaduais e Distritais, os presidentes, ou
                  equivalentes, de entidades da administração pública indireta
                  estadual e distrital e os presidentes de Tribunais de Justiça,
                  Militares, de Contas ou equivalente de Estado e do Distrito
                  Federal;
                </li>
                <li>
                  Os prefeitos, vereadores, presidentes de Tribunais de Contas
                  ou equivalente dos municípios.
                </li>
              </ul>
              <p>
                Também são consideradas pessoas expostas politicamente:
                representantes, familiares (parentes, na linha direta, até o
                segundo grau, o cônjuge, o companheiro, a companheira, o enteado
                e a enteada) e outras pessoas de relacionamento próximo dos
                agentes públicos que se encontrem nas situações listadas acima.
                <br />
                São também consideradas pessoas expostas politicamente as que
                exercem ou exerceram, nos últimos cinco anos, funções públicas
                importantes em país estrangeiro, como chefe de Estado e de
                governo, político de alto nível, servidor de alto escalão do
                poder público, magistrado ou militar de alto nível, dirigente de
                empresa pública ou dirigentes de partido político. Também são
                consideradas pessoas expostas politicamente os dirigentes de
                escalões superiores de entidades de direito internacional
                público ou privado.
              </p>
            </TextContainer>
          </ModalWrapper>
        </Dialog>
      )}
    </>
  );
}
