import styled, { css } from 'styled-components';

import loginBackground from 'assets/images/background.png';

export const Background = styled.div`
  display: flex;
  background-image: url(${loginBackground});
  background-size: cover;
  justify-content: space-between;
  background-repeat: no-repeat;
  height: 100% !important;
  min-height: 100vh;

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      background: linear-gradient(180deg, #00887d 0%, #00294b 100%);
      background-size: cover;
      justify-content: space-between;
      background-repeat: no-repeat;
      height: 100vh !important;
      flex-direction: column;
    `}
  }
`;

export const ContainerTopMobile = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  margin-top: 30px;
  flex-direction: column;
  color: #fff;
  text-align: center;
`;

export const ContainerLeft = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
  padding-right: 3rem;
  padding-left: 3rem;
`;

export const LogoImage = styled.img`
  margin-right: auto;
  margin-left: auto;
  max-width: 400px;

  ${(props) =>
    props.mobile &&
    css`
      max-width: 220px;
    `}
  }
`;

export const Separator = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 1.5rem;
      margin-bottom: 0rem;
    `}
  }
`;

export const Cabecalho = styled.div``;

export const Titulo = styled.p`
  text-align: left;
  font-size: 25px;
  font-weight: 400;

  ${(props) =>
    props.mobile &&
    css`
      text-align: center;
      font-size: 25px;
      font-weight: 400;
    `}
  }
`;

export const Descricao = styled.p`
  text-align: justify;
  margin-top: 3rem;
  margin-bottom: 3rem;

  ${(props) =>
    props.mobile &&
    css`
      text-align: justify;
      margin-top: 3rem;
      margin-bottom: 3rem;
      margin: 50px 20px 0px 20px;
    `}
  }
`;

export const DescricaoRodape = styled.p`
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 14px;
  margin: 20px 20px 20px 20px;
`;

export const ContainerRight = styled.div`
  background-color: white;
  border-radius: 2em;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TituloRightContainer = styled.div`
  margin-top: 3rem;
  font-size: 25px;
  font-weight: 600;
  padding: 3rem;
`;
