import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 80vh;
  flex-direction: column;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 95%;
  margin-top: 1rem;
  margin-left: 1rem;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 2rem;
      width: 100%;
      margin-left: 0;
      justify-content: center;

      h2 {
        font-size: 16px;
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.mobile &&
    css`
      flex-direction: column;
    `}
`;

export const ContainerAprovado = styled.div`
  display: flex;
  width: 70%;
  margin-left: 30px;
  flex-direction: column;

  h3 {
    color: #828282;
    font-weight: normal;
  }

  p {
    color: #828282;
    font-size: 14px;
  }
`;

export const ContainerInformacoes = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const MatriculaContainer = styled.div`
  display: flex;
  width: 341px;
  flex-direction: column;

  h3 {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #4f4f4f;
  }
`;

export const NumeroMatricula = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  width: 309px;
  height: 40px;

  background: #f2f2f2;
  border-radius: 27px;

  h2 {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.3em;
  }
`;

export const Image = styled.img`
  cursor: pointer;
`;

export const SenhaPetrosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 341px;
  height: 115px;

  h3 {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #4f4f4f;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 6rem;
`;

export const BaixeAppContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;

    color: #4f4f4f;
  }
`;

export const ContainerLojasApp = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;

  ${(props) =>
    props.mobile &&
    css`
      justify-content: space-evenly;
    `}
`;

export const QrCodeContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;
