import { useState } from 'react';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton } from '@mui/material';

import { steps } from 'components/Steps/item';

import { useInfoPessoais } from 'context/InfoPessoais';
import { useSteps } from 'context/Steps';

import FaleConoscoIconSelect from 'assets/images/headset_selected.svg';
import FaleConoscoIcon from 'assets/images/headset.svg';
import ProfileIcon from 'assets/images/profile.svg';

import {
  Container,
  ContainerButtons,
  ContainerProfile,
  ProfileName,
  StyledPopover,
  TextContainer,
} from './styles';

export function Header() {
  const { activeStep, handleBack, handleNext } = useSteps();
  const { page } = useInfoPessoais();

  const { nome } = JSON.parse(sessionStorage.getItem('@user:dados'))
    ? JSON.parse(sessionStorage.getItem('@user:dados'))
    : {};

  const [openPopover, setOpenPopover] = useState(null);

  const handleClickPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const open = Boolean(openPopover);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container>
      <ContainerButtons>
        <IconButton
          onClick={() => {
            handleBack();
          }}
          disabled={activeStep === 0 && page === 'documento'}
          aria-label="previous"
          style={{ background: '#f4f5f7' }}
        >
          <NavigateBeforeIcon />
        </IconButton>

        <StyledPopover
          id={id}
          open={open}
          anchorEl={openPopover}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <h3>Central de Relacionamento Petros</h3>
          <TextContainer>
            <p>
              Por meio deste canal você pode solicitar informações e esclarecer
              suas dúvidas.
            </p>
            <p>
              <b>0800 025 35 45</b> ou <b>(21) 3529-5550</b> para ligações de
              celular
            </p>
            <p style={{ fontSize: '12px' }}>
              Horário de atendimento 08h às 20h, de segunda a sexta-feira.
            </p>
          </TextContainer>
        </StyledPopover>

        <IconButton
          onClick={() => {
            handleNext();
          }}
          disabled={activeStep === steps.length - 1}
          aria-label="next"
          style={{ background: '#f4f5f7' }}
        >
          <NavigateNextIcon />
        </IconButton>
      </ContainerButtons>
      <ContainerProfile>
        <IconButton aria-label="help" style={{ background: '#f4f5f7' }}>
          {openPopover ? (
            <img
              src={FaleConoscoIconSelect}
              alt="Fale Conosco"
              onClick={handleClickPopover}
            />
          ) : (
            <img
              src={FaleConoscoIcon}
              alt="Fale Conosco"
              onClick={handleClickPopover}
            />
          )}
        </IconButton>

        <IconButton
          disabled
          aria-label="help"
          style={{ background: '#f4f5f7' }}
        >
          <img src={ProfileIcon} alt="User" />
        </IconButton>
        <ProfileName>{nome}</ProfileName>
      </ContainerProfile>
    </Container>
  );
}
