import { api } from './api';

export async function sendFile({
  file,
  codPessoa,
  numPedido,
  tag,
  numSeq,
  tipoPessoa,
  onError,
  onSuccess,
}) {
  try {
    const options = {
      method: 'POST',
      url: '/files/enviaracb/anexo',
      data: {
        file,
        numPedido,
        codPessoa,
        numIdTipoDocumentacao: tag,
        numSeqDoctoEntregue: numSeq,
        tipoPessoa,
      },
    };

    const { data } = await api.request(options);

    if (onSuccess) onSuccess(data);

    return data;
  } catch (error) {
    if (onError) onError(error);
  }
}

// export async function sendFileToIdProspect({
//   file,
//   codPessoa,
//   numPedido,
//   tag,
//   numSeq,
//   onError,
//   onSuccess,
// }) {
//   try {
//     const options = {
//       method: 'POST',
//       url: '/files/enviaracb/prospect',
//       data: {
//         file,
//         numPedido,
//         codPessoa,
//         numIdTipoDocumentacao: tag,
//         numSeqDoctoEntregue: numSeq,
//       },
//     };

//     const { data } = await api.request(options);

//     if (onSuccess) onSuccess();

//     return data;
//   } catch (error) {
//     if (onError) onError();
//   }
// }

// export async function sendFileToIdDependente({
//   file,
//   codPessoa,
//   numPedido,
//   tag,
//   numSeq,
//   onError,
//   onSuccess,
// }) {
//   try {
//     const options = {
//       method: 'POST',
//       url: '/files/enviaracb/vinculado',
//       data: {
//         file,
//         numPedido,
//         codPessoa,
//         numIdTipoDocumentacao: tag,
//         numSeqDoctoEntregue: numSeq,
//       },
//     };

//     const { data } = await api.request(options);

//     if (onSuccess) onSuccess();

//     return data;
//   } catch (error) {
//     if (onError) onError();
//   }
// }

export async function deleteFile(numPedido, numSeq, codTipo) {
  const options = {
    method: 'DELETE',
    url: `files/anexo/${numPedido}/${numSeq}/${codTipo}`,
  };

  const { data } = await api.request(options);

  return data;
}

/* export async function deleteFileFromIdDependente(id) {
  const options = {
    method: 'DELETE',
    url: `/files/deleteFull/${id}`,
    data: {
      id,
    },
  };

  const { data } = await api.request(options);

  return data;
} */

export async function findFilesByNumPedido(numPedido) {
  const options = {
    method: 'GET',
    url: `/files/documentosEntregues/${numPedido}`,
  };

  const { data } = await api.request(options);

  return data;
}

/* export async function findFilesByDepId(id) {
  const options = {
    method: 'GET',
    url: `/files/findbydep/${id}`,
    data: {
      id,
    },
  };

  const { data } = await api.request(options);

  return data;
} */

/* export async function envioAcbProspect(id) {
  const { data } = await api.get(`/files/enviaracb/prospect/${id}`);
  return data;
}

export async function envioAcbVinculados(id) {
  const { data } = await api.get(`/files/enviaracb/vinculados/${id}`);
  return data;
} */

/* export async function atualizarFileDependente(idDep, idFile) {
  const { data } = await api.put(`/files/update/dependente/${idDep}/${idFile}`);
  return data;
} */
