export const steps = [
  {
    index: 0,
    numero: 1,
    page: 'formulario',
    text: 'Informações pessoais',
  },
  {
    index: 1,
    numero: 2,
    page: 'vinculados',
    text: 'Vinculados',
  },
  {
    index: 2,
    numero: 3,
    page: 'contribuicao',
    text: 'Contribuição',
  },
  {
    index: 3,
    numero: 4,
    page: 'confirmacao',
    text: 'Status e confirmação',
  },
];
