import { useEffect } from 'react';
// import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useQueryRouter } from 'hook/useQueryRouter';
import { useResponsive } from 'hook/useResponsive';
// import { getAccessToken } from 'services/sensediaServices';

import { ValidacaoEmail } from 'pages/ValidacaoEmail';

import { Button } from 'components/Button';
import { Login } from 'components/Login';
import { ModalRedefinirSenha } from 'components/ModalSenha';

import { useModal } from 'context/Modal';
import { useToast } from 'context/Toast';

import {
  Background,
  ContainerLeft,
  LogoImage,
  Separator,
  Cabecalho,
  Titulo,
  Descricao,
  ContainerRight,
  TituloRightContainer,
  ContainerTopMobile,
  DescricaoRodape,
} from './styles';

export function Home() {
  const { mobileLayout } = useResponsive();
  const history = useHistory();

  const {
    setShowModalRedefinirSenha,
    showModalRedefinirSenha,
    openModalRedefinirSenha,
  } = useModal();
  const { toast } = useToast();

  const query = useQueryRouter();

  const confirmacao = query.has('confirmationToken');

  useEffect(() => {
    // getAccessToken();

    if (query.has('token')) {
      openModalRedefinirSenha();
    }

    if (query.has('tokenPortal')) {
      try {
        sessionStorage.setItem('@petros:portal_token', true);
      } catch (errro) {
        toast.error('Não é possivel autenticar no portal.');
      }
      history.go(0);
    }
  }, []);

  return confirmacao ? (
    <ValidacaoEmail />
  ) : (
    <>
      {!mobileLayout && (
        <Background>
          <ContainerLeft>
            <LogoImage alt="Logo Petros" src="./logo.png" />
            <Separator />
            <Cabecalho>
              <Titulo>Saiba mais sobre o FlexPrev</Titulo>
              <Descricao>
                Plano de previdência complementar da Vibra Energia, o FlexPrev é
                administrado pela Petros. É um benefício oferecido pela sua
                empresa para que você possa, desde já, se organizar
                financeiramente para o futuro. Cada real que você deposita de
                contribuição básica mensal é acompanhado pela Vibra. Além disso,
                para aumentar seu saldo, você pode fazer contribuições
                voluntárias e portar para o FlexPrev os recursos depositados em
                outro plano de previdência.
              </Descricao>
            </Cabecalho>
          </ContainerLeft>
          <ContainerRight>
            <TituloRightContainer>
              Pedido de inscrição FlexPrev
            </TituloRightContainer>
            <Login />
          </ContainerRight>
        </Background>
      )}

      {mobileLayout && (
        <Background mobile>
          <ContainerTopMobile>
            <LogoImage mobile alt="Logo Petros" src="./logo.png" />
            <Separator mobile />
            <Cabecalho mobile>
              <Titulo mobile>Saiba mais sobre o FlexPrev</Titulo>
              <Descricao mobile>
                Plano de previdência complementar da Vibra Energia, o FlexPrev é
                administrado pela Petros. É um benefício oferecido pela sua
                empresa para que você possa, desde já, se organizar
                financeiramente para o futuro. Cada real que você deposita de
                contribuição básica mensal é acompanhado pela Vibra. Além disso,
                para aumentar seu saldo, você pode fazer contribuições
                esporádicas e portar para o FlexPrev os recursos depositados em
                outro plano de previdência.
              </Descricao>
              <DescricaoRodape>
                Já é participante de outro plano Petros e deseja mudar?
              </DescricaoRodape>

              <Button mobile>Clique aqui</Button>
            </Cabecalho>
          </ContainerTopMobile>
          <Login />
        </Background>
      )}

      <ModalRedefinirSenha
        showModal={showModalRedefinirSenha}
        setShowModal={setShowModalRedefinirSenha}
      />
    </>
  );
}
