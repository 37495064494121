import { createContext, useContext, useState } from 'react';

import { ModalPatrocinadora } from 'components/ModalPatrocinadora';

export const ModalPatrocinadoraContext = createContext();

export function ModalPatrocinadoraProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [prospect, setProspect] = useState();
  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };

  return (
    <ModalPatrocinadoraContext.Provider
      value={{ isOpen, open, close, prospect, setProspect }}
    >
      {children}
      <ModalPatrocinadora />
    </ModalPatrocinadoraContext.Provider>
  );
}

export function useModalPatrocinadora() {
  const context = useContext(ModalPatrocinadoraContext);
  if (!context) {
    throw new Error(
      'useModalPatrocinadora must be used within a ModalProvider'
    );
  }
  return context;
}
