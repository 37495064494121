import { useState } from 'react';

export function useVinculadoFormulario() {
  const [nome, setNome] = useState('');
  const [idNumSeq, setIdNumSeq] = useState(0);

  const [dataNascimento, setDataNascimento] = useState(null);
  const [idade, setIdade] = useState();
  const [grauParentesco, setGrauParentesco] = useState('');
  const [cpf, setCpf] = useState('');
  const [sexo, setSexo] = useState('');
  const [invalidez, setInvalidez] = useState(false);
  // BUG_FIX: datas de invalidez e nascimento iniciadas com a data de hoje
  // Author: Lemuel
  const [dataInvalidez, setDataInvalidez] = useState(null);

  // Errors
  const [errorNome, setErrorNome] = useState(false);
  const [errorDataNascimento, setErrorDataNascimento] = useState(false);
  const [errorDataNascimetnoFilho, setErrorDataNascimentoFilho] =
    useState(false);
  const [errorGrauParentesco, setErrorGrauParentesco] = useState(false);
  const [errorCpf, setErrorCpf] = useState(false);
  const [errorSexo, setErrorSexo] = useState(false);
  const [errorInvalidez, setErrorInvalidez] = useState(false);
  const [errorDataInvalidez, setErrorDataInvalidez] = useState(false);
  const [errorPorcentagemVinculado, setErrorPorcentagemVinculado] =
    useState(false);

  return {
    // States
    nome,
    setNome,
    idNumSeq,
    setIdNumSeq,
    dataNascimento,
    setDataNascimento,
    idade,
    setIdade,
    grauParentesco,
    setGrauParentesco,
    cpf,
    setCpf,
    sexo,
    setSexo,
    invalidez,
    setInvalidez,
    dataInvalidez,
    setDataInvalidez,
    // Erros
    errorNome,
    setErrorNome,
    errorDataNascimento,
    setErrorDataNascimento,
    errorGrauParentesco,
    setErrorGrauParentesco,
    errorCpf,
    setErrorCpf,
    errorSexo,
    setErrorSexo,
    errorInvalidez,
    setErrorInvalidez,
    errorDataInvalidez,
    setErrorDataInvalidez,
    errorPorcentagemVinculado,
    setErrorPorcentagemVinculado,
    errorDataNascimetnoFilho,
    setErrorDataNascimentoFilho,
  };
}
