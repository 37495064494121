import { auth } from './api';

export function getGrantCode() {
  return auth.post('/grant-code', {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: 'http://localhost/',
  });
}

export function getAccessToken(grantCodeResponse) {
  return auth.post(
    '/access-token',
    {
      grant_type: 'client_credentials',
      code: grantCodeResponse.data.redirect_uri.replace(
        'http://localhost/?code=',
        ''
      ),
    },
    {
      auth: {
        username: process.env.REACT_APP_CLIENT_ID,
        password: process.env.REACT_APP_CLIENT_SECRET,
      },
    }
  );
}

export function getBearerToken() {
  const params = new URLSearchParams();
  params.append('grant_type', 'client_credentials');

  return auth.post('/v2/access-token', params, {
    auth: {
      username: process.env.REACT_APP_CLIENT_ID,
      password: process.env.REACT_APP_CLIENT_SECRET,
    },
  });
}
