import { useState, createContext, useContext } from 'react';

import { steps } from 'components/Steps/item';

import { useToast } from './Toast';

const StepsContext = createContext();

export function StepsProvider({ children }) {
  const { toast } = useToast();
  const [activeStep, setActiveStep] = useState(0);
  const [formStep, setFormStep] = useState(0);
  const [page, setPage] = useState('confirmacao');

  const openPageStatus = (page) => {
    setPage(page);
  };

  const completeFormStep = () => {
    setFormStep(formStep + 1);
  };

  const selectStep = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setPage(steps[activeStep - 1].page);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setPage(steps[activeStep + 1].page);
  };

  const isCompletedSteps = (step) => {
    if (step < activeStep) {
      return true;
    }

    return false;
  };

  const handleAprovado = () => {
    setActiveStep(4);
  };

  const selecionarTributacao = () => {
    toast.error('Por favor, selecione uma opção.', { id: 'tributacao' });
  };

  return (
    <StepsContext.Provider
      value={{
        activeStep,
        setActiveStep,
        selectStep,
        formStep,
        setFormStep,
        completeFormStep,
        handleBack,
        handleNext,
        selecionarTributacao,
        isCompletedSteps,
        page,
        setPage,
        openPageStatus,
        handleAprovado,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
}

export function useSteps() {
  const context = useContext(StepsContext);
  if (!context) {
    throw new Error('useSteps must be used within a StepsProvider');
  }
  return context;
}
