import { useEffect } from 'react';
import { buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

import { InputAdornment } from '@mui/material';

import { useResponsive } from 'hook/useResponsive';

import { Button } from 'components/Button';
import { ModalContribuicao } from 'components/ModalContribuicao';

// eslint-disable-next-line import-helpers/order-imports
import { useContribuicao } from 'context/Contribuicao';

// import checkMarkLock from 'assets/images/checkmark_lock.svg';

import { useModal } from 'context/Modal';

import checkIcon from 'assets/images/check.png';

import {
  Container,
  TitleContainer,
  StyledTextField,
  StyledSlider,
  StyledThumb,
  StyledThumbDisabled,
  StyledTrack,
  ContainerMid,
  ContainerLeft,
  ContainerCircularProgressBar,
  CircularProgressbarText,
  ImageProfile,
  StyledCircular,
  ContainerCard,
  CardMobile,
  ContribuicaoParticipante,
  ContainerThumb,
  StyledThumbDisabledText,
  PorcentagemText,
  DinheiroText,
  ContainerSlider,
  DescricaoText,
  Footer,
  FooterText,
  ContribuicaoBasicaTitle,
} from './styles';

const defaultValue = (state, limitePorcentagem = 0) => {
  const valor = state.valueNow;
  if (state.valueNow == state.value[1]) {
    if (state.value[0] < limitePorcentagem) {
      return state.value[0];
    }

    return state.valueNow - 5;
  }

  return valor;
};

function Thumb({ props, state, limitePorcentagem }) {
  return (
    <StyledThumb {...props}>
      {defaultValue(state, limitePorcentagem)}%
    </StyledThumb>
  );
}

function Track(props, state) {
  return <StyledTrack {...props} index={state.index} voluntaria />;
}

export function ContribuicaoMobile() {
  const { mobileLayout } = useResponsive();

  const {
    salario,
    setSalario,
    porcentagem1,
    porcentagem2,
    calcularPorcentagem,
    handleChangePorcentagem1,
    handleChangePorcentagem2,
    limitePorcentagem2,
    calcularSalario,
    handleOpenModalOuProximaTela,
    handleLoadContribuicao,
    disabled,
    disabledAll,
    naoDesabilita,
    temSalario,
  } = useContribuicao();

  const { showModalContribuicao, setShowModalContribuicao } = useModal();

  useEffect(() => {
    handleLoadContribuicao();
  }, []);

  return (
    <>
      {mobileLayout && (
        <Container mobile>
          <TitleContainer mobile>
            <h2>Adicione seu salario e contribuição</h2>
          </TitleContainer>

          <ContainerMid mobile>
            <ContainerLeft mobile>
              <div style={{ display: 'flex' }}>
                <StyledTextField
                  id="outlined-basic"
                  label="Salário"
                  variant="outlined"
                  type="text"
                  value={salario}
                  required
                  disabled={
                    temSalario ||
                    (naoDesabilita == false ? false : disabled) ||
                    disabledAll
                  }
                  currencySymbol="R$"
                  outputFormat="string"
                  onChange={(event, value) => setSalario(value)}
                  maximumValue={100000000}
                  decimalCharacter=","
                  digitGroupSeparator="."
                  minimumValue={0}
                  InputProps={{
                    endAdornment: (
                      <>
                        {(disabled || disabledAll) && (
                          <InputAdornment
                            style={{ marginTop: '5px' }}
                            position="end"
                          >
                            <ImageProfile src={checkIcon} />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
              </div>
              <ContainerCircularProgressBar mobile>
                <StyledCircular
                  maxValue={25}
                  styles={buildStyles({
                    pathColor: '#04cfbf',
                    textColor: '#4f4f4f',
                    trailColor: '#f2f2f2',
                  })}
                  value={parseInt(porcentagem1) + parseInt(porcentagem2)}
                  text={`${
                    Number.isNaN(
                      parseInt(porcentagem1) + parseInt(porcentagem2)
                    )
                      ? 0
                      : parseInt(porcentagem1) + parseInt(porcentagem2)
                  }%`}
                >
                  <CircularProgressbarText>
                    Contribuição total individual
                  </CircularProgressbarText>
                </StyledCircular>
              </ContainerCircularProgressBar>
            </ContainerLeft>

            <ContainerCard mobile>
              <CardMobile>
                <PorcentagemText>{porcentagem1}%</PorcentagemText>
                <DinheiroText>
                  {porcentagem1 == 0
                    ? 'R$ 0,00'
                    : calcularSalario(porcentagem1).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                </DinheiroText>
                <DescricaoText>Contrubuição básica</DescricaoText>
              </CardMobile>
              <CardMobile>
                <PorcentagemText>{porcentagem2}%</PorcentagemText>
                <DinheiroText>
                  {porcentagem2 == 0
                    ? 'R$ 0,00'
                    : calcularSalario(porcentagem2).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                </DinheiroText>
                <DescricaoText>Contrubuição da patrocinadora</DescricaoText>
              </CardMobile>
              <CardMobile>
                <PorcentagemText>{porcentagem2}%</PorcentagemText>
                <DinheiroText>
                  {porcentagem2 == 0
                    ? 'R$ 0,00'
                    : calcularSalario(porcentagem2).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                </DinheiroText>
                <DescricaoText>Contrubuição voluntária</DescricaoText>
              </CardMobile>
            </ContainerCard>

            <ContribuicaoParticipante mobile>
              <ContribuicaoBasicaTitle>
                Contribuição básica
              </ContribuicaoBasicaTitle>
              <p>
                É feita mensalmente por você e acompanhada pela Vibra, que
                depositará, todo mês, o mesmo percentual em seu nome. Assim, seu
                investimento dobra de imediato. O percentual disponível varia de
                acordo com a faixa salarial: de 1% a 5% ou de 1% a 11%.
              </p>

              <ContainerThumb>
                <StyledThumbDisabled>
                  <StyledThumbDisabledText>
                    {calcularPorcentagem(salario)}%
                  </StyledThumbDisabledText>
                </StyledThumbDisabled>
              </ContainerThumb>

              <ContainerSlider>
                <StyledSlider
                  value={porcentagem1}
                  max={calcularPorcentagem(salario)}
                  disabled={salario == null || disabledAll}
                  renderTrack={Track}
                  renderThumb={(props, state) => (
                    <Thumb
                      props={props}
                      state={state}
                      limitePorcentagem={calcularPorcentagem(salario)}
                    />
                  )}
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  onChange={(porcentagem1) => {
                    handleChangePorcentagem1(porcentagem1);
                  }}
                  ariaLabel={['Lower thumb', 'Upper thumb']}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  ariaLabelledby={['first-slider-label', 'second-slider-label']}
                  snapDragDisabled
                />
              </ContainerSlider>
            </ContribuicaoParticipante>
            <ContribuicaoParticipante mobile>
              <ContribuicaoBasicaTitle>
                Contribuição voluntária
              </ContribuicaoBasicaTitle>

              <p>
                Se você optar pelo percentual máximo da contribuição básica
                também poderá fazer contribuições mensais voluntárias, sem a
                contrapartida da Vibra, para elevar sua conta individual e o
                benefício futuro. A contribuição voluntária é opcional.
              </p>

              <ContainerThumb>
                <StyledThumbDisabled>
                  <StyledThumbDisabledText>25%</StyledThumbDisabledText>
                </StyledThumbDisabled>
              </ContainerThumb>

              {(calcularPorcentagem(salario) && porcentagem1 == 5) ||
              porcentagem1 == 11 ? (
                <ContainerSlider>
                  <StyledSlider
                    value={porcentagem2}
                    max={25}
                    disabled={salario == null || disabledAll}
                    renderTrack={Track}
                    renderThumb={(props, state) => (
                      <Thumb
                        props={props}
                        state={state}
                        limitePorcentagem={calcularPorcentagem(salario)}
                      />
                    )}
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    onChange={(porcentagem2) => {
                      handleChangePorcentagem2(porcentagem2);
                    }}
                    ariaLabel={['Lower thumb', 'Upper thumb']}
                    ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                    ariaLabelledby={[
                      'first-slider-label',
                      'second-slider-label',
                    ]}
                    snapDragDisabled
                  />
                </ContainerSlider>
              ) : (
                <ContainerSlider>
                  <StyledSlider
                    value={porcentagem2}
                    max={limitePorcentagem2()}
                    disabled
                    renderTrack={Track}
                    renderThumb={(props, state) => (
                      <Thumb
                        props={props}
                        state={state}
                        limitePorcentagem={calcularPorcentagem(salario)}
                      />
                    )}
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    onChange={(porcentagem2) => {
                      handleChangePorcentagem2(porcentagem2);
                    }}
                    ariaLabel={['Lower thumb', 'Upper thumb']}
                    ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                    ariaLabelledby={[
                      'first-slider-label',
                      'second-slider-label',
                    ]}
                    snapDragDisabled
                  />
                </ContainerSlider>
              )}
            </ContribuicaoParticipante>
          </ContainerMid>
          <Footer mobile>
            <FooterText mobile secondary>
              <p>
                <b>Importante:</b> todas as contribuições para previdência
                complementar podem ser deduzidas do Imposto de Renda até o
                limite de 12% do total dos rendimentos brutos tributáveis anuais
                por quem utiliza o modelo completo.
              </p>

              <Button
                normal
                onClick={() => {
                  handleOpenModalOuProximaTela();
                }}
              >
                Avançar
              </Button>
            </FooterText>
          </Footer>
        </Container>
      )}

      <ModalContribuicao
        showModal={showModalContribuicao}
        setShowModal={setShowModalContribuicao}
      />
    </>
  );
}
