import axios from 'axios';

export const auth = axios.create({
  baseURL: process.env.REACT_APP_URL_AUTH,
});

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    client_id: process.env.REACT_APP_CLIENT_ID,
  },
});

export const apiResetAuth = axios.create({
  baseURL: process.env.REACT_APP_RESET_AUTH,
});

export const apiReset = axios.create({
  baseURL: process.env.REACT_APP_RESET_URL,
  headers: {
    client_id: process.env.REACT_APP_CLIENT_ID,
  },
});
