import Files from 'react-files';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 172px;
  flex-direction: column;

  border: 2px dashed #c4c4c4;
  border-radius: 27px;
`;

export const StyledFiles = styled(Files)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #828282;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Image = styled.img`
  margin-top: 10px;
`;

export const Description = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  color: #4f4f4f;
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: underline;
`;

export const ContainerButtonDocs = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  align-items: center;
  height: 9rem;
  justify-content: space-around;
`;
