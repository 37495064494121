import { api, apiReset } from './api';
// import { getAccessToken } from './sensediaServices';

// const resetAuth = process.env.REACT_APP_RESET_AUTH;

export async function getCienciaPatrocinadora(numPedido) {
  const { data } = await api.get(`/ciencia-patrocinadora/${numPedido}`);
  return data;
}

export async function consultarStatusBpm(cpf) {
  const { data } = await api.get(`/status/bpm/${cpf}`);
  return data;
}

export async function getPedidos(numPedido) {
  const { data } = await api.get(`/pedidos/${numPedido}`);
  return data;
}

export const gerarSenha = async (matricula, cpf, dtNascimento) => {
  // await getAccessToken(resetAuth, apiReset);
  const { data } = await apiReset.get(
    `/${matricula}/recupera_senha?cpf=${cpf}&dtNasc=${dtNascimento}`
  );
  return data;
};
