import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 80vh;
  flex-direction: column;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 95%;
  margin-top: 1rem;
  margin-left: 1rem;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 1rem;
      margin-left: 0rem;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `}
`;

export const ProtocoloContainer = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #00887d;
    margin-left: 1rem;
  }
`;

export const NumeroProtocolo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  width: 218px;
  height: 40px;
  margin-top: -0.75rem;

  background: #f2f2f2;
  border-radius: 27px;

  h2 {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.3em;
  }
`;

export const StatusBarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 68px;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#00887D',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: '#00887D',
  },
}));

export const StatusContainer = styled.div`
  display: flex;
  width: 90%;
  margin-top: 10px;
  justify-content: space-around;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;
    `}
`;

export const StatusItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 255px;
  height: 114px;
  align-items: center;
  justify-content: space-around;

  ${(props) =>
    props.mobile &&
    css`
      width: 100%;

      p {
        text-align: center;
        font-size: 17px;
      }
    `}
`;

export const StatusAtencaoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ImageWarning = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const StatusListWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const List = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;

  p {
    color: #828282;
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 90%;
    `}
`;

export const AtencaoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #828282;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 20px;
`;
