import { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from '@mui/material';

import useKeyLock from 'hook/useKeyLock';

export function PasswordInput({
  name,
  error,
  label,
  onFocus,
  onBlur,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { toggled } = useKeyLock('CapsLock');

  const handleFocus = (event) => {
    setShowTooltip(true);
    if (onFocus) onFocus(event);
  };

  const handleBlur = (event) => {
    setShowTooltip(false);
    if (onBlur) onBlur(event);
  };

  return (
    <FormControl
      error={Boolean(error)}
      fullWidth
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={showTooltip && toggled}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Caps Lock está ativado"
        placement="bottom-start"
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'yellow',
              color: 'black',
              transform: 'translateY(-100px)',
              marginLeft: '0px',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
            },
          },
        }}
      >
        <OutlinedInput
          {...rest}
          id={name}
          name={name}
          variant="outlined"
          label={label}
          type={showPassword ? 'text' : 'password'}
          aria-describedby="error-text"
          placeholder={error}
          style={{ borderRadius: '10px' }}
          inputProps={{ maxLength: 32 }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          error={Boolean(error)}
        />
      </Tooltip>
      <FormHelperText id="error-text">{error}</FormHelperText>
    </FormControl>
  );
}
