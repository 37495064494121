import { api } from './api';

export async function pathCadastro(body) {
  const response = await api.put('/users/pre-cadastro', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}
