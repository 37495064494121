import { Popover } from '@mui/material';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 8%;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 8%;
`;

export const ContainerProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20%;
`;

export const ProfileName = styled.div`
  font-size: 18px;
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    width: 230px;
    height: 236px;

    background: #ffffff;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
  }

  h3 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #000000;
  }

  p {
  }
`;

export const TextContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  text-align: start;
  flex-direction: column;
  justify-content: flex-start;
  color: #828282;

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;

    color: #000000;
  }

  b {
    color: #000000;
  }
`;
