import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useQueryRouter } from 'hook/useQueryRouter';
import { validarPrimeiroAcesso } from 'services/loginServices';

export function ValidacaoEmail() {
  const query = useQueryRouter();

  useEffect(() => {
    if (query.has('confirmationToken')) {
      setTimeout(() => {
        validarPrimeiroAcesso(query.get('confirmationToken'));
      }, 500);
    }
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Email confirmado!</h1>
      <p>
        <Link to="/">Clique aqui</Link> para voltar ao sistema.
      </p>
    </div>
  );
}
