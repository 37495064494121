import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 45em;
`;

export const LogoImage = styled.img`
  margin-right: auto;
  margin-left: auto;
  max-width: 116px;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  text-align: start;
  flex-direction: column;
  justify-content: flex-start;

  p {
    font-size: 18px;
  }
`;

export const Description = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  color: #4f4f4f;
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: underline;
`;

export const CarregarDocumentosButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  input {
    width: 100%;
    background: #f5f8fa;
    border: 1px solid #d3e2e5;
    border-radius: 20px;
    outline: none;
    color: #5c8599;
  }

  input[type='file'] {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 38px;
  object-fit: cover;
  border: 1px solid #00887d;
  box-sizing: border-box;
  border-radius: 27px;
`;

export const LabelImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export const ImagePreview = styled.img`
  width: 85%;
  height: 20%;
  border-radius: 27px;
  object-fit: cover;
  position: absolute;
`;

export const Footer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  margin-left: 15px;
  margin-right: 15px;
`;
