import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 15em;
`;

export const LogoImage = styled.img`
  margin-right: auto;
  margin-left: auto;
  max-width: 220px;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  text-align: start;
  flex-direction: column;
  justify-content: flex-start;
  color: #828282;

  p {
    font-size: 16px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  b {
    color: #828282;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  margin-left: 15px;
  margin-right: 15px;
`;
