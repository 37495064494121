import { useState, createContext, useContext } from 'react';

import {
  getProspects,
  importProspect,
  deleteProspect,
} from 'services/prospectServices';
import { getUsers, putDeferir, putIndeferir } from 'services/usuariosServices';

import { useModal } from './Modal';
import { useToast } from './Toast';

const Prospect = createContext();

export function ProspectProvider({ children }) {
  const { toast } = useToast();
  const { openModalAguardar, closeModalAguardar, setModalAguardarProgress } =
    useModal();
  const [listaProspect, setListaProspect] = useState([]);
  const [openEdit, setOpenEdit] = useState();
  const [selected, setSelected] = useState(null);
  const [edit, setEdit] = useState(false);
  const [matriculas, setMatriculas] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [matriculasUsuarios, setMatriculasUsuarios] = useState([]);
  const [atualizaLista, setAtualizaLista] = useState(false);
  const [loading, setLoading] = useState(false);

  const getListaProspects = async () => {
    toast.loading('Carregando tabela', { id: 'tabela' });
    const prospects = await getProspects();
    toast.success('Tabela carregada com sucesso', { id: 'tabela' });

    setListaProspect(prospects || []);
    setMatriculas(prospects.map((prospect) => prospect.numMatricula));
  };

  const handleEdit = (data) => {
    setSelected(data);
    setEdit(true);
    setOpenEdit(true);
  };

  const handleDelete = async (data) => {
    await deleteProspect(data);
    toast.success('Prospect deletado com sucesso', { id: 'tabela' });

    const prospects = await getProspects();
    toast.success('Tabela carregada com sucesso', { id: 'tabela' });

    setListaProspect(prospects || []);
    setMatriculas(prospects.map((prospect) => prospect.numMatricula));
  };

  const setOpenModal = () => {
    setOpenEdit(true);
  };

  const handleCloseModal = () => {
    setOpenEdit(false);
    window.location.replace('/');
  };

  const onFileChangeProspectos = async (file) => {
    toast.loading('Importando Prospect...', { id: 'tabela' });
    try {
      const formData = new FormData();
      formData.append('file', file[0]);
      const d = await importProspect(formData);
      // const d = await importProspect(undefined);

      console.log(d);
      toast.success('Prospect importado com sucesso!', { id: 'tabela' });
    } catch (error) {
      toast.error(error.message, { id: 'tabela' });
    }
  };

  const getAllUsuarios = async () => {
    toast.loading('Carregando tabela', { id: 'tabela' });
    const allUsers = await getUsers();
    toast.success('Tabela carregada com sucesso', { id: 'tabela' });

    setListaUsuarios(allUsers);
    setMatriculasUsuarios(allUsers.map((user) => user.numMatricula));
  };

  const carregarLista = async () => {
    setAtualizaLista(true);
    const allUsers = await getUsers();
    setListaUsuarios(allUsers);
    setMatriculasUsuarios(allUsers.map((user) => user.numMatricula));
    setAtualizaLista(false);
  };

  const deferirUsuario = async (data) => {
    setLoading(true);
    const loading = toast.loading('Deferindo usuário');
    try {
      await putDeferir(data.numPedido);
      toast.success('Usuário deferido com sucesso');
      carregarLista();
    } catch (error) {
      if (error.response.status === 504) {
        openModalAguardar();
        const timer = setInterval(() => {
          setModalAguardarProgress((prev) => {
            if (prev > 0) {
              return prev - 1;
            }
            clearInterval(timer);
            closeModalAguardar();
            carregarLista();
            return 60;
          });
        }, 1000);
      } else {
        toast.error('Erro ao deferir usuário');
      }
    } finally {
      toast.dismiss(loading);
      setLoading(false);
    }
  };

  const indeferirUsuario = async (data) => {
    setLoading(true);
    const loadingToast = toast.loading('Indeferindo usuário');
    try {
      await putIndeferir(data.numPedido);
      toast.success('Usuário indeferido com sucesso');
      carregarLista();
    } catch (error) {
      toast.error('Erro ao indeferir');
    } finally {
      toast.dismiss(loadingToast);
      setLoading(false);
    }
  };

  return (
    <Prospect.Provider
      value={{
        listaProspect,
        setListaProspect,
        openEdit,
        setOpenEdit,
        selected,
        setSelected,
        edit,
        setEdit,
        matriculas,
        setMatriculas,
        getListaProspects,
        handleEdit,
        handleDelete,
        setOpenModal,
        handleCloseModal,
        onFileChangeProspectos,
        // Table Usuarios
        listaUsuarios,
        setListaUsuarios,
        matriculasUsuarios,
        setMatriculasUsuarios,
        getAllUsuarios,
        deferirUsuario,
        indeferirUsuario,
        atualizaLista,
        loading,
      }}
    >
      {children}
    </Prospect.Provider>
  );
}

export function useProspect() {
  const context = useContext(Prospect);
  if (!context) {
    throw new Error('useProspect must be used within a ProspectProvider');
  }
  return context;
}
