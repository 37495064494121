import { TableCell, Paper, TableHead } from '@mui/material';

import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  box-shadow: none;
  border: 1px solid #99999966;
  border-bottom: none;

  width: 100%;
`;

export const StyledTableHead = styled(TableHead)`
  background-color: #e5eef3;
  border-radius: 4px 4px 0 0;
`;

export const HeaderTableCell = styled(TableCell)`
  color: #808080;
  font-size: 16px;
`;

export const FixedTh = styled(HeaderTableCell)`
  width: 200px;
`;

export const SortableThText = styled.div`
  margin-right: 16px;
`;
