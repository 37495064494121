import { api } from './api';

export async function getEstados(codPais) {
  const { data } = await api.get(`/localidades/estados/${codPais}`);
  return data;
}

export async function getCidades(codUf) {
  const { data } = await api.get(`/localidades/cidades/${codUf}`);
  return data;
}
