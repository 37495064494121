/* eslint-disable no-restricted-syntax */
import { useEffect, useState, createContext, useContext } from 'react';

import {
  deleteFile,
  findFilesByNumPedido,
  sendFile,
  // findFilesByDepId,
} from 'services/files';

import { useAuth } from './Auth';
import { useToast } from './Toast';

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        name: file.name,
        file: reader.result,
        contentType: file.type,
      });
    };
    reader.onerror = (error) => reject(error);
  });

export function base64ToFile(base64, filename) {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const InfoPessoaisContext = createContext();

export function InfoPessoaisProvider({ children }) {
  const { numPedido, numIdPf } = useAuth();

  const { toast } = useToast();

  // Documentos Pessoais
  const [files, setFiles] = useState([]);

  // RG
  const [rg, setRg] = useState(false);

  // CPF
  const [cpf, setCpf] = useState(false);

  // Certidao de Nascimento
  const [certidaoNascimento, setCertidaoNascimento] = useState(false);

  // Certidão de Casamento
  // eslint-disable-next-line no-unused-vars
  const [certidaoCasamento, setCertidaoCasamento] = useState(false);

  // Documentos Vinculado
  const [filesVinculado, setFilesVinculado] = useState([]);

  // Pagina de Documento ou Formulário
  const [page, setPage] = useState('documento');

  const [numSeq, setNumSeq] = useState(0);

  const openPageFormularios = (page) => {
    setPage(page);
  };

  const submitFiles = async (files) => {
    const aux = [];

    try {
      // await deleteFilesByNumPedido(numPedido);
      for await (const file of files) {
        setNumSeq(numSeq + 1);
        toast.loading('Enviando...', {
          id: 'upload',
        });

        const base64File = await toBase64(file);

        await sendFile({
          file: base64File,
          codPessoa: numIdPf,
          numPedido,
          tag: file.tag,
          numSeq,
          tipoPessoa: 'PROSPECT',
          // eslint-disable-next-line no-loop-func
          onError: (error) => {
            let mensagem = `Erro ao enviar arquivo: ${file.name}`;
            if (error.response.status === 413) {
              mensagem = `Arquivo com ${Math.round(
                base64File.file.length / 1000
              )}KB excede o limite de 512KB`;
            }
            toast.error(mensagem, {
              id: file.name,
            });
          },
          onSuccess: (data) => {
            toast.success(`Arquivo enviado: ${file.name}`, { id: file.name });
            aux.push({
              name: data.nomeTratado,
              tag: file.tag,
              numSeq: data.novoNumSeqDoctoEntregue,
            });
          },
        });
      }
    } catch (error) {
      toast.error('Ops! Erro ao enviar as imagens!', { id: 'files-upload' });
    } finally {
      setFiles((previousFiles) => [...previousFiles, ...aux]);
      toast.dismiss('upload');
    }
  };

  const handleLoadFiles = async () => {
    const data = await findFilesByNumPedido(numPedido);

    const prospects = data.prospect;

    if (!prospects) return [];

    const newData = [];
    for await (const item of prospects) {
      newData.push({
        name: item.nomeArqFisico,
        tag: item.numIdTipoDocumentacao,
        numSeq: item.numSeqDoctoEntregue,
      });
    }
    setNumSeq(newData.length);
    setFiles(newData);

    return data;
  };

  const handleLoadFilesVinculados = async () => {
    /*
    const data = await findFilesByDepId(idDependent);

    if (!data) return [];

    const newData = [];
    for (const item of data) {
      newData.push(base64ToFile(item.data, item.name));
    }
    setFilesVinculado(newData);

    return data; */
  };

  useEffect(() => {
    if (numPedido) {
      handleLoadFiles();
    }
  }, []);

  const handleClickRg = () => {
    setRg(true);

    setCpf(false);
    setCertidaoNascimento(false);
    setCertidaoCasamento(false);
  };

  const handleClickCpf = () => {
    setCpf(true);

    setRg(false);
    setCertidaoNascimento(false);
    setCertidaoCasamento(false);
  };

  const handleClickCertidaoNascimento = () => {
    setCertidaoNascimento(true);

    setRg(false);
    setCpf(false);
    setCertidaoCasamento(false);
  };

  const handleClickCertidaoCasamento = () => {
    setCertidaoCasamento(true);

    setRg(false);
    setCpf(false);
    setCertidaoNascimento(false);
  };

  // BUG_FIX: inserindo arquivos deletados, quando tentando reenserir um arquivo já deletado previamente
  // Author: Lemuel
  const onFilesChange = async (_files = []) => {
    if (_files.length > 0) {
      _files = _files.filter(Boolean);
      const newFilesWithTag = _files.map((file) => {
        const extension = file.name.substr(file.name.length - 3, 3);
        if (extension !== 'pdf' && extension !== 'tif' && extension !== 'jpg') {
          toast.error(
            `Erro ao enviar arquivo: ${file.name}\nExtensão não suportada.`,
            { id: file.name }
          );
          return undefined;
        }
        if (file.name) file.tag = '52';
        if (rg == true) {
          setRg(false);
          file.tag = '51';
        }
        if (cpf == true) {
          setCpf(false);
          file.tag = '17';
        }
        if (certidaoNascimento == true) {
          setCertidaoNascimento(false);
          file.tag = '54';
        }
        return file;
      });

      submitFiles(newFilesWithTag);

      /* const newFiles =
        newFilesWithTag.length === 0
          ? newFilesWithTag
          : [].concat(files || [], newFilesWithTag[newFilesWithTag.length - 1]);
      setFiles(newFiles); */
    }
  };

  const onFilesError = (error, file) => {
    if (error.code === 1) {
      toast.error(`A extensão ${file.extension} não é suportada`);
    }
  };

  const removeFile = (numSeq, codTipo) => {
    deleteFile(numPedido, numSeq, codTipo);
    setFiles((files) => files.filter((f) => f.numSeq !== numSeq));
  };

  const removeFileVinculado = (numSeq, tag) => {
    removeFile(numPedido, numSeq, tag);
    const newFiles = filesVinculado.filter((f) => f.numSeq !== numSeq);
    setFilesVinculado(newFiles);
  };

  const handleTag = (codeTipo) => {
    codeTipo = Number(codeTipo);
    switch (codeTipo) {
      case 51:
        return 'RG';
      case 17:
        return 'CPF';
      case 54:
        return 'CN';
      default:
        return 'ETC';
    }
  };
  // BUG_FIX: inserindo arquivos deletados, quando tentando reenserir um arquivo já deletado previamente
  // Author: Lemuel
  const onFileChangeVinculado = (_files = []) => {
    const newFiles =
      _files.length === 0
        ? _files
        : [].concat(filesVinculado || [], _files[_files.length - 1]);
    setFilesVinculado(newFiles);
  };

  const onFilesErrorVinculado = (error, file) => {
    console.log(`error code ${error.code}: ${error.message}`);
    console.log('file: ', file);
  };

  return (
    <InfoPessoaisContext.Provider
      value={{
        handleTag,
        page,
        setPage,
        openPageFormularios,
        files,
        setFiles,
        onFilesChange,
        onFilesError,
        filesVinculado,
        toBase64,
        setFilesVinculado,
        submitFiles,
        onFileChangeVinculado,
        removeFileVinculado,
        onFilesErrorVinculado,
        removeFile,
        handleLoadFilesVinculados,
        handleClickRg,
        handleClickCpf,
        handleClickCertidaoNascimento,
        handleClickCertidaoCasamento,
      }}
    >
      {children}
    </InfoPessoaisContext.Provider>
  );
}

export function useInfoPessoais() {
  const context = useContext(InfoPessoaisContext);
  if (!context) {
    throw new Error(
      'useInfoPessoais must be used within a InfoPessoaisProvider'
    );
  }
  return context;
}
