import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;

  z-index: 10;

  width: 32px;
  height: 32px;
  border-radius: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
