import { createTheme } from '@mui/material';

// Create a theme object.
export const theme = createTheme({
  palette: {
    primary: {
      main: '#00887D',
    },
    secondary: {
      main: '#00294B',
    },
    error: {
      main: '#CC390A',
    },
    background: {
      default: '#FAFBFC',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
  },
  overrides: {
    MuiStepConnector: {
      line: {
        borderColor: '#99999933',
      },
      lineHorizontal: {
        borderTopWidth: '2px',
        borderRadius: 1,
      },
      alternativeLabel: {
        top: '30px',
        left: 'calc(-50% + 56px)',
        right: 'calc(50% + 56px)',
      },
      active: {
        '& $line': {
          borderColor: '#00887D',
        },
      },
      completed: {
        '& $line': {
          borderColor: '#00887D',
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: '#99999933',
        width: '60px',
        height: '60px',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '20px',
        color: '#585858',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#fafbfc',
        '&:hover': { backgroundColor: '#fafbfc' },
        '&$focused': { backgroundColor: '#fafbfc' },
      },
      underline: {
        '&::before': {
          borderBottom: '1px solid #707070',
        },
        '&::after': {
          borderBottom: '2px solid #00887D',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#4A4A4A',
        backgroundColor: '#fafbfc',
        '&$focused': {
          color: '#4A4A4A',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiSelect: {
      select: {
        backgroundColor: '#fafbfc',
        '&:focus': { backgroundColor: '#fafbfc' },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '20px',
        color: '#585858',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': { backgroundColor: '#E6F8FF' },
        '&:hover': { backgroundColor: 'rgba(0,153,209, 0.3)' },
        '&$selected:hover': { backgroundColor: 'rgba(0,153,209, 0.3)' },
      },
      button: {
        '&:hover': { backgroundColor: 'rgba(0,153,209, 0.3)' },
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: '#00887D',
      },
      transitionContainer: {
        fontSize: '18px',
        color: '#585858',
      },
      dayLabel: {
        fontSize: '18px',
        color: '#585858',
      },
    },
    MuiPickersDay: {
      day: {
        fontSize: '18px',
        color: '#585858',
        fontWeight: 'normal',
        '&$daySelected': {
          backgroundColor: '#00887D',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        color: '#585858',
        backgroundColor: '#fff',
        boxShadow: '0px 3px 6px #B2BAC599',
        fontSize: '14px',
      },
      arrow: {
        color: '#fff',
        filter: 'drop-shadow(-5px 3px 3px #B2BAC599)',
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: '0px',
      },
      label: {
        fontSize: '24px',
        color: '#444444',
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: '#FF0606',
      },
    },
    MuiLinearProgress: {
      root: {
        height: '22px',
        width: '100%',
        borderRadius: '22px',
      },
      colorPrimary: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #00887D',
      },
      barColorPrimary: {
        backgroundColor: '#00887D',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#00887D',
      },
      colorPrimary: '#00887D',
    },
  },
});
