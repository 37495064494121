import { useState, createContext, useContext } from 'react';

import { pathContribuicao } from 'services/contribuicaoServices';
// import { getTravarUser } from 'services/loginServices';
import { getOneUser } from 'services/usuariosServices';

import { useAuth } from './Auth';
import { useModal } from './Modal';
import { useSteps } from './Steps';
import { useToast } from './Toast';

const ContribuicaoContext = createContext();

export function ContribuicaoProvider({ children }) {
  const { toast } = useToast();
  const { openModalContribuicao, handleCloseModalContribuicao } = useModal();
  const { handleNext } = useSteps();
  const { travaCadastro } = useAuth();

  // BUG_FIX
  // 1 - N„o est· deixando imputar sal·rio quando o prospect n„o est· na base da patrocinadora (isso imposibilitou os demais testes nessa tela)
  // 2 - A conbribuiÁ„o total est· carregando com mensagem de erro "NaN%"
  // Author: Lemuel
  const [temSalario, setTemSalario] = useState(false);

  const [salario, setSalario] = useState(0);

  const [porcentagem1, setPorcentagem1] = useState(0);
  const [porcentagem2, setPorcentagem2] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const calcularPorcentagem = (salario) => {
    if (salario >= 0 && salario <= 6090.3) {
      return 5;
    }

    return 11;
  };

  const handleChangePorcentagem1 = (percent) => {
    const limitePorcentagem = calcularPorcentagem(salario);

    if (percent < limitePorcentagem) {
      setPorcentagem2(0);
    }

    if (percent > limitePorcentagem) {
      setPorcentagem1(limitePorcentagem);
      return;
    }

    setPorcentagem1(percent);
  };

  const handleChangePorcentagem2 = (percent) => {
    setPorcentagem2(percent);
  };

  const limitePorcentagem2 = () => {
    const limitePorcentagem = calcularPorcentagem(salario);

    return 25 - limitePorcentagem;
  };

  const calcularSalario = (porcentagem) => salario * (porcentagem / 100);

  const handleOpenModalOuProximaTela = () => {
    if (salario == '' || salario == null) {
      toast.error('O campo salário não foi preenchido.', {
        id: 'contribuicao',
      });
      return;
    }
    if (porcentagem1 <= 0) {
      toast.error('A contribuição deve ser de pelo menos 1%', {
        id: 'contribuicao',
      });
      return;
    }

    if (porcentagem2 == 25) {
      handleNext();
      atualizarContribuicao();
      handleCloseModalContribuicao();
    }

    if (porcentagem1 < 5 || porcentagem1 <= 11) {
      openModalContribuicao();
    } else {
      handleNext();
      atualizarContribuicao();
    }
  };

  const atualizarContribuicao = async () => {
    const { numPedido, numIdPf } = JSON.parse(
      sessionStorage.getItem('@user:dados')
    );

    if (!numPedido || !numIdPf) return;

    const body = {
      numPedido,
      numIdPf,
      contribuicaoBasica: porcentagem1,
      contribuicaoVoluntaria: porcentagem2,
      salario,
    };

    try {
      await pathContribuicao(body);
    } catch (error) {
      const multipleMessages = Array.isArray(error.response?.data.erros);
      if (multipleMessages) {
        error.response?.data.erros.forEach((e) => {
          toast.error(e);
        });
      } else if (error.isAxiosError) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleLoadContribuicao = async () => {
    setIsLoading(true);
    handleCloseModalContribuicao();
    const dados = sessionStorage.getItem('@user:dados');

    if (!dados) {
      toast.error('Dados não encontrados');
      setIsLoading(false);
      return;
    }

    const { numPedido, numIdPf } = JSON.parse(dados);

    if (!numPedido && !numIdPf) return setIsLoading(false);

    const { contribuicaoBasica, contribuicaoVoluntaria, salario } =
      await getOneUser(numPedido, numIdPf);

    // const { travarCadastro } = await getTravarUser(numPedido, numIdPf);

    // setTravaCadastro(travarCadastro);

    setPorcentagem1(contribuicaoBasica ?? 0);
    setPorcentagem2(contribuicaoVoluntaria ?? 0);
    setSalario(salario ?? 0);
    setTemSalario(!!salario);

    setIsLoading(false);
  };

  const verificarContribuicao = () => {
    if (temSalario || isLoading) {
      return true;
    }
    return false;
  };

  const isDisabledAll = () => {
    if (travaCadastro == 2 || isLoading) {
      return true;
    }
    return false;
  };

  const naoTrava = () => {
    if (travaCadastro == 0 || isLoading || travaCadastro == null) {
      return false;
    }
    return false;
  };

  return (
    <ContribuicaoContext.Provider
      value={{
        salario,
        setSalario,
        porcentagem1,
        porcentagem2,
        setPorcentagem1,
        setPorcentagem2,
        calcularPorcentagem,
        handleChangePorcentagem1,
        handleChangePorcentagem2,
        limitePorcentagem2,
        calcularSalario,
        handleOpenModalOuProximaTela,
        atualizarContribuicao,
        handleLoadContribuicao,
        disabled: verificarContribuicao(),
        disabledAll: isDisabledAll(),
        naoDesabilita: naoTrava(),
        temSalario,
      }}
    >
      {children}
    </ContribuicaoContext.Provider>
  );
}

export function useContribuicao() {
  const context = useContext(ContribuicaoContext);
  if (!context) {
    throw new Error(
      'useContribuicao must be used within a ContribuicaoProvider'
    );
  }
  return context;
}
