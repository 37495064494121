import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';

import { useResponsive } from 'hook/useResponsive';
import formataCpf from 'util/formatarCPF';
import formataTelefone from 'util/formatarTelefone';

import { Button } from 'components/Button';
import { ModalSenha, ModalSenhaSucesso } from 'components/ModalSenha';

import { useLogin } from 'context/Login';
import { useModal } from 'context/Modal';
import { usePrimeiroAcesso } from 'context/PrimeiroAcesso';

import {
  Wrapper,
  ContainerTop,
  ContainerMid,
  Footer,
  TermosDeUsoContainer,
  TermosDeUsoText,
  StyledTextFieldMobile,
} from './styles';

export function LoginMobile() {
  const { mobileLayout } = useResponsive();

  const { formik: formikLogin } = useLogin();

  const {
    trocarAcesso,
    isPrimeiroAcesso,
    linkPoliticaDePrivacidade,
    acessoMobile,
    formik,
  } = usePrimeiroAcesso();
  const {
    showModal,
    setShowModal,
    setShowModalSucesso,
    showModalSucesso,
    openModal,
  } = useModal();

  return (
    <>
      {mobileLayout && (
        <Wrapper
          mobile
          style={acessoMobile ? { marginTop: '20px' } : { marginTop: '-20em' }}
        >
          <ContainerTop mobile>
            <Button
              contained={isPrimeiroAcesso == 'primeiroAcesso'}
              outlined={!isPrimeiroAcesso != 'primeiroAcesso'}
              onClick={() => {
                trocarAcesso('primeiroAcesso');
              }}
            >
              Primeiro acesso?
            </Button>

            <Button
              contained={isPrimeiroAcesso == 'login'}
              outlined={isPrimeiroAcesso != 'login'}
              onClick={() => {
                trocarAcesso('login');
              }}
            >
              Já sou cadastrado
            </Button>
          </ContainerTop>

          {isPrimeiroAcesso == 'login' ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formikLogin.handleSubmit(e);
              }}
            >
              <ContainerMid mobile>
                <StyledTextFieldMobile
                  id="email"
                  name="email"
                  label="E-mail"
                  variant="outlined"
                  type="email"
                  value={formikLogin.values.email}
                  onChange={formikLogin.handleChange}
                  required
                  error={
                    Boolean(formikLogin.errors.email) &&
                    formikLogin.touched.email
                  }
                  placeholder={formikLogin.errors.email}
                />
                <StyledTextFieldMobile
                  style={{ marginTop: '32px' }}
                  id="senha"
                  name="senha"
                  label="Senha"
                  variant="outlined"
                  type="password"
                  value={formikLogin.values.senha}
                  onChange={formikLogin.handleChange}
                  autoComplete="current-password"
                  required
                  error={
                    Boolean(formikLogin.errors.senha) &&
                    formikLogin.touched.senha
                  }
                  placeholder={formikLogin.errors.senha}
                />
              </ContainerMid>

              <Footer mobile>
                <Button
                  none
                  onClick={() => {
                    openModal();
                  }}
                >
                  Esqueceu a senha?
                </Button>

                <Button normal type="submit">
                  Avançar
                </Button>
              </Footer>
            </form>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <ContainerMid mobile style={{ height: '20rem' }}>
                <StyledTextFieldMobile
                  style={{ marginTop: '16px' }}
                  id="nome"
                  name="nome"
                  label="Nome"
                  value={formik.values.nome}
                  onChange={formik.handleChange}
                  variant="outlined"
                  type="nome"
                  required
                  error={Boolean(formik.errors.nome) && formik.touched.nome}
                  placeholder={formik.errors.nome}
                />

                <StyledTextFieldMobile
                  style={{ marginTop: '16px' }}
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  variant="outlined"
                  type="tel"
                  value={formataCpf(formik.values.cpf)}
                  onChange={formik.handleChange}
                  required
                  inputProps={{
                    maxLength: 14,
                  }}
                  error={Boolean(formik.errors.cpf) && formik.touched.cpf}
                  placeholder={formik.errors.cpf}
                />

                <StyledTextFieldMobile
                  style={{ marginTop: '16px' }}
                  id="celular"
                  name="celular"
                  label="Celular"
                  variant="outlined"
                  type="tel"
                  required
                  value={formataTelefone(formik.values.celular)}
                  onChange={formik.handleChange}
                  inputProps={{
                    maxLength: 15,
                  }}
                  error={
                    Boolean(formik.errors.celular) && formik.touched.celular
                  }
                  placeholder={formik.errors.celular}
                />

                <StyledTextFieldMobile
                  style={{ marginTop: '16px' }}
                  id="email"
                  name="email"
                  label="E-mail pessoal"
                  variant="outlined"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  required
                  error={Boolean(formik.errors.email) && formik.touched.email}
                  placeholder={formik.errors.email}
                />

                <StyledTextFieldMobile
                  style={{ marginTop: '16px' }}
                  id="senha"
                  name="senha"
                  label="Senha"
                  variant="outlined"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={formik.values.senha}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.senha) && formik.touched.senha}
                  placeholder={formik.errors.senha}
                  helperText={formik.errors.senha}
                />
              </ContainerMid>
              <TermosDeUsoContainer mobile>
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  checked={formik.values.termos}
                  onChange={() =>
                    formik.setFieldValue('termos', !formik.values.termos)
                  }
                  color="primary"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <TermosDeUsoText mobile>
                  <p>
                    Ao clicar em "Avançar" e continuar com o seu cadastro, você
                    autoriza a Petros a coletar seus dados pessoais de acordo
                    com a nossa{' '}
                    <strong onClick={() => linkPoliticaDePrivacidade()}>
                      Política de Privacidade
                    </strong>
                    , com o objetivo de comunicar informações sobre o processo
                    de adesão.
                  </p>
                </TermosDeUsoText>
              </TermosDeUsoContainer>

              <Footer
                style={{
                  justifyContent: 'flex-end',
                  height: '8%',
                  width: '82%',
                }}
              >
                <Button normal type="submit">
                  Avançar
                </Button>
              </Footer>
            </form>
          )}
        </Wrapper>
      )}

      <ModalSenha showModal={showModal} setShowModal={setShowModal} />
      <ModalSenhaSucesso
        showModal={showModalSucesso}
        setShowModal={setShowModalSucesso}
      />
    </>
  );
}
