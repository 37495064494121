/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect } from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';
import Switch from 'react-switch';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import ptBR from 'date-fns/locale/pt-BR';
import { usePersonalInfoForm } from 'hook/usePersonalInfoForm';
import { useResponsive } from 'hook/useResponsive';
import { isCpf } from 'iscpf';
import formataCpf from 'util/formatarCPF';
import formatarNome from 'util/formatarNome';
import formataTelefone from 'util/formatarTelefone';
import isValidDate from 'util/isValidDate';

import { Button } from 'components/Button';
import { ModalPPE } from 'components/ModalPPE';

import { useInfoPessoais } from 'context/InfoPessoais';
import { useModal } from 'context/Modal';

import checkIcon from 'assets/images/check.png';
import checkMarkLock from 'assets/images/checkmark_lock.svg';
import docPreview from 'assets/images/doc.svg';

import { sexos } from './item';
import {
  Container,
  ContainerButton,
  ContainerDocumentos,
  ContainerDoubleTextField,
  ContainerForm,
  ContainerPessoaExposta,
  ContainerPessoaExpostaInputs,
  FileItem,
  FileList,
  ImageProfile,
  StyledTextFieldDoubleMobile,
  StyledTextFieldMobile,
  SwitchContainer,
  TermosDeUsoContainer,
  TermosDeUsoText,
  TitleContainer,
  WrapperForm,
} from './styles';

export function FormularioMobile() {
  const { files } = useInfoPessoais();
  const { mobileLayout } = useResponsive();

  const {
    formik,
    handleLoadFormulario,
    disabled_cpf,
    disabled_sexo,
    disabled_matricula,
    disabled_email,
    disabled_dtAdmissao,
    disabled_dtnasc,
    disabledAll,
    disabledForm,
    naoDesabilita,
  } = usePersonalInfoForm();

  const { showModalPPE, setShowModalPPE, openModalPPE } = useModal();

  useEffect(() => {
    handleLoadFormulario();
  }, []);

  return (
    <>
      {mobileLayout && (
        <Container mobile>
          <TitleContainer mobile>
            <h2>Confira seus dados</h2>
          </TitleContainer>
          <WrapperForm mobile>
            <ContainerForm mobile>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <StyledTextFieldMobile
                  style={{ marginTop: '16px' }}
                  id="outlined-basic"
                  name="nome"
                  label="Nome do vinculado"
                  variant="outlined"
                  type="nome"
                  disabled={disabledAll || disabledForm}
                  value={formatarNome(formik.values.nome) || ''}
                  onChange={formik.handleChange}
                  required
                  error={Boolean(formik.errors.nome) && formik.touched.nome}
                  placeholder={formik.errors.nome}
                  InputProps={{
                    endAdornment: (
                      <>
                        {formik.values.nome &&
                          formik.values.nome.length > 3 && (
                            <InputAdornment
                              style={{ marginTop: '5px' }}
                              position="end"
                            >
                              <ImageProfile src={checkIcon} />
                            </InputAdornment>
                          )}
                      </>
                    ),
                  }}
                />
                <ContainerDoubleTextField mobile>
                  <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}
                  >
                    <DesktopDatePicker
                      maxDate={new Date()}
                      disabled={
                        !naoDesabilita
                          ? false
                          : disabled_dtnasc || disabledAll || disabledForm
                      }
                      label="Data de nascimento"
                      value={formik.values.dataNascimento}
                      onChange={(value) => {
                        formik.setFieldValue('dataNascimento', value);
                      }}
                      sx={{ marginTop: '16px' }}
                      slotProps={{
                        textField: {
                          name: 'dataNascimento',
                          error:
                            Boolean(formik.errors.dataNascimento) &&
                            formik.touched.dataNascimento,
                          helperText:
                            formik.errors.dataNascimento &&
                            formik.touched.dataNascimento,
                        },
                      }}
                    />
                  </LocalizationProvider>

                  <StyledTextFieldDoubleMobile
                    style={{ marginTop: '16px' }}
                    id="outlined-select-currency"
                    select
                    name="sexo"
                    label="Sexo"
                    disabled={
                      !naoDesabilita
                        ? false
                        : disabled_sexo || disabledAll || disabledForm
                    }
                    value={formik.values.sexo}
                    onChange={formik.handleChange}
                    required
                    error={Boolean(formik.errors.sexo) && formik.touched.sexo}
                    placeholder={formik.errors.sexo}
                    InputProps={{
                      endAdornment: (
                        <>
                          {(disabled_sexo || disabledAll || disabledForm) && (
                            <InputAdornment
                              style={{
                                marginTop: '4px',
                                marginRight: '25px',
                              }}
                              position="end"
                            >
                              <ImageProfile src={checkIcon} />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  >
                    {sexos.map((option) => (
                      <MenuItem key={option.key} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </StyledTextFieldDoubleMobile>
                </ContainerDoubleTextField>
                <StyledTextFieldMobile
                  style={{ marginTop: '16px' }}
                  id="outlined-basic"
                  label="CPF"
                  name="cpf"
                  variant="outlined"
                  type="text"
                  disabled={disabled_cpf || disabledAll || disabledForm}
                  required
                  value={formataCpf(formik.values.cpf) || ''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.cpf) && formik.touched.cpf}
                  placeholder={formik.errors.cpf}
                  inputProps={{
                    maxLength: 14,
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {isCpf(formik.values.cpf) && (
                          <InputAdornment
                            style={{ marginTop: '5px' }}
                            position="end"
                          >
                            <ImageProfile src={checkMarkLock} />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
                <StyledTextFieldMobile
                  style={{ marginTop: '16px' }}
                  id="celular"
                  name="celular"
                  label="Celular"
                  variant="outlined"
                  type="tel"
                  required
                  disabled={disabledAll || disabledForm}
                  value={formataTelefone(formik.values.celular) || ''}
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.celular) && formik.touched.celular
                  }
                  placeholder={formik.errors.celular}
                  inputProps={{
                    maxLength: 15,
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {formik.values.celular &&
                          formataTelefone(formik.values.celular).length >=
                            14 && (
                            <InputAdornment
                              style={{ marginTop: '5px' }}
                              position="end"
                            >
                              <ImageProfile src={checkIcon} />
                            </InputAdornment>
                          )}
                      </>
                    ),
                  }}
                />
                <StyledTextFieldMobile
                  style={{ marginTop: '16px' }}
                  id="email"
                  name="email"
                  label="E-mail pessoal"
                  variant="outlined"
                  type="email"
                  required
                  disabled={
                    !naoDesabilita
                      ? false
                      : disabled_email || disabledAll || disabledForm
                  }
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.email) && formik.touched.email}
                  placeholder={formik.errors.email}
                  InputProps={{
                    endAdornment: (
                      <>
                        {formik.values.email && (
                          <InputAdornment
                            style={{ marginTop: '5px' }}
                            position="end"
                          >
                            <ImageProfile src={checkIcon} />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
                <ContainerDoubleTextField
                  style={{ display: 'flex', width: '96%' }}
                  mobile
                >
                  <StyledTextFieldDoubleMobile
                    style={{ marginTop: '16px' }}
                    id="telefone"
                    name="matricula"
                    label="Matrícula da patrocinadora"
                    variant="outlined"
                    type="text"
                    required
                    disabled={
                      !naoDesabilita
                        ? false
                        : disabled_matricula || disabledAll || disabledForm
                    }
                    value={formik.values.matricula}
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.matricula) &&
                      formik.touched.matricula
                    }
                    placeholder={formik.errors.matricula}
                    inputProps={{
                      maxLength: 9,
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {(disabled_matricula ||
                            disabledAll ||
                            disabledForm) && (
                            <InputAdornment
                              style={{ marginTop: '5px' }}
                              position="end"
                            >
                              <ImageProfile src={checkIcon} />
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  />
                  <LocalizationProvider
                    locale={ptBR}
                    dateAdapter={AdapterDateFns}
                  >
                    <DesktopDatePicker
                      maxDate={new Date()}
                      minDate={formik.values.dataNascimento}
                      label="Data de admissão"
                      value={formik.values.dataAdmissao}
                      onChange={(value) => {
                        formik.setFieldValue('dataAdmissao', value);
                      }}
                      disabled={
                        !naoDesabilita
                          ? false
                          : disabled_dtAdmissao || disabledAll || disabledForm
                      }
                      sx={{ marginTop: '16px', marginLeft: '9rem' }}
                      slotProps={{
                        textField: {
                          name: 'dataAdmissao',
                          error:
                            Boolean(formik.errors.dataAdmissao) &&
                            formik.touched.dataAdmissao,
                          helperText:
                            formik.errors.dataAdmissao &&
                            formik.touched.dataAdmissao,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </ContainerDoubleTextField>

                <SwitchContainer mobile>
                  <ContainerPessoaExposta mobile>
                    Pessoa politicamente exposta
                    <Tooltip title="Saiba mais sobre PPE" placement="right">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <AiFillQuestionCircle
                          size={22}
                          color="#4F4F4F"
                          onClick={() => {
                            openModalPPE();
                          }}
                        />
                      </div>
                    </Tooltip>
                  </ContainerPessoaExposta>
                  <Switch
                    onChange={() =>
                      formik.setFieldValue(
                        'pessoaExposta',
                        !formik.values.pessoaExposta
                      )
                    }
                    checked={formik.values.pessoaExposta}
                    className="react-switch"
                    offColor="#CC390A"
                    onColor="#4CAF50"
                    width={77}
                    height={32}
                    disabled={disabledAll || disabledForm}
                    uncheckedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 15,
                          color: '#FFF',
                          paddingRight: 2,
                        }}
                      >
                        Não
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 15,
                          color: '#FFF',
                          paddingRight: 2,
                        }}
                      >
                        Sim
                      </div>
                    }
                  />
                </SwitchContainer>

                {formik.values.pessoaExposta && (
                  <ContainerPessoaExpostaInputs>
                    <StyledTextFieldMobile
                      style={{ marginTop: '16px' }}
                      id="orgao-pep"
                      name="orgaoPEP"
                      label="Órgão PEP"
                      variant="outlined"
                      type="text"
                      required
                      value={formik.values.orgaoPEP}
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.orgaoPEP) &&
                        formik.touched.orgaoPEP
                      }
                      placeholder={formik.errors.orgaoPEP}
                      disabled={disabledAll || disabledForm}
                    />
                    <StyledTextFieldMobile
                      style={{ marginTop: '16px' }}
                      id="cargo"
                      name="cargoPEP"
                      label="Cargo PEP"
                      variant="outlined"
                      type="text"
                      value={formik.values.cargoPEP}
                      onChange={formik.handleChange}
                      required
                      error={
                        Boolean(formik.errors.cargoPEP) &&
                        formik.touched.cargoPEP
                      }
                      placeholder={formik.errors.cargoPEP}
                      disabled={disabledAll || disabledForm}
                    />
                    <LocalizationProvider
                      locale={ptBR}
                      dateAdapter={AdapterDateFns}
                    >
                      <DesktopDatePicker
                        maxDate={new Date()}
                        name="dataNomeacao"
                        label="Data de nomeação"
                        inputFormat="dd/MM/yyyy"
                        value={formik.values.dataNomeacao}
                        onChange={(date) => {
                          const dateValue = isValidDate(date) ? date : '';
                          formik.setFieldValue('dataNomeacao', dateValue);
                        }}
                        error={
                          Boolean(formik.errors.dataNomeacao) &&
                          formik.touched.dataNomeacao
                        }
                        placeholder={formik.errors.dataNomeacao}
                        disabled={disabledAll || disabledForm}
                        renderInput={(params) => (
                          <StyledTextFieldMobile
                            style={{ marginTop: '16px' }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider
                      adapterLocale={ptBR}
                      dateAdapter={AdapterDateFns}
                    >
                      <DesktopDatePicker
                        maxDate={new Date()}
                        minDate={formik.values.dataNomeacao}
                        label="Data de exoneração"
                        value={formik.values.dataExoneracao}
                        onChange={(value) => {
                          formik.setFieldValue('dataExoneracao', value);
                        }}
                        disabled={disabledAll || disabledForm}
                        sx={{ marginTop: '16px' }}
                        slotProps={{
                          textField: {
                            name: 'dataExoneracao',
                            error:
                              Boolean(formik.errors.dataExoneracao) &&
                              formik.touched.dataExoneracao,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </ContainerPessoaExpostaInputs>
                )}

                <TermosDeUsoContainer mobile>
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    checked={
                      disabledAll || disabledForm ? true : formik.values.termos
                    }
                    onChange={() =>
                      formik.setFieldValue('termos', !formik.values.termos)
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={disabledAll || disabledForm}
                  />
                  <TermosDeUsoText mobile>
                    <p>
                      Declaro, para os devidos fins, que as informações
                      prestadas acima são verdadeiras e assumo inteira
                      responsabilidade pelas mesmas.
                    </p>
                  </TermosDeUsoText>
                </TermosDeUsoContainer>
                <ContainerButton mobile>
                  <Button normal type="submit">
                    Avançar
                  </Button>
                </ContainerButton>
              </form>
            </ContainerForm>
            <ContainerDocumentos>
              {files && files.length > 0 ? (
                <FileList>
                  {files.map((file, i) => (
                    <FileItem key={i}>
                      <img src={docPreview} alt={file.name} />
                      <p>{file.name}</p>
                    </FileItem>
                  ))}
                </FileList>
              ) : null}
            </ContainerDocumentos>
          </WrapperForm>

          <ModalPPE showModal={showModalPPE} setShowModal={setShowModalPPE} />
        </Container>
      )}
    </>
  );
}
