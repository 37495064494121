export const parentesco = [
  {
    key: '01',
    label: 'Cônjuge',
  },
  {
    key: '02',
    label: 'Filho(a)',
  },
  {
    key: '03',
    label: 'Filiação',
  },
  {
    key: '04',
    label: 'Irmão(a)',
  },
  {
    key: '05',
    label: 'Designado',
  },
  {
    key: '06',
    label: 'Companheiro(a)',
  },
  {
    key: '07',
    label: 'Ex-Cônjuge / Ex-Companheiro(a)',
  },
  {
    key: '08',
    label: 'Padrastro',
  },
  {
    key: '09',
    label: 'Madrastra',
  },
  {
    key: '10',
    label: 'Outros / Sem parentesco',
  },
  {
    key: '11',
    label: 'Enteado',
  },
  {
    key: '12',
    label: 'Neto(a) / Bisneto(a)',
  },
  {
    key: '13',
    label: 'Avo(a) / Bisavo(a)',
  },
  {
    key: '14',
    label: 'Sogro(a)',
  },
  {
    key: '15',
    label: 'Sobrinho(a)',
  },
  {
    key: '16',
    label: 'Menor Sob Guarda',
  },
  {
    key: '17',
    label: 'Agregado',
  },
  {
    key: '18',
    label: 'Tutelado',
  },
];

export const sexos = [
  {
    key: 'M',
    label: 'Masculino',
  },
  {
    key: 'F',
    label: 'Feminino',
  },
];
