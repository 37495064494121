import { api } from './api';

/* export async function postTributacao(body) {
  const { data } = await api.post(`/tributacao/cadastrar`, body);
  return data;
} */

export async function updateTributacao(numPedido, numIdPf, body) {
  const { data } = await api.put(
    `/tributacao/atualizar/${numPedido}/${numIdPf}`,
    body
  );
  return data;
}

export async function getTributacao(numPedido, numIdPf) {
  const { data } = await api.get(
    `/tributacao/findById/${numPedido}/${numIdPf}`
  );
  return data;
}
