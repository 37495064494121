import { useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';

import { getUsers } from 'services/usuariosServices';

import ModalAguardar from 'components/ModalAguardar';
import TabelaCienciaPatrocinadora from 'components/TableUsuario';

import { ModalPatrocinadoraProvider } from 'context/ModalPatrocinadora';
import { useProspect } from 'context/Prospects';

import { ContainerTitleSearch, Search } from '../Prospect/styles';

export function Usuarios() {
  const {
    listaUsuarios,
    setListaUsuarios,
    matriculasUsuarios,
    getAllUsuarios,
    atualizaLista,
  } = useProspect();

  useEffect(() => {
    getAllUsuarios();
  }, [atualizaLista]);

  return (
    <>
      <ContainerTitleSearch>
        <h1>Ciência do Patrocinador</h1>
        <Autocomplete
          id="combo-box-demo"
          freeSolo
          clearOnBlur
          options={matriculasUsuarios}
          onChange={async (e, newValue) => {
            if (newValue != null) {
              setListaUsuarios(
                listaUsuarios.filter(
                  (prospect) => prospect.numMatricula === newValue
                )
              );
            } else {
              const allUsers = await getUsers();
              setListaUsuarios(allUsers || []);
            }
          }}
          getOptionLabel={(professor) => professor}
          style={{ width: 300 }}
          renderInput={(params) => (
            <Search
              {...params}
              label="Buscar por matricula"
              variant="outlined"
            />
          )}
        />
      </ContainerTitleSearch>

      {listaUsuarios && (
        <ModalPatrocinadoraProvider>
          <TabelaCienciaPatrocinadora rows={listaUsuarios} />
        </ModalPatrocinadoraProvider>
      )}

      <ModalAguardar />
    </>
  );
}
