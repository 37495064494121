import { TextField } from '@mui/material';

import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 30em;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const ContainerFormDouble = styled.div`
  display: flex;
  flex-direction: row;
  height: 57px;
  width: 100%;
  justify-content: space-around;
`;

export const LogoImage = styled.img`
  margin-right: auto;
  margin-left: auto;
  max-width: 120px;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  text-align: start;
  flex-direction: column;
  justify-content: flex-start;
  color: #828282;

  p {
    font-size: 16px;
  }

  b {
    color: #828282;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  margin-left: 15px;
  margin-right: 15px;
`;

export const StyledTextField = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '260px',
      height: '57px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '@media only screen and (min-width: 1000px) and (max-width: 1150px)': {
        width: '500px',
      },
      '@media only screen and (min-width: 800px) and (max-width: 1149px)': {
        height: '45px',
        width: '400px',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});
