import { useState, useEffect } from 'react';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';

import { usePersonalInfoForm } from 'hook/usePersonalInfoForm';
import { useResponsive } from 'hook/useResponsive';
import { postAcb } from 'services/acbServices';
import { getVinculadosById } from 'services/vinculadoServices';
import formataCpf from 'util/formatarCPF';

import { Button } from 'components/Button';

import { useContribuicao } from 'context/Contribuicao';
import { useInfoPessoais } from 'context/InfoPessoais';
import { useSteps } from 'context/Steps';
import { useToast } from 'context/Toast';
import { useTributacao } from 'context/Tributacao';

import contribuicaoIcon from 'assets/images/contribuicao.svg';
import vinculadoIcon from 'assets/images/person_group.svg';
import personIcon from 'assets/images/person.svg';
import tributacaoIcon from 'assets/images/tributacao.svg';

import {
  Container,
  TitleContainer,
  CardContainer,
  Card,
  CardItem,
  Image,
  ContainerInformacoes,
  ContainerInformacoesText,
  EditarButton,
  ContainerDeclaracao,
  ContainerButton,
  ContainerInformacoesRegimeText,
} from './styles';

export function ConcluirInscricaoMobile() {
  const { mobileLayout } = useResponsive();
  const { toast } = useToast();
  const { porcentagem1, porcentagem2 } = useContribuicao();
  const { regime } = useTributacao();
  const { selectStep, openPageStatus } = useSteps();
  const { formik, handleLoadFormulario } = usePersonalInfoForm();

  const { verificarRegime, handleLoadTributacao } = useTributacao();
  const { setPage } = useInfoPessoais();

  const { nome, cpf } = JSON.parse(sessionStorage.getItem('@user:dados'));

  const [checkedDeclaracao, setCheckedDeclaracao] = useState(false);
  const [vinculados, setVinculados] = useState([]);

  const handleChecked = () => {
    setCheckedDeclaracao(!checkedDeclaracao);
  };

  const handleLoadVinculados = async () => {
    const dados = sessionStorage.getItem('@user:dados');
    if (!dados) {
      toast.error('Dados não encontrados');
      return;
    }

    const { numPedido } = JSON.parse(dados);

    const getDados = await getVinculadosById(numPedido);

    if (!getDados) return;

    setVinculados(getDados);
  };

  const verificarSeTudoPreenchido = async () => {
    const dados = sessionStorage.getItem('@user:dados');
    if (!dados) {
      toast.error('Dados não encontrados');
      return;
    }

    const { id_prospect } = JSON.parse(dados);

    if (Number.isNaN(parseInt(porcentagem1)))
      return toast.error('Contribuição básica inválida', { id: 'toast' });
    if (Number.isNaN(parseInt(porcentagem2)))
      return toast.error('Contribuição voluntária inválida', { id: 'toast' });
    if (!regime) return toast.error('Regime não encontrado', { id: 'toast' });

    if (formik.values.pessoaExposta == true) {
      if (!formik.values.nome)
        return toast.error('Nome não preenchido', { id: 'toast' });
      if (!formik.values.dataNascimento)
        return toast.error('Data de nascimento não preenchida', {
          id: 'toast',
        });
      if (!formik.values.sexo)
        return toast.error('Sexo não preenchido', { id: 'toast' });
      if (!formik.values.cpf)
        return toast.error('CPF não preenchido', { id: 'toast' });
      if (!formik.values.celular)
        return toast.error('Celular não preenchido', { id: 'toast' });
      if (!formik.values.email)
        return toast.error('E-mail não preenchido', { id: 'toast' });
      if (!formik.values.matricula)
        return toast.error('Matricula não preenchido', { id: 'toast' });
      if (!formik.values.dataAdmissao)
        return toast.error('Data Admissão não preenchido', { id: 'toast' });
      if (!formik.values.pessoaExposta)
        return toast.error('Pessoa exposta não preenchido', { id: 'toast' });
      if (!formik.values.orgaoPEP)
        return toast.error('Orgão PEP não preenchido', { id: 'toast' });
      if (!formik.values.cargoPEP)
        return toast.error('Cargo PEP não preenchida', { id: 'toast' });
      if (!formik.values.dataNomeacao)
        return toast.error('Data de nomeação não preenchido', { id: 'toast' });
      // if (!formik.values.dataExoneracao)
      //   return toast.error('Data de exoneração não preenchido', { id: 'toast' });
    } else {
      if (!formik.values.nome)
        return toast.error('Nome não preenchido', { id: 'toast' });
      if (!formik.values.dataNascimento)
        return toast.error('Data de nascimento não preenchida', {
          id: 'toast',
        });
      if (!formik.values.sexo)
        return toast.error('Sexo não preenchido', { id: 'toast' });
      if (!formik.values.cpf)
        return toast.error('CPF não preenchido', { id: 'toast' });
      if (!formik.values.celular)
        return toast.error('Celular não preenchido', { id: 'toast' });
      if (!formik.values.email)
        return toast.error('E-mail não preenchido', { id: 'toast' });
      if (!formik.values.matricula)
        return toast.error('Matricula não preenchido', { id: 'toast' });
    }

    const body = {
      id_prospect,
    };

    if (checkedDeclaracao) {
      toast.loading('Enviando dados...', { id: 'toast' });

      await postAcb(body);

      /* await envioAcbProspect(id_prospect);
      await envioAcbVinculados(id_prospect); */

      toast.success('Dados enviados com sucesso', { id: 'toast' });
      openPageStatus();
    } else {
      toast.error('Você precisa aceitar as informações', { id: 'declaracao' });
    }
  };

  useEffect(() => {
    handleLoadFormulario();
    handleLoadVinculados();
    handleLoadTributacao();
  }, []);

  return (
    <>
      {mobileLayout && (
        <Container>
          <TitleContainer mobile>
            <h2>Confira os dados abaixo antes de concluir sua inscrição</h2>
          </TitleContainer>
          <CardContainer mobile>
            <Card>
              <CardItem>
                <Image src={personIcon} alt="Pessoa" />

                <ContainerInformacoes>
                  <h3>Informações pessoais</h3>
                  <ContainerInformacoesText>
                    {nome}
                    <br />
                    {formataCpf(cpf)}
                  </ContainerInformacoesText>

                  <EditarButton
                    onClick={() => {
                      setPage('formulario');
                      selectStep(0);
                    }}
                  >
                    Editar
                  </EditarButton>
                </ContainerInformacoes>
              </CardItem>
              <CardItem>
                <Image src={contribuicaoIcon} alt="Contribuição" />

                <ContainerInformacoes>
                  <h3>Contribuição</h3>
                  <ContainerInformacoesText>
                    Básica: {porcentagem1}%
                    <br />
                    Voluntária: {porcentagem2}%
                  </ContainerInformacoesText>

                  <EditarButton
                    onClick={() => {
                      selectStep(2);
                    }}
                  >
                    Editar
                  </EditarButton>
                </ContainerInformacoes>
              </CardItem>
            </Card>
            <Card>
              <CardItem>
                <Image src={vinculadoIcon} alt="Vinculados" />

                <ContainerInformacoes>
                  <h3>Vinculados</h3>
                  <ContainerInformacoesText>
                    {vinculados.map((vinculado) => (
                      <p> {vinculado.nome} </p>
                    ))}
                  </ContainerInformacoesText>

                  <EditarButton
                    onClick={() => {
                      selectStep(1);
                    }}
                  >
                    Editar
                  </EditarButton>
                </ContainerInformacoes>
              </CardItem>
              <CardItem>
                <Image src={tributacaoIcon} alt="Tributação" />

                <ContainerInformacoes>
                  <h3>Tributação</h3>
                  <ContainerInformacoesRegimeText style={{ width: '90px' }}>
                    {verificarRegime()}
                  </ContainerInformacoesRegimeText>

                  <EditarButton
                    onClick={() => {
                      selectStep(3);
                    }}
                  >
                    Editar
                  </EditarButton>
                </ContainerInformacoes>
              </CardItem>
            </Card>
          </CardContainer>
          <ContainerDeclaracao mobile>
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<RadioButtonCheckedIcon />}
              checked={checkedDeclaracao}
              onChange={() => {
                handleChecked();
              }}
              color="primary"
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <div>
              Declaro, para os devidos fins, que as informações prestadas acima
              são verdadeiras e assumo inteira responsabilidade pelas mesmas.
            </div>
          </ContainerDeclaracao>

          <ContainerButton>
            <Button
              normal
              onClick={() => {
                // Validação de todos os dados
                verificarSeTudoPreenchido();
              }}
            >
              Enviar pedido de inscrição
            </Button>
          </ContainerButton>
        </Container>
      )}
    </>
  );
}
