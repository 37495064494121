import TextField from '@mui/material/TextField';

import { breakpoints } from 'hook/useMedia';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 3rem 0 3rem;

  ${(props) =>
    props.mobile &&
    css`
      background: #fff;
      display: flex;
      flex-direction: column;
      border-radius: 27px 27px 0px 0px;
      margin-top: 20px;
      height: 100%;
      z-index: 1;
    `}
`;

export const ContainerTop = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 3rem;

  ${(props) =>
    props.mobile &&
    css`
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 6rem;
      align-items: center;
    `}
`;

export const ContainerMid = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1000px) and (max-width: 1150px) {
    width: 90%;
  }

  @media only screen and ${breakpoints.lg} {
    width: 100%;
  }

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      width: 100%;
    `}
`;

export const ContainerBottom = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (min-width: 1000px) and (max-width: 1150px) {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  @media only screen and ${breakpoints.lg} {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Spacer = styled.div`
  margin-right: 14px;

  @media only screen and ${breakpoints.lg} {
    margin-right: 0px;
  }
`;

export const Footer = styled.div`
  text-align: right;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      flex-direction: column-reverse;
      display: flex;
      width: 100%;
      justify-content: center;
    `}
`;

export const Text = styled.strong`
  color: #1351b4;
  font-size: 16px;
  font-weight: 700;
  margin-left: 1rem;
`;

export const StyledTextField = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      height: '57px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '@media only screen and (min-width: 1000px) and (max-width: 1150px)': {
        width: '500px',
      },
      '@media only screen and (min-width: 800px) and (max-width: 1149px)': {
        height: '45px',
        width: '400px',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const StyledTextFieldDouble = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '330px',
      height: '57px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '@media only screen and (min-width: 1000px) and (max-width: 1150px)': {
        width: '500px',
      },
      '@media only screen and (min-width: 800px) and (max-width: 1149px)': {
        height: '45px',
        width: '400px',
        marginRight: '0px',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const StyledTextFieldMobile = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '343px',
      height: '45px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '@media only screen and (min-width: 1000px) and (max-width: 1150px)': {
        width: '500px',
      },
      '@media only screen and (min-width: 800px) and (max-width: 1149px)': {
        height: '45px',
        width: '400px',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const TermosDeUsoContainer = styled.div`
  display: flex;

  @media only screen and ${breakpoints.lg} {
    display: flex;
    width: 100%;
  }

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      width: 100%;
      margin-top: -1rem;
      align-items: center;
      justify-content: center;
    `}
`;

export const TermosDeUsoText = styled.div`
  display: flex;
  word-break: break-word;
  width: 90%;
  margin-bottom: 1em;

  @media only screen and ${breakpoints.xl} {
    width: 75%;
  }

  @media only screen and ${breakpoints.lg} {
    width: 90%;
    display: flex;
  }

  strong {
    color: #2f80ed;
    cursor: pointer;
  }

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      word-break: break-word;
      width: 90%;
      font-size: 12px;
      margin-right: 1rem;

      strong {
        color: #2f80ed;
        cursor: pointer;
      }
    `}
`;
