// import { useMediaQuery } from 'react-responsive';

import { useResponsive } from 'hook/useResponsive';

import { ButtonClose } from 'components/ButtonClose';
import { Steps, StepsMobile } from 'components/Steps';

import { Background, ContainerLeft, LogoImage, ContainerRight } from './styles';

export function Layout({ children }) {
  const { mobileLayout } = useResponsive();

  return (
    <>
      {!mobileLayout && (
        <Background>
          <ContainerLeft>
            <ButtonClose />
            <LogoImage alt="Logo Petros" src="./logo.png" />
            <Steps />
            <LogoImage rodape alt="Logo Rodapé Vibra" src="./logo-rodape.png" />
          </ContainerLeft>
          <ContainerRight>{children}</ContainerRight>
        </Background>
      )}

      {mobileLayout && (
        <Background mobile>
          <ContainerLeft mobile>
            <LogoImage mobile alt="Logo Petros" src="./logo.png" />
            <StepsMobile />
          </ContainerLeft>
          <ContainerRight mobile>{children}</ContainerRight>
        </Background>
      )}
    </>
  );
}
