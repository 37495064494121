// import { Link } from 'react-router-dom';

import TextField from '@mui/material/TextField';

import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 25em;

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      height: 30em;
    `}
`;

export const StyledLink = styled.a`
  text-decoration: none;
`;

export const LogoImage = styled.img`
  margin-right: auto;
  margin-left: auto;
  max-width: 116px;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
`;

export const TextFieldContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  flex-direction: column;

  ${(props) =>
    props.mobile &&
    css`
      margin-left: 0px;
    `}
`;

export const StyledTextField = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '520px',
      height: '57px',
      borderRadius: '12px',
      textAlign: 'left',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const StyledTextFieldMobile = styled(TextField)({
  root: {
    '& .MuiOutlinedInput-root': {
      width: '300px',
      height: '45px',
      borderRadius: '12px',
      '& .MuiInputLabel-root .Mui-focused': {
        color: '#00887D',
      },
      '& fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&:hover fieldset': {
        border: '1px solid #C4C4C4;',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C4C4C4;',
      },
    },
  },
});

export const ImageProfile = styled.img`
  ${(props) =>
    props.mobile &&
    css`
      width: 40px;
      height: 40px;
    `}
`;

export const Footer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  margin-left: 15px;
  margin-right: 15px;
`;

export const ContainerWarning = styled.div`
  display: flex;

  width: 280px;
  height: 240px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  color: #4f4f4f;

  span {
    align-items: center;
    justify-content: center;
    text-align: initial;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
  }
`;

export const Image = styled.img``;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
`;

export const DatePickerWarning = styled.p`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #cc390a;
`;

export const ContainerAvisoMenor = styled.div`
  display: flex;
  width: 90%;
`;

export const TitleAvisoMenor = styled.div`
  display: flex;
`;
