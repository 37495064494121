import { Dialog } from '@mui/material';

import { Button } from 'components/Button';
import { DropzoneVinculado } from 'components/Dropzone';
import { FilesPreviewVinculado } from 'components/FilesPreview';

import { useInfoPessoais } from 'context/InfoPessoais';
import { useModal } from 'context/Modal';
import { useToast } from 'context/Toast';

import personImage from 'assets/images/person.png';

import { Footer, LogoImage, ModalWrapper, TextContainer } from './styles';

// BUG_FIX: verificando se o arquivo de consentimento está carregado e exibindo mensagem de erro
// Author: Lemuel
export function ModalDocumentos({ verificarDocumentoConsentimento }) {
  const { filesVinculado } = useInfoPessoais();

  const {
    showModalDocumentos,
    handleCloseModalDocumentos,
    proximaEtapaPassaDireto,
    pularEtapaDocumentos,
    handleCloseModalDocumentosExcluir,
    idade,
    submitFilesVinculados,
    grauParentesco,
  } = useModal();

  const { toast } = useToast();

  return (
    <>
      {showModalDocumentos && (
        <Dialog
          onClose={handleCloseModalDocumentos}
          aria-labelledby="simple-dialog-title"
          open={showModalDocumentos}
          scroll="body"
          fullWidth
        >
          <ModalWrapper>
            <LogoImage src={personImage} alt="Perfil" />
            <TextContainer>
              {idade > 12 || grauParentesco == '02' ? (
                <p>
                  Compartilhe o <b>RG, CNH ou RNE</b> do vinculado
                </p>
              ) : (
                <p>
                  Compartilhe o{' '}
                  <b>formulário de consentimento e o RG, CNH ou RNE</b> do
                  vinculado
                </p>
              )}
            </TextContainer>

            <DropzoneVinculado />
            <FilesPreviewVinculado />

            {filesVinculado && filesVinculado.length == 0 ? (
              <Footer>
                <Button
                  outlinedVinculados
                  onClick={() => {
                    if (verificarDocumentoConsentimento) {
                      return toast.error(
                        'Você precisa enviar o termo de consentimento'
                      );
                    }
                    pularEtapaDocumentos();
                  }}
                >
                  Pular Etapa
                </Button>
              </Footer>
            ) : (
              <Footer>
                <Button
                  outlinedVinculados
                  onClick={() => {
                    handleCloseModalDocumentosExcluir();
                  }}
                >
                  Excluir
                </Button>
                <Button
                  normalVinculados
                  onClick={async () => {
                    // if (verificarDocumentoConsentimento) {
                    //   const podeProsseguir = filesVinculado.some(
                    //     (file) =>
                    //       file.name ===
                    //       'TERMO_DE_CONSENTIMENTO_PARA_DADOS_DE_CRIANÇAS.pdf'
                    //   );

                    //   console.log(filesVinculado);

                    //   if (!podeProsseguir) {
                    //     return toast.error(
                    //       'Você precisa enviar o termo de consentimento para dados de crianças'
                    //     );
                    //   }
                    // }

                    await submitFilesVinculados(() => {
                      proximaEtapaPassaDireto(); // console.log('não é nada')
                    });
                  }}
                >
                  Prosseguir
                </Button>
              </Footer>
            )}
          </ModalWrapper>
        </Dialog>
      )}
    </>
  );
}
