import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import { Form } from 'pages/Form';
import { Home } from 'pages/Home';
import TabPanels from 'pages/TabPanel';

import { useAuth } from 'context/Auth';

function Routes() {
  const history = useHistory();
  const { signed, portalSigned } = useAuth();

  const token = sessionStorage.getItem('@petros:token');
  const portalToken = sessionStorage.getItem('@petros:portal_token');

  if (token) {
    history.push('/form');
  } else {
    sessionStorage.removeItem('@petros:token');
    sessionStorage.removeItem('@user:dados');

    if (portalToken) {
      history.push('/patrocinadora');
    } else {
      sessionStorage.removeItem('@petros:portal_token');
    }
  }

  return (
    <Switch>
      {signed ? (
        <>
          {/* Rotas autenticadas */}
          <Route path="/form" exact component={Form} />
        </>
      ) : (
        <>
          {portalSigned ? (
            <Route path="/patrocinadora" exact component={TabPanels} />
          ) : (
            <Route path="/" component={Home} />
          )}
        </>
      )}
    </Switch>
  );
}

export default Routes;
